import React from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

const ImageCropper = React.forwardRef((props, ref) => (
  <Cropper
    ref={ref}
    src={props.imgSrc}
    style={{
      height: '40vh',
      width: 'auto'
    }}
    // Cropper.js options
    aspectRatio={props.aspectRatio}
    autoCropArea={1}
    viewMode={1}
    guides={true}
    movable={false}
    scalable={false}
    zoomable={false}
  />
))

ImageCropper.propTypes = {
  aspectRatio: PropTypes.number,
  imgSrc: PropTypes.string.isRequired
}

export default ImageCropper
