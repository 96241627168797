import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'

import PregnancyVerification from '../Auth/pregnancy-verification'
import PrivateRoute from './PrivateRoute'
import Layout from '../Layout/Layout'
import Blog from '../Blog/BlogIndex'
import BlogPage from '../Blog/BlogPage'
import MailIndex from '../Mail/MailIndex'
import Unauthorized from '../Auth/Unauthorized'
import withBlogData from '../../hoc/withBlogData'

import { BLOG_DATA } from '../../constants/blog-data'

const routes = props => {
  return (
    <Switch>
      <Route exact path="/logout" component={Unauthorized} />
      {BLOG_DATA.map(subArray =>
        subArray.map(routeData => (
          <PrivateRoute
            exact
            path={routeData.path}
            component={withBlogData(routeData.data.list, routeData.breadcrumbsRoutes)(BlogPage)}
          />
        ))
      )}
      <PrivateRoute exact path="/blog" component={Blog} />
      <PrivateRoute exact path="/mis-correos" component={MailIndex} />
      <Route exact path="/login" component={PregnancyVerification} />
      <PrivateRoute exact path="/" component={Layout} />
      <Redirect to="/" />
    </Switch>
  )
}

export default routes
