import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Magnifier from 'react-magnifier'

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import CollapseContainer from '../Collapsible/CollapseContainer'
import Footer from '../Layout/Footer'
import CALENDARIO_VACUNACION from '../../img/calendario_vacunación.png'

// NOTE: receive data through props because layout pattern is respected in other blog pages.
const BlogPage = props => {
  return (
    <React.Fragment>
      <Main>
        <Breadcrumbs routes={props.breadcrumbsRoutes} />
        {props.location.pathname !== '/blog/calendario-de-vacunacion-del-recien-nacido' ? (
          <StyledCollapseContainer>
            {props.list.map((item, i) => (
              <div key={`${item.title}-${i}`} title={item.title}>
                {item.content}
              </div>
            ))}
          </StyledCollapseContainer>
        ) : (
          <Magnifier src={CALENDARIO_VACUNACION} width="90%" />
        )}
      </Main>
      <Footer />
    </React.Fragment>
  )
}

BlogPage.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired, content: PropTypes.node })
  ),
  title: PropTypes.string
}

const Main = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 62vh;
  padding-top: 2%;
`
const StyledCollapseContainer = styled(CollapseContainer)`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  & ul {
    line-height: 2em;
  }
`

BlogPage.propTypes = {
  list: PropTypes.array.isRequired
}
export default BlogPage
