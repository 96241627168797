import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { KeyboardArrowDown } from 'styled-icons/material'

class CollapseContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: null
    }
  }
  collapseHandler = id => {
    this.setState(prevState => {
      return prevState.activeItem !== id ? { activeItem: id } : { activeItem: null }
    })
  }
  render() {
    return (
      <Container {...this.props}>
        {this.props.children.map((collapsibleComponent, index) => (
          <CollapseElement
            isActive={this.state.activeItem === index}
            key={`${collapsibleComponent.props.title}-${index}`}
          >
            <CollapseHeader onClick={() => this.collapseHandler(index)}>
              <Span>{collapsibleComponent.props.title}</Span>
              <HeaderIcon size={'2em'} isActive={this.state.activeItem === index} />
            </CollapseHeader>
            <Collapsible isActive={this.state.activeItem === index}>
              {collapsibleComponent.props.children}
            </Collapsible>
          </CollapseElement>
        ))}
      </Container>
    )
  }
}

const HeaderIcon = styled(KeyboardArrowDown)`
  transition: transform 1s ease;
  ${props =>
    props.isActive &&
    css`
      transform: rotateZ(-180deg);
    `}
`
const Span = styled.span`
  width: 85%;
`
const Container = styled.div`
  width: 80%;
  overflow-y: hidden;
  @media screen and (max-width: 800px) {
    width: 95%;
  }
`
const CollapseElement = styled.div`
  max-height: 8vh;
  overflow: hidden;
  margin-bottom: 1%;
  transition: max-height 1s ease;
  ${props =>
    props.isActive &&
    css`
      max-height: 250vh;
    `}
`
const Collapsible = styled.div`
  padding: 0 2%;
`
const CollapseHeader = styled.div`
  background-color: ${props => props.theme.themeColors[props.theme.selected].quinaryColor};
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  font-weight: 600;
  min-height: 8vh;
  display: flex;
  align-items: center;
  padding: 0 2%;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`
export default CollapseContainer
