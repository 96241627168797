import axios from 'axios'
import store from './redux/store/store'

import envConfig from './envConfig'
import { setError, resetFeedbackMessage } from './redux/actions/feedbackMessageActions'
import { showLoader, hideLoader } from './redux/actions/loaderActions'
import { logoutForbbiden } from './redux/actions/authActions'
import { MAIL_CATEGORIES } from './constants/generics'

/**
 * Axios instances creation.
 */
const withCommonLoader = axios.create({
  baseURL: envConfig.endpoints.API_URL
})

const withoutCommonLoader = axios.create({
  baseURL: envConfig.endpoints.API_URL
})

/**
 * Common to any axios instance interceptor actions.
 */
const resInterceptor = res => {
  const { token } = store.getState().authReducer.auth
  res.headers = {
    ...res.headers,
    Authorization: token
  }
  return res
}

const errorInterceptor = err => {
  if (!err.response) {
    const niceError = new Error('Ocurrió un error de conexión, intenta nuevamente o más tarde.')
    niceError.title = '¡Error de comunicación!'
    store.dispatch(setError(niceError))
    throw err
  }
  // Get undefined profile photos
  if (err.response.status === 500 && err.response.data === 'Archivo inexistente') {
    throw err
  }
  if (err.response.status === 403 || err.response.status === 401) {
    store.dispatch(logoutForbbiden())
    throw err
  }
  if (!store.getState().feedbackMessageReducer.messageShown) {
    const niceError = new Error('Ocurrió un error de conexión, intenta nuevamente o más tarde.')
    niceError.title = '¡Error de comunicación!'
    store.dispatch(setError(niceError))
  }
  console.log('error en response ->', err.message)
  throw err
}

/**
 * Axios instance with common loader dispatch
 */
withCommonLoader.interceptors.request.use(
  res => {
    store.dispatch(resetFeedbackMessage())
    store.dispatch(showLoader())
    return resInterceptor(res)
  },
  err => {
    throw errorInterceptor(err)
  }
)

withCommonLoader.interceptors.response.use(
  res => {
    store.dispatch(hideLoader())
    return resInterceptor(res)
  },
  err => {
    store.dispatch(hideLoader())
    throw errorInterceptor(err)
  }
)
/**
 * Without loader axios instance
 */
withoutCommonLoader.interceptors.request.use(
  res => {
    store.dispatch(resetFeedbackMessage())
    return resInterceptor(res)
  },
  err => {
    throw errorInterceptor(err)
  }
)

withoutCommonLoader.interceptors.response.use(
  res => {
    return resInterceptor(res)
  },
  err => {
    throw errorInterceptor(err)
  }
)

/**
 * Get user data
 */
export const getUserData = usuarioId => {
  const url = `usuario?usuarioId=${usuarioId}`
  return withCommonLoader.get(url)
}

/**
 * Get embarazo data.
 */
export const getEmbarazo = usuarioId => {
  const url = `embarazo/buscar?usuarioId=${usuarioId}`
  return withCommonLoader.get(url)
}

/**
 * Set profile data
 */
export const setProfile = payloadObject => {
  const url = `embarazo`
  return withCommonLoader.post(url, payloadObject, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Registros data
 */
export const getRegistrosClinicos = () => {
  const url = `embarazo/estudios`
  return withCommonLoader.get(url)
}

/**
 * Mi embarazo data
 */
export const getEtapas = () => {
  const url = `embarazo/etapas`
  return withCommonLoader.get(url)
}

/**
 * Reserva de turno de estudio
 */
export const reservarTurnoEstudioAPI = payloadObject => {
  const url = `turno/estudio`
  return withCommonLoader.post(url, payloadObject, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Reserva de turno generica por especialidad
 */
export const reservarTurnoEspecialidadAPI = payloadObject => {
  const url = `turno/especialidad`
  return withCommonLoader.post(url, payloadObject, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Get turno estudio
 */
export const getTurno = payloadObject => {
  const { usuarioId, estudio } = payloadObject
  const url = `turno/estudio/buscar?usuarioId=${usuarioId}&estudio=${estudio}`
  return withCommonLoader.get(url)
}

/**
 * Get turno especialidad
 */
export const getTurnoEspecialidad = payloadObject => {
  const { usuarioId, especialidadId } = payloadObject
  const url = `turno/especialidad/buscar?usuarioId=${usuarioId}&especialidad=${especialidadId}`
  return withCommonLoader.get(url)
}

/**
 * Set photo
 */
export const setPhoto = payloadObject => {
  const url = `embarazo/upload?usuarioId=${payloadObject.usuarioId}&fileType=${payloadObject.fileType}`
  const data = new FormData()
  data.append('file', payloadObject.file)
  return withCommonLoader.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * Get photo
 */
export const getPhoto = (usuarioId, fileType) => {
  const url = `embarazo/download?usuarioId=${usuarioId}&fileType=${fileType}`
  return withCommonLoader.get(url)
}

/**
 * Get notas of day
 */
export const getNotasAPI = (usuarioId, dayNumber) => {
  const url = `embarazo/nota/buscar?usuarioId=${usuarioId}&dia=${dayNumber}`
  return withoutCommonLoader.get(url)
}

/**
 * Set nota
 */
export const setNotaAPI = payloadObject => {
  const url = `embarazo/nota`
  return withCommonLoader.post(url, payloadObject, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Delete nota
 */
export const deleteNotaAPI = (usuarioId, idDeNota) => {
  const url = `embarazo/nota?usuarioId=${usuarioId}&id=${idDeNota}`
  return withCommonLoader.delete(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Subir archivo a nota
 */
export const subirArchivoNotaAPI = (usuarioId, idDeNota, file) => {
  const url = `embarazo/nota/upload?usuarioId=${usuarioId}&notaId=${idDeNota}`
  const data = new FormData()
  data.append('file', file)
  return withCommonLoader.post(url, data, {
    headers: {
      'Content-Type': 'multipart/x-www-url-enconded'
    }
  })
}

/**
 * Subir archivo a nota
 */
export const removeArchivoNotaAPI = (usuarioId, idDeNota, idArchivo) => {
  const url = `embarazo/nota/archivo?usuarioId=${usuarioId}&notaId=${idDeNota}&id=${idArchivo}`
  return withCommonLoader.delete(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * bajar archivos de nota
 */
export const bajarArchivosDeNotaAPI = (usuarioId, idDeNota) => {
  const url = `embarazo/nota/download?usuarioId=${usuarioId}&notaId=${idDeNota}`
  return withoutCommonLoader.get(url)
}

/**
 * Get especialidades
 */
export const getEspecialidades = () => {
  const url = `especialidad/especialidades`
  return withCommonLoader.get(url)
}

/**
 * Get especialidad por ID
 */
export const getEspecialidadPorId = especialidadId => {
  const url = `especialidad/buscar?id=${especialidadId}`
  return withCommonLoader.get(url)
}

/**
 * Get emails no leidos
 */
export const getCantidadNoLeidosAPI = usuarioId => {
  const url = `mensaje/noleidos?usuario=${usuarioId}`
  return withoutCommonLoader.get(url)
}

/**
 * Get appointment notifications
 */
export const getAppointmentNotification = usuarioId => {
  const url = `embarazo/notificaciones?usuarioId=${usuarioId}`
  return withoutCommonLoader.get(url)
}

/**
 * Get emails from API
 */
export const getMensajesAPI = payload => {
  const url = `mensaje/mensajes`
  return withCommonLoader.get(url, {
    params: payload
    // headers: {
    //   'Content-Type': 'application/json'
    // }
  })
}

/**
 * Get destacados from API
 */
export const getDestacadosAPI = payload => {
  const url = `mensaje/destacados`
  return withCommonLoader.get(url, {
    params: payload
  })
}

/**
 * Guardar borrador
 */
export const guardarBorradorAPI = payload => {
  const url = 'mensaje/borrador'
  return withCommonLoader.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Enviar mensaje
 */
export const enviarMensajeAPI = payload => {
  const url = 'mensaje/enviar'
  return withCommonLoader.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Actualizar mensaje
 */
export const actualizarMensajeAPI = payload => {
  const url = `mensaje/actualizar`
  return withCommonLoader.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Obtener hilo de mensajes
 */
export const obtenerHiloAPI = payload => {
  const url = `mensaje/hilo`
  return withCommonLoader.get(url, {
    params: payload,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Favoritear mensaje
 */
export const favoritearMensajeAPI = payload => {
  const url = `mensaje/actualizar`
  return withoutCommonLoader.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 * Mover a papelera el mensaje
 */
export const moverPapeleraAPI = payload => {
  payload.bandeja = MAIL_CATEGORIES.ELIMINADOS
  const url = `mensaje/actualizar`
  return withoutCommonLoader.post(url, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
