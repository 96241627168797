import React from 'react'
import { connect } from 'react-redux'

import styled, { css } from 'styled-components'
import {
  FastForward,
  FastRewind,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  PregnantWoman,
  BusinessCenter,
  ChromeReaderMode,
  AddCircleOutline
} from 'styled-icons/material'

import Spinner from '../Loader/Spinner'

import { daysDifference } from '../../utils/date'
import { weekCalc, setDay } from '../../redux/actions/calendarActions'

import colors from '../../styled/colors'
import { MONTHS, NUMBER_OF_WEEKS, ACTION_ICON_SIZE } from '../../constants/generics'

const MobileCalendar = props => {
  const showFrom = new Date(props.week.datefromTo[0])
  const showTo = new Date(props.week.datefromTo[1])
  showFrom.setDate(showFrom.getDate() + 1)
  showTo.setDate(showTo.getDate() + 1)
  let dayNumber = new Date(props.week.datefromTo[0])
  dayNumber.setDate(dayNumber.getDate() + props.week.selectedDay + 1)

  const registrosPorDia = props.data.registros.filter(
    entry => entry.diaEstimado === daysDifference(dayNumber, props.week.pregnancyBeginsOn)
  )

  const miEmbarazoPorDia = props.data.embarazo.filter(
    entry => entry.diaEstimado === daysDifference(dayNumber, props.week.pregnancyBeginsOn)
  )

  const misNotas = props.data.notas[daysDifference(dayNumber, props.week.pregnancyBeginsOn)]
    ? props.data.notas[daysDifference(dayNumber, props.week.pregnancyBeginsOn)]
    : []

  return (
    <Section>
      <HeadContainer>
        <SemanaContainer>
          <StyledFastRewind
            isVisible={!(props.week.number - 1 < 0)}
            size="2em"
            color={colors.strongGray}
            onClick={() => {
              const { number } = props.week
              return !(number - 1 < 0) ? props.weekCalc(number - 1) : null
            }}
          />
          <TitleContainer>
            <H3>Semana {props.week.number + 1}</H3>
            <H5>
              {`Del ${showFrom.getDate()} de ${
                MONTHS[props.week.datefromTo[0].getMonth()]
              } al ${showTo.getDate()} de ${MONTHS[showTo.getMonth()]}`}
            </H5>
          </TitleContainer>
          <StyledFastForward
            isVisible={!(props.week.number + 1 > NUMBER_OF_WEEKS)}
            size="2em"
            color={colors.strongGray}
            onClick={() => {
              const { number } = props.week
              return !(number + 1 > NUMBER_OF_WEEKS) ? props.weekCalc(number + 1) : null
            }}
          />
        </SemanaContainer>
        <Hr />
        <DiaContainer>
          <StyledArrowLeft
            isVisible={!(props.week.selectedDay - 1 < 0)}
            size="2em"
            color={colors.strongGray}
            onClick={() => {
              const { selectedDay } = props.week
              return !(selectedDay - 1 < 0) ? props.setDay(props.week.selectedDay - 1) : null
            }}
          />
          <TitleContainer>
            <H4>
              {new Date(props.dateToBirth).toDateString() !== dayNumber.toDateString()
                ? dayNumber.toDateString() === props.week.activeDay.toDateString()
                  ? 'Hoy'
                  : `Día ${dayNumber.getDate()} de ${MONTHS[dayNumber.getMonth()]}`
                : 'Probable parto'}
            </H4>
          </TitleContainer>
          <StyledArrowRight
            isVisible={!(props.week.selectedDay + 1 > 6)}
            size="2em"
            color={colors.strongGray}
            onClick={() => {
              const { selectedDay } = props.week
              return !(selectedDay + 1 > 6) ? props.setDay(props.week.selectedDay + 1) : null
            }}
          />
        </DiaContainer>
      </HeadContainer>
      <MainContainer>
        <RowContainer>
          <DarkHelper>
            <BusinessCenter size="1.5rem" />
            <span>Registros clínicos</span>
          </DarkHelper>
          <DarkDataContainer>
            {registrosPorDia.length ? (
              registrosPorDia.map(registro => (
                <Data key={registro.id} isActive={!!registro}>
                  <DataSpan>{registro && registro.nombre}</DataSpan>
                  <TurnoButtonContainer
                    onClick={() => props.turnoClickHandler(registro, props.usuarioId)}
                  >
                    <AddCircleOutline size={ACTION_ICON_SIZE} />
                    <span>Turno</span>
                  </TurnoButtonContainer>
                </Data>
              ))
            ) : (
              <Data isActive={false}>
                <DataSpan>No hay info para este día.</DataSpan>
              </Data>
            )}
          </DarkDataContainer>
        </RowContainer>
        <RowContainer>
          <Helper>
            <PregnantWoman color={colors.opaqueRed} size="30px" />
            <span>Mí embarazo</span>
          </Helper>
          <DataContainer>
            {miEmbarazoPorDia.length ? (
              miEmbarazoPorDia.map(evento => (
                <Data key={evento.id} isActive={!!evento}>
                  <DataSpan>{evento.descripcion}</DataSpan>
                  <TurnoButtonContainer onClick={() => props.detalleClickHandler(evento)}>
                    <AddCircleOutline size={ACTION_ICON_SIZE} />
                    <span>Ver más</span>
                  </TurnoButtonContainer>
                </Data>
              ))
            ) : (
              <Data isActive={false}>
                <DataSpan>No hay info para este día.</DataSpan>
              </Data>
            )}
          </DataContainer>
        </RowContainer>
        <RowContainer>
          <DarkHelper>
            <ChromeReaderMode color={colors.opaqueRed} size="30px" />
            <span>Mis Notas</span>
          </DarkHelper>
          <DarkDataContainer>
            {props.notasLoading ? (
              <Spinner />
            ) : (
              <DataNotas isActive={!!props.data.notas}>
                <TurnoButtonContainer
                  onClick={() =>
                    props.notasHandler(
                      misNotas,
                      daysDifference(dayNumber, props.week.pregnancyBeginsOn)
                    )
                  }
                >
                  <AddCircleOutline size={ACTION_ICON_SIZE} />
                  <span>Ver las notas del día</span>
                </TurnoButtonContainer>
              </DataNotas>
            )}
          </DarkDataContainer>
        </RowContainer>
      </MainContainer>
    </Section>
  )
}

const iconsCss = css`
  cursor: ${props => (props.isVisible ? 'pointer' : 'normal')};
  padding: 1%;
  border-radius: 50%;
  opacity: ${props => (props.isVisible ? '1' : '0')};
  &:hover {
    background-color: ${props => props.theme.colors.transparentGray};
  }
`

const StyledFastForward = styled(FastForward)`
  ${iconsCss};
`
const StyledFastRewind = styled(FastRewind)`
  ${iconsCss};
`
const StyledArrowRight = styled(KeyboardArrowRight)`
  ${iconsCss};
`
const StyledArrowLeft = styled(KeyboardArrowLeft)`
  ${iconsCss};
`
const TurnoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  color: ${props => props.theme.colors.white};
  &:hover {
    cursor: pointer;
  }
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SemanaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`
const DiaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MainContainer = styled.div`
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const RowContainer = styled.div`
  display: flex;
  height: 33.33%;
  border-width: 2px 0;
  border-color: ${props => props.theme.colors.white};
`
const Helper = styled.div`
  width: 35%;
  height: 100%;
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHelperBackgroundLight};
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 3%;
  border-right: 2px solid ${props => props.theme.colors.white};
  font-weight: bold;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  & svg {
    fill: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  }
  & span {
    text-align: center;
  }
`
const DarkHelper = styled(Helper)`
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHelperBackgroundDark};
`

const DataContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarRowItemBackgroundLight};
  overflow: auto;
`

const DarkDataContainer = styled(DataContainer)`
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarRowItemBackground};
`

const Data = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.white};
  border-top: 1px solid ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  ${props =>
    props.isActive &&
    css`
      color: ${props.theme.colors.white};
      background-color: ${props.theme.themeColors[props.theme.selected].calendarDataActive};
      justify-content: space-evenly;
    `}
`
const DataNotas = styled(Data)`
  justify-content: center;
`
const DataSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const H3 = styled.h3`
  margin-top: 2vh;
  margin-bottom: 0;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
const H5 = styled.h5`
  margin-top: 0;
  margin-bottom: 2vh;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`
const H4 = styled.h4`
  background-color: ${props => props.theme.themeColors[props.theme.selected].mainColor};
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  padding: 3vw 4vw;
  border-radius: 5px;
`
const Hr = styled.hr`
  width: 70%;
  margin: 0;
`

const mapStateToProps = state => {
  const { week, data, notasLoading } = state.calendarReducer
  const { usuarioId } = state.authReducer.auth
  const { dateToBirth } = state.profileReducer
  return {
    week,
    notasLoading,
    data,
    usuarioId,
    dateToBirth
  }
}

const mapDispatchToProps = {
  weekCalc,
  setDay
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileCalendar)
