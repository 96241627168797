import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import validationSchema from '../../../validation/mailSchema'
import { MAIL_CATEGORIES } from '../../../constants/generics'
import { guardarBorradorAPI, enviarMensajeAPI, getUserData, obtenerHiloAPI } from '../../../api'
import { H2 } from '../../../styled/generics'
import {
  StyledForm as Form,
  StyledInput as Input,
  TextArea,
  SubmitButton
} from '../../../styled/forms'
import { SecondaryButton } from '../../../styled/generics'
import { ErrFeedbackContainer } from '../../../styled/forms'

class MailForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      asunto: '',
      cuerpo: '',
      userTo: 'Pregnancy Planner',
      userFrom: '',
      errors: {}
    }
  }

  componentDidMount() {
    //TODO get assigned pacientes to select from pop-up list
    //TODO get assigned medicos/PP to select from pop-up list
    getUserData(this.props.userFrom)
      .then(res => this.setState({ userFrom: `${res.data.nombre} ${res.data.apellido}` }))
      .catch(console.log)
    if (this.props.esBorrador) {
      const urlParams = {
        idUsuario: this.props.userFrom,
        idMensajeRaiz: this.props.idMail
      }
      obtenerHiloAPI(urlParams)
        .then(res => {
          const foundMail = res.data.find(mail => mail.id === this.props.idMail)
          if (foundMail) {
            const { asunto, cuerpo } = foundMail
            this.setState({ asunto, cuerpo })
          }
        })
        .catch(console.log)
    }
  }

  onChangeHandler = event => {
    const { name, value } = event.target
    if (name === 'cuerpo' && value.length > 1024) {
      return this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          cuerpo: `Cantidad de caractéres ${value.length}. Cantidad máxima 1024!`
        },
        cuerpo: value
      }))
    }
    const newErrors = { ...this.state.errors }
    delete newErrors[name]
    this.setState({
      [name]: value,
      errors: newErrors
    })
  }

  submitHandler = event => {
    event.preventDefault()
    const { asunto, cuerpo } = this.state
    const payload = {
      idUsuarioFrom: this.props.userFrom,
      asunto,
      cuerpo
    }
    if (this.props.esBorrador) {
      payload.id = this.props.idMail
    }
    validationSchema
      .validate(this.state)
      .then(res => {
        enviarMensajeAPI(payload)
          .then(res => this.props.closeModalCallback(MAIL_CATEGORIES.ENVIADOS))
          .catch(console.log)
      })
      .catch(errors => {
        console.log(errors)
        this.setState({
          errors: {
            [errors.path]: errors.message
          }
        })
      })
  }

  guardarBorrador = event => {
    event.preventDefault()
    const { asunto, cuerpo } = this.state
    const payload = {
      asunto,
      cuerpo
    }
    payload.idUsuarioFrom = this.props.userFrom
    if (this.props.esBorrador) {
      payload.id = this.props.idMail
      return guardarBorradorAPI(payload)
        .then(res => this.props.closeModalCallback(MAIL_CATEGORIES.BORRADORES))
        .catch(console.log)
    }
    guardarBorradorAPI(payload)
      .then(res => this.props.closeModalCallback(MAIL_CATEGORIES.BORRADORES))
      .catch(console.log)
  }

  render() {
    return (
      <Form onSubmit={this.submitHandler}>
        <H2>{this.props.title}</H2>
        <Label>
          <strong>De</strong>
          <CustomInput name="from" type="text" defaultValue={this.state.userFrom} disabled />
        </Label>
        <Label>
          <strong>Para</strong>
          <CustomInput name="to" type="text" defaultValue={this.state.userTo} disabled />
        </Label>
        <Label>
          <strong>Asunto</strong>
          <CustomInput
            name="asunto"
            type="text"
            value={this.state.asunto}
            onChange={this.onChangeHandler}
          />
        </Label>
        <Label>
          <strong>Tu mensaje</strong>
          <CustomTextArea name="cuerpo" value={this.state.cuerpo} onChange={this.onChangeHandler} />
          {this.state.errors.cuerpo && (
            <ErrContainer>{'* ' + this.state.errors.cuerpo}</ErrContainer>
          )}
        </Label>
        <ButtonsContainer>
          <EnviarButton
            name="enviar"
            disabled={
              Object.entries(this.state.errors).length !== 0 &&
              this.state.errors.constructor === Object
            }
          >
            {this.props.esBorrador ? 'Enviar borrador' : 'Enviar mensaje'}
          </EnviarButton>
          <BorradorButton name="borrador" onClick={this.guardarBorrador}>
            {this.props.esBorrador ? 'Guardar cambios' : 'Guardar como borrador'}
          </BorradorButton>
        </ButtonsContainer>
      </Form>
    )
  }
}

const ErrContainer = styled(ErrFeedbackContainer)`
  margin-top: -3%;
  margin-bottom: 2%;
`

const Label = styled.label`
  width: 80%;
  margin-bottom: 2%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
const CustomInput = styled(Input)`
  width: 100%;
`

const CustomTextArea = styled(TextArea)`
  width: 100%;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 5%;
`
const commonButtonCSS = css`
  margin: 0;
  width: 45%;
`
const BorradorButton = styled(SecondaryButton)`
  ${commonButtonCSS}
`
const EnviarButton = styled(SubmitButton)`
  ${commonButtonCSS}
`

MailForm.propTypes = {
  userFrom: PropTypes.string.isRequired,
  idMail: PropTypes.number,
  closeModalCallback: PropTypes.func.isRequired,
  esBorrador: PropTypes.bool
}

export default MailForm
