import { createStore, compose, applyMiddleware } from 'redux'
import persistState from 'redux-sessionstorage'
import rootReducer from '../reducers'
// import loggerMiddleware from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

const store = createStore(
  rootReducer,
  compose(
    persistState('authReducer', {
      key: 'auth'
    }),
    persistState('profileReducer', {
      key: 'profile'
    }), // Sync auth state with sessionStorage in browser
    applyMiddleware(thunkMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)

export default store
