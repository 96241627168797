import COLORS from '../../styled/colors'
import { constants } from '../../constants/reduxActions'
const { SET_PROFILE } = constants

const initialState = {
  name: '',
  doctor: '',
  clinic: '',
  dateToBirth: new Date(),
  sex: undefined,
  embarazoDoesExist: false,
  color: {
    hex: COLORS.fadedPink,
    name: 'main'
  }
}

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      const { nombre, fechaEstimadaParto, sexo, obstetra, clinica, color, embarazoDoesExist } = action.payload

      return {
        ...state,
        name: nombre,
        doctor: obstetra,
        clinic: clinica,
        dateToBirth: new Date(fechaEstimadaParto),
        sex: sexo,
        color,
        embarazoDoesExist
      }
    default:
      return {
        ...state
      }
  }
}

export default profileReducer
