import { constants } from '../../constants/reduxActions'
const {
  SET_WEEK,
  SET_DAY,
  SET_CALENDAR_DATA,
  SET_NOTAS,
  SET_HAS_BED_APPOINTMENT,
  SET_ACTIVE_DAY,
  SET_NOTAS_OF_WEEK,
  SET_GOTTEN_NOTAS,
  SET_NOTAS_LOADER,
  REMOVE_NOTAS_LOADER
} = constants

const initialState = {
  week: {
    number: 0, //Pregnancy week number from 0 to NUMBER_OF_WEEKS
    month: 0, //Month number [0, 11]
    datefromTo: [new Date(), new Date()], //Real date of week
    selectedDay: 0, //Day number [0, 6]
    pregnancyBeginsOn: new Date(),
    hasBedAppointment: true,
    activeDay: new Date(),
    gottenNotas: {} // This should always be an object in order to map different day intervals.
  },
  notasLoading: false,
  data: {
    registros: [], //This array will be inmutable along 40 weeks
    embarazo: [], //This array will be inmutable along 40 weeks
    notas: {} // This should always be an object in order to map different day intervals.
  }
}

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WEEK:
      return {
        ...state,
        week: {
          ...state.week,
          ...action.payload,
          selectedDay: 0
        }
      }
    case SET_DAY:
      return {
        ...state,
        week: {
          ...state.week,
          selectedDay: action.payload
        }
      }

    case SET_NOTAS:
      return {
        ...state,
        data: {
          ...state.data,
          notas: action.payload
        }
      }
    case SET_NOTAS_OF_WEEK:
      return {
        ...state,
        data: {
          ...state.data,
          notas: action.payload
        }
      }
    case SET_GOTTEN_NOTAS:
      return {
        ...state,
        week: {
          ...state.week,
          gottenNotas: {
            ...state.week.gottenNotas,
            [action.payload.weekNumber]: true
          }
        }
      }
    case SET_CALENDAR_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case SET_HAS_BED_APPOINTMENT:
      return {
        ...state,
        week: {
          ...state.week,
          hasBedAppointment: action.payload
        }
      }
    case SET_ACTIVE_DAY:
      return {
        ...state,
        week: {
          ...state.week,
          activeDay: action.payload
        }
      }
    case SET_NOTAS_LOADER:
      return {
        ...state,
        notasLoading: true
      }
    case REMOVE_NOTAS_LOADER:
      return {
        ...state,
        notasLoading: false
      }
    default:
      return {
        ...state
      }
  }
}

export default calendarReducer
