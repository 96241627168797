import * as yup from 'yup'

const profileSchema = yup.object().shape({
  name: yup
    .string()
    // .min(3, 'El campo debe contener al menos 3 caracteres alfabéticos')
    .max(255, 'El nombre no puede contener más de 255 caracteres alfabéticos.')
    .matches(/^[a-zA-Z\sáéíóúñÑ]*$/, 'Solo se permiten caractéres alfabéticos y espacios'),
  dateToBirth: yup.string().required('Es necesario completar la fecha.'),
  avatar: yup
    .mixed()
    .test('avatar-test', 'La imagen de perfíl debe ser de tipo jpg, jpeg, o png.', function test(
      value
    ) {
      return value && !value.type.match(/(jpg|jpeg|png)/) ? false : true
    })
    .test('avatar-size', 'No puedes subir archivos de mas de 5MB.', function test(value) {
      return value && value.size > 5242880 ? false : true
    }),
  portada: yup
    .mixed()
    .test('portada-test', 'La imagen de portada debe ser de tipo jpg, jpeg, o png.', function test(
      value
    ) {
      return value && !value.type.match(/(jpg|jpeg|png)/) ? false : true
    })
    .test('avatar-size', 'No puedes subir archivos de mas de 5MB.', function test(value) {
      return value && value.size > 5242880 ? false : true
    })
})

export default profileSchema
