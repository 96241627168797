import { MONTHS, INITIAL_WEEK_NUMBER, NUMBER_OF_WEEKS } from '../constants/generics'

export const dateParse = date => {
  const d = new Date(date)
  let parsedDate = ''
  parsedDate += `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  return parsedDate
}
export const longDateParse = date => {
  const d = new Date(date)
  let parsedDate = ''
  parsedDate += `${d.getDate()} de ${MONTHS[d.getMonth()]} del ${d.getFullYear()}`
  return parsedDate
}

export const daysDifference = (dateDayOfWeek, pregnancyBeginsOn) => {
  dateDayOfWeek.setHours(0)
  dateDayOfWeek.setMinutes(0)
  dateDayOfWeek.setSeconds(0)
  pregnancyBeginsOn.setHours(0)
  pregnancyBeginsOn.setMinutes(0)
  pregnancyBeginsOn.setSeconds(0)
  return Math.round(
    Math.abs(dateDayOfWeek.getTime() - pregnancyBeginsOn.getTime()) / (3600000 * 24)
  )
}

export const noAbsDaysDifference = (dateDayOfWeek, pregnancyBeginsOn) => {
  //Normalize hours of dates.
  dateDayOfWeek.setHours(0)
  dateDayOfWeek.setMinutes(0)
  dateDayOfWeek.setSeconds(0)
  pregnancyBeginsOn.setHours(0)
  pregnancyBeginsOn.setMinutes(0)
  pregnancyBeginsOn.setSeconds(0)
  return Math.round((dateDayOfWeek.getTime() - pregnancyBeginsOn.getTime()) / (3600000 * 24))
}

export const getPregnancyBeginDate = dateToBirth => {
  const WEEKS_OFFSET = 40
  const pregnancyBeginsOn = new Date(dateToBirth)
  pregnancyBeginsOn.setDate(pregnancyBeginsOn.getDate() - WEEKS_OFFSET * 7)
  return pregnancyBeginsOn
}

export const weekNumberFromEstimatedDayNumber = estimatedDayNumber => {
  const divisionResult = estimatedDayNumber / 7
  return Number.isInteger(divisionResult) ? divisionResult - 1 : Math.floor(divisionResult)
}

export const weekNumberFromRealDateDay = (realDateDay, dateToBirth) => {
  const pregnancyBeginsOn = getPregnancyBeginDate(dateToBirth)
  const actualEstimatedDay = noAbsDaysDifference(new Date(realDateDay), pregnancyBeginsOn)
  const actualWeekNumber = weekNumberFromEstimatedDayNumber(actualEstimatedDay)
  return actualWeekNumber > INITIAL_WEEK_NUMBER
    ? actualWeekNumber > NUMBER_OF_WEEKS
      ? NUMBER_OF_WEEKS
      : actualWeekNumber
    : INITIAL_WEEK_NUMBER
}

export const getInitialWeekNumber = dateToBirth => {
  const realDate = new Date()
  const pregnancyBeginsOn = getPregnancyBeginDate(dateToBirth)
  const actualEstimatedDay = noAbsDaysDifference(realDate, pregnancyBeginsOn)
  const actualWeekNumber = weekNumberFromEstimatedDayNumber(actualEstimatedDay)
  return actualWeekNumber > INITIAL_WEEK_NUMBER
    ? actualWeekNumber > NUMBER_OF_WEEKS
      ? NUMBER_OF_WEEKS
      : actualWeekNumber
    : INITIAL_WEEK_NUMBER
}

export const getRealDateFromEstimatedDay = (estimatedDayNumber, pregnancyBeginsOn) => {
  const realDate = new Date(pregnancyBeginsOn)
  realDate.setDate(realDate.getDate() + estimatedDayNumber)
  return realDate
}

export const getDayNumbersRange = (initialDate, offsetQuantity) => {
  const dayNumbers = []
  for (let i = 0; i < offsetQuantity; i++) {
    const d = new Date(initialDate)
    d.setDate(d.getDate() + i)
    dayNumbers.push(d)
  }
  return dayNumbers
}

export const getFirstDayOfWeekNumber = (weekNumber, pregnancyBeginsOn) => {
  const firstDayDate = new Date(pregnancyBeginsOn)
  firstDayDate.setDate(firstDayDate.getDate() + weekNumber * 7)
  return firstDayDate
}
