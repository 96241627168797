import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import MailIndex from '../Mail/MailIndex'

const PrivateRoute = props => {
  const { isAuth } = props.auth
  return isAuth === 'paciente' ? (
    <Route {...props} />
  ) : isAuth === 'admin' ? (
    window.location.pathname !== '/mis-correos' ? (
      <Redirect to="/mis-correos" />
    ) : (
      <Route component={MailIndex} />
    )
  ) : (
    <Redirect to="/logout" />
  )
}

const mapStateToProps = state => {
  const { auth } = state.authReducer
  return {
    auth
  }
}

export default connect(mapStateToProps)(PrivateRoute)
