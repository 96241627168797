import React from 'react'
import { connect } from 'react-redux'

import { H3 } from '../../styled/generics'
import { TextArea, StyledForm } from '../../styled/forms'

const DetalleEmbarazo = props => {
  return (
    <StyledForm>
      <H3>Mí embarazo</H3>
      <TextArea defaultValue={props.detalle} disabled />
    </StyledForm>
  )
}

const mapStateToProps = state => {
  return {
    detalle: state.modalReducer.detalleEmbarazo
  }
}
export default connect(mapStateToProps)(DetalleEmbarazo)
