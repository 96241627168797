import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

import {
  getMensajesAPI,
  favoritearMensajeAPI,
  moverPapeleraAPI,
  actualizarMensajeAPI,
  obtenerHiloAPI,
  getDestacadosAPI
} from '../../api'
import BreadcrumbsFromUrl from '../Breadcrumbs/BreadcrumbsFromUrl'
import Footer from '../Layout/Footer'
import MailsList from './MailsList'
import Modal from '../Modal/Backdrop'
import MailForm from '../Forms/Mail/MailForm'
import { Select } from '../../styled/forms'
import { MAIL_CATEGORIES } from '../../constants/generics'
import HiloForm from '../Forms/Mail/HiloForm'

class MailIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cantidadTotalEnBandeja: 0,
      isModalOpen: false,
      modalMailId: null,
      activeCategory: MAIL_CATEGORIES.RECIBIDOS,
      activeEmails: [], //Last mails to get entire thread when opening email. => "hiloData"
      favAllActive: false,
      selectAllActive: false,
      selectedIds: [],
      actionLoading: {
        fav: false,
        del: false
      }, //Fav, Del
      orderBy: 'date',
      orderType: true //true = asc -- false = desc
    }
  }

  componentDidMount() {
    //Async call to fetch mails
    this.setCategory(this.state.activeCategory)
  }

  toggleLoadingAction = actionName => {
    //actionName => (fav|del)
    this.setState(prevState => ({
      actionLoading: {
        ...prevState.actionLoading,
        [actionName]: !prevState.actionLoading[actionName]
      }
    }))
  }

  setOrder = orderBy => {
    this.setState(prevState => {
      const sortedArray = prevState.activeEmails.sort((hiloDataA, hiloDataB) => {
        if (!prevState.orderType && orderBy === 'date') {
          return (
            Date.parse(hiloDataA.ultimoMensaje.fechaEnvio) -
            Date.parse(hiloDataB.ultimoMensaje.fechaEnvio)
          )
        }
        if (prevState.orderType && orderBy === 'date') {
          return (
            Date.parse(hiloDataB.ultimoMensaje.fechaEnvio) -
            Date.parse(hiloDataA.ultimoMensaje.fechaEnvio)
          )
        }
        if (!prevState.orderType && orderBy === 'subject') {
          return hiloDataA.ultimoMensaje.asunto.localeCompare(hiloDataB.ultimoMensaje.asunto)
        }
        return hiloDataB.ultimoMensaje.asunto.localeCompare(hiloDataA.ultimoMensaje.asunto)
      })
      return {
        ...prevState,
        activeEmails: sortedArray,
        orderType: !prevState.orderType,
        orderBy
      }
    })
  }

  setFavorite = mailId => {
    //Async call API
    this.toggleLoadingAction('fav')
    const dataIndex = this.state.activeEmails.findIndex(hiloData => hiloData.id === mailId)
    const payload = {
      idUsuario: this.props.userId,
      idMensaje: mailId,
      favorito: !this.state.activeEmails[dataIndex].tieneFavorito
    }
    payload.bandeja =
      this.state.activeCategory === MAIL_CATEGORIES.DESTACADOS
        ? this.state.activeEmails[dataIndex].category
        : this.state.activeCategory
    favoritearMensajeAPI(payload)
      .then(res => {
        this.toggleLoadingAction('fav')
        this.setState(prevState => {
          prevState.activeEmails[dataIndex].tieneFavorito = !prevState.activeEmails[dataIndex]
            .tieneFavorito
          let totalFav = 0
          prevState.activeEmails.forEach(hiloData => {
            if (hiloData.tieneFavorito) {
              totalFav++
            }
          })
          if (prevState.activeCategory === MAIL_CATEGORIES.DESTACADOS) {
            prevState.activeEmails = prevState.activeEmails.filter(
              hiloData => hiloData.id !== mailId
            )
          }
          return {
            ...prevState,
            favAllActive: totalFav === prevState.activeEmails.length
          }
        })
      })
      .catch(() => this.toggleLoadingAction('fav'))
  }

  favoriteAll = () => {
    this.toggleLoadingAction('fav')
    //TODO async call API
    Promise.all(
      this.state.activeEmails.map(hiloData => {
        const payload = {
          idUsuario: this.props.userId,
          idMensaje: hiloData.id,
          favorito: !hiloData.tieneFavorito
        }
        payload.bandeja =
          this.state.activeCategory === MAIL_CATEGORIES.DESTACADOS
            ? hiloData.category
            : this.state.activeCategory
        return favoritearMensajeAPI(payload)
      })
    )
      .then(res => {
        this.toggleLoadingAction('fav')
        this.setState(prevState => {
          prevState.activeEmails.forEach(hiloData => {
            hiloData.tieneFavorito = !prevState.favAllActive
          })
          prevState.favAllActive = !prevState.favAllActive
          if (!prevState.favAllActive && prevState.activeCategory === MAIL_CATEGORIES.DESTACADOS) {
            prevState.activeEmails = []
            prevState.selectedIds = []
          }
          return {
            ...prevState
          }
        })
      })
      .catch(err => this.toggleLoadingAction('fav'))
  }

  setSelect = selectId => {
    this.setState(prevState => {
      if (prevState.selectedIds.find(id => id === selectId)) {
        const newIds = prevState.selectedIds.filter(id => id !== selectId)
        prevState.selectedIds = newIds
      } else {
        prevState.selectedIds.push(selectId)
      }
      if (
        prevState.selectedIds.length > 0 &&
        prevState.selectedIds.length === prevState.activeEmails.length
      ) {
        return {
          ...prevState,
          selectAllActive: true
        }
      }
      return {
        ...prevState,
        selectAllActive: false
      }
    })
  }

  selectAll = () => {
    //TODO async call API
    this.setState(prevState => {
      if (prevState.selectAllActive) {
        return {
          ...prevState,
          selectedIds: [],
          selectAllActive: !prevState.selectAllActive
        }
      }
      const selectedIds = prevState.activeEmails.map(
        hiloData => hiloData.ultimoMensaje.idMensajeRaiz || hiloData.ultimoMensaje.id
      )
      prevState.selectAllActive = !prevState.selectAllActive
      return {
        ...prevState,
        selectedIds
      }
    })
  }

  deleteHandler = async () => {
    this.toggleLoadingAction('del')
    if (await window.confirm('¿Mover a "Eliminados"?')) {
      Promise.all(
        this.state.selectedIds.map(async idRaiz => {
          const urlParams = {
            idUsuario: this.props.userId,
            idMensajeRaiz: idRaiz
          }
          const hilo = await obtenerHiloAPI(urlParams)
          return hilo.data.flatMap(mensajeEnHilo =>
            moverPapeleraAPI({
              idUsuario: this.props.userId,
              idMensaje: mensajeEnHilo.id
            })
          )
        })
      )
        .then(res => this.setCategory(MAIL_CATEGORIES.ELIMINADOS))
        .catch(console.log)
    }
    this.toggleLoadingAction('del')
  }

  setCategory = (category, pagina, cantidad) => {
    //SHOULD call api everytime it renders. In case a new email is ready at backend.
    let apiCallback = getMensajesAPI
    const urlParams = {
      idUsuario: this.props.userId,
      bandeja: category,
      pagina: pagina || 1,
      cantidad: cantidad || 5
    }
    if (category === MAIL_CATEGORIES.DESTACADOS) {
      apiCallback = getDestacadosAPI
      delete urlParams.bandeja
    }
    apiCallback(urlParams)
      .then(res => {
        const cantidadTotalEnBandeja = res.data.cantidadTotal
        const activeEmails = res.data.mensajes.map(hiloData => ({
          ...hiloData,
          id: hiloData.ultimoMensaje.idMensajeRaiz || hiloData.ultimoMensaje.id
        }))
        const newState = {
          activeCategory: category,
          activeEmails,
          cantidadTotalEnBandeja,
          selectAllActive: false,
          selectedIds: []
        }
        let favCounter = 0
        activeEmails.forEach(hiloData => (hiloData.tieneFavorito ? favCounter++ : null))
        newState.favAllActive = favCounter === activeEmails.length && activeEmails.length > 0
        this.setState(newState)
      })
      .catch(console.log)
  }

  hideMailModal = categoryToRefresh => {
    this.setState({ isModalOpen: false })
    if (typeof categoryToRefresh === 'string') {
      this.setCategory(categoryToRefresh)
    }
  }

  showMailModal = modalMailId =>
    this.setState({ isModalOpen: true, modalMailId, esNuevoMensaje: true })

  openMail = async raizId => {
    const activeEmailsIndex = this.state.activeEmails.findIndex(hiloData => hiloData.id === raizId)
    const hilo = await obtenerHiloAPI({
      idUsuario: this.props.userId,
      idMensajeRaiz: raizId,
      esPapelera:
        this.state.activeCategory === MAIL_CATEGORIES.ELIMINADOS ||
        !!this.state.activeEmails[activeEmailsIndex].category
    })
    Promise.all(
      hilo.data.map(mensajeDeHilo => {
        const payload = {
          leido: true,
          idUsuario: this.props.userId,
          idMensaje: mensajeDeHilo.id,
          favorito: mensajeDeHilo.favorito
        }
        payload.bandeja =
          this.state.activeCategory === MAIL_CATEGORIES.DESTACADOS
            ? this.state.activeEmails[activeEmailsIndex].category
            : this.state.activeCategory
        return actualizarMensajeAPI(payload)
      })
    )
      .then(res => {
        this.setState(prevState => {
          const index = prevState.activeEmails.findIndex(hiloData => hiloData.id === raizId)
          prevState.activeEmails[index].ultimoMensaje.leido = true
          return {
            isModalOpen: true,
            modalMailId: raizId,
            esNuevoMensaje: false,
            activeEmails: [...prevState.activeEmails]
          }
        })
      })
      .catch(console.log)
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isModalOpen && (
          <Modal isCloseable customCloseHandler={this.hideMailModal}>
            {this.state.esNuevoMensaje ||
            this.state.activeCategory === MAIL_CATEGORIES.BORRADORES ? (
              <MailForm
                idMail={this.state.modalMailId}
                userFrom={this.props.userId}
                closeModalCallback={this.hideMailModal}
                title={this.state.esNuevoMensaje ? 'Nuevo Mensaje' : 'Editar borrador'}
                esBorrador={
                  this.state.activeCategory === MAIL_CATEGORIES.BORRADORES &&
                  !this.state.esNuevoMensaje
                }
              />
            ) : (
              <HiloForm
                title="Tus mensajes"
                userId={this.props.userId}
                isAdmin={this.props.isAdmin}
                idMail={this.state.modalMailId}
                esPapelera={
                  this.state.activeCategory === MAIL_CATEGORIES.ELIMINADOS ||
                  !!this.state.activeEmails.find(
                    hiloData => hiloData.category === MAIL_CATEGORIES.ELIMINADOS
                  )
                }
              />
            )}
          </Modal>
        )}
        <Container>
          <StyledBreadcrumbs />
          <Main>
            {!this.props.isAdmin && (
              <RelevantActionMobile onClick={() => this.showMailModal(null)}>
                Nuevo +
              </RelevantActionMobile>
            )}
            <StyledSelect
              value={this.state.activeCategory}
              onChange={event => this.setCategory(event.target.value)}
            >
              <option value={MAIL_CATEGORIES.RECIBIDOS}>Bandeja de entrada</option>
              <option value={MAIL_CATEGORIES.DESTACADOS}>Destacados</option>
              <option value={MAIL_CATEGORIES.ENVIADOS}>Enviados</option>
              <option value={MAIL_CATEGORIES.BORRADORES}>Borradores</option>
              <option value={MAIL_CATEGORIES.ELIMINADOS}>Eliminados</option>
            </StyledSelect>
            <MailMenu>
              {!this.props.isAdmin && (
                <RelevantActionContainer>
                  <RelevantAction>
                    <Span onClick={() => this.showMailModal(null)}>Nuevo +</Span>
                  </RelevantAction>
                </RelevantActionContainer>
              )}
              <MailCategories>
                <Category isActive={this.state.activeCategory === MAIL_CATEGORIES.RECIBIDOS}>
                  <Span onClick={() => this.setCategory(MAIL_CATEGORIES.RECIBIDOS)}>
                    Bandeja de entrada
                  </Span>
                </Category>
                <Category isActive={this.state.activeCategory === MAIL_CATEGORIES.DESTACADOS}>
                  <Span onClick={() => this.setCategory(MAIL_CATEGORIES.DESTACADOS)}>
                    Destacados
                  </Span>
                </Category>
                <Category isActive={this.state.activeCategory === MAIL_CATEGORIES.ENVIADOS}>
                  <Span onClick={() => this.setCategory(MAIL_CATEGORIES.ENVIADOS)}>Enviados</Span>
                </Category>
                <Category isActive={this.state.activeCategory === MAIL_CATEGORIES.BORRADORES}>
                  <Span onClick={() => this.setCategory(MAIL_CATEGORIES.BORRADORES)}>
                    Borradores
                  </Span>
                </Category>
                <Category isActive={this.state.activeCategory === MAIL_CATEGORIES.ELIMINADOS}>
                  <Span onClick={() => this.setCategory(MAIL_CATEGORIES.ELIMINADOS)}>
                    Eliminados
                  </Span>
                </Category>
              </MailCategories>
            </MailMenu>
            <MailsContainer>
              <MailsList
                totalEnBandeja={this.state.cantidadTotalEnBandeja}
                activeCategory={this.state.activeCategory}
                setCategory={this.setCategory}
                mails={this.state.activeEmails}
                selectAllActive={this.state.selectAllActive}
                selectedIds={this.state.selectedIds}
                selectAll={this.selectAll}
                setSelect={this.setSelect}
                favAllActive={this.state.favAllActive}
                favoriteAll={this.favoriteAll}
                setFavorite={this.setFavorite}
                setOrder={this.setOrder}
                isOrderedBy={this.state.orderBy}
                deleteHandler={this.deleteHandler}
                actionLoading={this.state.actionLoading}
                openMail={this.openMail}
              />
            </MailsContainer>
          </Main>
        </Container>
        <Footer />
      </React.Fragment>
    )
  }
}

const StyledSelect = styled(Select)`
  width: 100%;
  @media screen and (min-width: 801px) {
    display: none;
  }
`

const Span = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const StyledBreadcrumbs = styled(BreadcrumbsFromUrl)`
  width: 90%;
  margin-bottom: 0;
`
const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 62vh;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  @media screen and (max-width: 800px) {
    justify-content: space-evenly;
  }
`
const Main = styled.main`
  min-height: 41vh;
  margin-bottom: 2%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`
const MailMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
  background-color: ${props => props.theme.themeColors[props.theme.selected].quinaryColor};
  padding: 1% 0;
  height: 50vh;
  @media screen and (max-width: 800px) {
    display: none;
  }
`
const RelevantAction = styled.div`
  width: 70%;
  font-weight: 600;
`
const RelevantActionMobile = styled(RelevantAction)`
  @media screen and (min-width: 801px) {
    display: none;
  }
`

const RelevantActionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 15%;
  border-bottom: 5px solid ${props => props.theme.colors.white};
  margin-bottom: 5%;
`
const MailCategories = styled.ul`
  height: 80%;
  width: 70%;
  list-style-type: none;
  padding: 0;
  margin: 0;
`
const Category = styled.li`
  min-height: 6vh;
  ${props =>
    props.isActive &&
    css`
      font-weight: 600;
    `}
`
const MailsContainer = styled.div`
  width: 70vw;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

const mapStateToProps = state => {
  return {
    userId: state.authReducer.auth.usuarioId,
    isAdmin: state.authReducer.auth.isAuth === 'admin'
  }
}

export default connect(mapStateToProps)(MailIndex)
