const colors = {
  lightYellow: '#F8E71E',
  darkYellow: '#F0B615',
  pink: '#F0ADB8',
  paleRose: '#fbc5cb',
  lightBlue: '#a0dcf2',
  lile: '#b38df7',
  lightGreen: '#b8e986',
  styledRed: '#ab0015',
  transparentGray: '#dcdcdcc7',
  opaqueRed: '#802d37',
  lightOpaqueRed: 'rgba(128, 45, 55, 0.57)',
  tinylightOpaqueRed: 'rgba(128, 45, 55, 0.9)',
  opaquePink: '#e59ea4',
  fadedPink: '#eac0c5',
  lightPink: '#ffdbe0',
  roseFog: '#fcd3d8',
  vanillaIce: '#f5dde0',
  chablis: '#ffedef',
  superLightPink: '#fff5f7',
  uglyPink: '#cc7279',
  softPink: '#faaeb8',
  red: '#ac0016',
  lightRed: '#ab00154f',
  plum: '#62141d',
  plumTwo: '#460e14',
  grayWhite: '#d8d8d8',
  lightGray: '#e1dfe4',
  transparentGreen: 'rgba(113, 189, 106, 0.685)',
  strongGray: '#7a6f6f',
  gray: '#a1a4a0b8',
  darkGrayBlue: '#34495e',
  black: '#000000',
  transparentBlack: 'rgba(0, 0, 0, 0.8)',
  superTransparentBlack: 'rgba(0, 0, 0, 0.6)',
  white: '#ffffff',
  transparent: 'transparent',
  fernGreen: '#417941',
  chateauGreen: '#3eb53efc',
  greenBrown: '#573d02',
  poo: '#9a6c04',
  yellowOchre: '#de9a00',
  orangeYellow: '#ffbb22',
  lightGold: '#fbcc61',
  palePeach: '#ffe6ae',
  eggShell: '#ffedc3',
  // warmGrey: '#9b9b9b',
  appleGreen: '#6db820',
  niceBlue: '#1987af',
  blueyPurple: '#6d39ca',
  darkOliveGreen: '#2a5202',
  darkGrassGreen: '#397100',
  springGreen: '#aef762',
  washedOutGreen: '#c0ef8f',
  lightSage: '#dcf1c7',
  lightSageTwo: '#ecf8de',
  navy: '#012c3b',
  petrol: '#014d69',
  darkSkyBlue: '#4fb3d8',
  lightBlueTwo: '#91d8f1',
  duckEggBlue: '#c7defa',
  duckBlue: '#e4f5fb',
  midnightPurple: '#190145',
  indigo: '#2a0076',
  lavender: '#c2aaeb',
  paleLavender: '#ede4fe',
  veryLightPurple: '#f2edfc',
  wewak: '#F0ADB8',
  greyFour: '#827f7f',
  greyFive: '#f6f5f5',
  warmGrey: '#9e9e9e'
}

export default colors
