import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { AddAPhoto, Delete } from 'styled-icons/material'
// import * as yup from 'yup'

// import validationSchema from '../../../validation/notaSchema'
import { FileInputContainer, FileInputLabel } from '../../../styled/forms'
import { ImgAvatar } from '../../../styled/generics'
import { NOTA_FILE_TYPE } from '../../../constants/generics'

class ImagesRow extends Component {
  uploadHandler = (event, photoIndex) => {
    event.persist()
    const { files } = event.target
    if (!files.length) {
      return
    }
    //Could use index to perform some state change..
    this.props.fileHandler(event, NOTA_FILE_TYPE.PHOTO)
  }

  removeHandler = (event, photoIndex, photoId) => {
    event.preventDefault()
    this.props.removeHandler(photoId ? photoId : photoIndex)
  }

  render() {
    return (
      <ImagesContainer>
        {[0, 1, 2].map(index => (
          <ImageContainer key={index}>
            <ImageLabel isActive={!!this.props.photos[index]}>
              {!this.props.photos[index] && (
                <ImageInput
                  type="file"
                  name="photos"
                  onChange={event => this.uploadHandler(event, index)}
                />
              )}
              {!this.props.photos[index] ? (
                <AddPhotoIcon isActive={!!this.props.photos[index]} size={'2em'} />
              ) : (
                <RemoveIcon
                  onClickCapture={event => this.removeHandler(event, index)}
                  isActive={!!this.props.photos[index]}
                  size={'2em'}
                />
              )}
            </ImageLabel>
            {!!this.props.photos[index] && <ImagePreview src={this.props.photos[index].urlBlob} />}
          </ImageContainer>
        ))}
      </ImagesContainer>
    )
  }
}

const ImageInput = styled.input`
  width: 100px;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
`
const AddPhotoIcon = styled(AddAPhoto)`
  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.strongGray};
    `}
`
const RemoveIcon = styled(Delete)`
  /* background-color: ${props => props.theme.colors.styledRed}; */
  border-radius: 50%;
  color:  ${props => props.theme.colors.styledRed};
  transition: transform 0.4s ease;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`

const ImageLabel = styled(FileInputLabel)`
  ${props =>
    props.isActive &&
    css`
      cursor: auto;
    `}
  width: 100px;
  height: 100px;
  border-radius: 0;
  box-shadow: none;
  border: none;
  transition: all 0.3s ease;
  ${props =>
    !props.isActive &&
    css`
      &:hover svg {
        transform: scale(1.1);
      }
      background-color: ${props => props.theme.colors.grayWhite};
      opacity: 1;
    `}
`

const ImageContainer = styled(FileInputContainer)`
  flex: 0 0 33%;
  height: 100px;
  display: flex;
  justify-content: center;
`

const ImagePreview = styled(ImgAvatar)`
  border-radius: 0;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 80%;
  overflow: hidden;
  color: ${props => props.theme.colors.white};
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`

export default ImagesRow
