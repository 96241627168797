import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import styled, { css } from 'styled-components'
import { CameraAlt } from 'styled-icons/material'

import schema from '../../validation/profileSchema'
import { longDateParse } from '../../utils/date'
import { setAvatar, setPortada, getAvatar, getPortada } from '../../redux/actions/profileActions'
import LOADING_PORTADA from '../../img/profile/portada_loading.png'
import LOADING_AVATAR from '../../img/profile/avatar_loading.png'

import { H2, H5, PrimaryButton, SecondaryButton } from '../../styled/generics'
import ImageCropper from '../Cropper/ImageCropper'
import Modal from '../Modal/Backdrop'
import { AVATAR_RATIO, PORTADA_RATIO } from '../../constants/generics'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPortadaLoaded: false,
      isAvatarLoaded: false,
      isPhotoEditorOpen: false,
      editingPhoto: null,
      cropperRef: React.createRef()
    }
  }

  componentDidMount() {
    const { getAvatar, getPortada, usuarioId, embarazoDoesExist } = this.props
    if (embarazoDoesExist) {
      getAvatar(usuarioId)
      getPortada(usuarioId)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.embarazoDoesExist !== this.props.embarazoDoesExist) {
      const { getAvatar, getPortada, usuarioId } = this.props
      getAvatar(usuarioId)
      getPortada(usuarioId)
    }
  }

  onLoadPortadaHandler = event => {
    this.setState({ isPortadaLoaded: true })
  }
  onLoadAvatarHandler = event => {
    this.setState({ isAvatarLoaded: true })
  }

  setPhotoToCrop = event => {
    event.persist()
    if (!event.target.files.length) {
      return
    }
    const { name, files } = event.target
    const payload = files[0]
    yup
      .reach(schema, name)
      .validate(payload)
      .then(result => {
        this.setState({ isPhotoEditorOpen: true, editingPhoto: { [name]: payload } })
        event.target.value = null
      })
      .catch(err => {
        event.target.value = null
        alert(err.message)
      })
  }

  cancelHandler = () => {
    this.setState({ isPhotoEditorOpen: false, editingPhoto: null })
  }

  fileInputHandler = () => {
    const { cropperRef, editingPhoto } = this.state
    const [photoName] = Object.entries(editingPhoto)[0]
    const newState = {
      editingPhoto: null,
      isPhotoEditorOpen: false
    }

    cropperRef.current.getCroppedCanvas().toBlob(blob => {
      if (photoName === 'portada') {
        newState.isPortadaLoaded = true
        this.props.setPortada(
          new File([blob], editingPhoto[photoName].name),
          this.onLoadPortadaHandler
        )
        this.setState(newState)
      } else {
        newState.isAvatarLoaded = true
        this.props.setAvatar(
          new File([blob], editingPhoto[photoName].name),
          this.onLoadAvatarHandler
        )
        this.setState(newState)
      }
    }, editingPhoto[photoName].type)
  }

  render() {
    return (
      <>
        {this.state.isPhotoEditorOpen && (
          <Modal customCloseHandler={this.cancelHandler} isCloseable>
            <TitleContainer>
              <H2>Redimensioná tu foto</H2>
              <ModalDescription>
                Seleccioná el area de tu foto para poder mantener la relación de aspecto de la
                misma.
              </ModalDescription>
            </TitleContainer>
            <CropperMainContainer>
              <CropperContainer isAvatar={!!this.state.editingPhoto.avatar}>
                <ImageCropper
                  ref={this.state.cropperRef}
                  aspectRatio={
                    (this.state.editingPhoto.portada && PORTADA_RATIO) ||
                    (this.state.editingPhoto.avatar && AVATAR_RATIO)
                  }
                  imgSrc={URL.createObjectURL(
                    this.state.editingPhoto.portada || this.state.editingPhoto.avatar
                  )}
                />
              </CropperContainer>
            </CropperMainContainer>
            <ButtonContainer>
              <AcceptButton onClick={this.fileInputHandler}>Aceptar</AcceptButton>
              <CancelButton onClick={this.cancelHandler}>Cancelar</CancelButton>
            </ButtonContainer>
          </Modal>
        )}
        <StyledHeader>
          <PortadaContainer>
            {this.state.isPortadaLoaded && (
              <PortadaLabel>
                <FileInput type="file" name="portada" onChange={this.setPhotoToCrop} />
                <CameraPortada size={'2em'} />
              </PortadaLabel>
            )}
            <ResponsiveWrapper>
              <PlaceholderPortada
                isShown={!this.state.isPortadaLoaded}
                src={LOADING_PORTADA}
                alt="placeholder"
              />
              <StyledImgPortada
                onLoad={this.onLoadPortadaHandler}
                isShown={this.state.isPortadaLoaded}
                src={this.props.portada.url}
                alt="portada"
              />
            </ResponsiveWrapper>
          </PortadaContainer>
          <ProfileDataContainer>
            <AvatarContainer>
              {this.state.isAvatarLoaded && (
                <AvatarLabel>
                  <FileInput type="file" name="avatar" onChange={this.setPhotoToCrop} />
                  <CameraAvatar size={'2em'} />
                </AvatarLabel>
              )}
              <AvatarPlaceholder
                isShown={!this.state.isAvatarLoaded}
                src={LOADING_AVATAR}
                alt="placeholder"
              />
              <StyledAvatarImg
                onLoad={this.onLoadAvatarHandler}
                isShown={this.state.isAvatarLoaded}
                src={this.props.avatar.url}
                alt="avatar"
              />
            </AvatarContainer>
            <ProfileDataInformation>
              <ProfileName>{this.props.name}</ProfileName>
              <ProfileDateToBirthHelper>Fecha probable de parto</ProfileDateToBirthHelper>
              <ProfileDateToBirth>{longDateParse(this.props.dateToBirth)}</ProfileDateToBirth>
            </ProfileDataInformation>
          </ProfileDataContainer>
        </StyledHeader>
      </>
    )
  }
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  width: 100%;
`
const AcceptButton = styled(PrimaryButton)`
  width: 40%;
`

const CancelButton = styled(SecondaryButton)`
  width: 40%;
`

const CropperMainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CropperContainer = styled.div`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  border: 3px solid;
  width: 90%;
  ${props =>
    props.isAvatar &&
    css`
      .cropper-view-box,
      .cropper-face {
        border-radius: 50%;
      }
    `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`

const ModalDescription = styled(H5)`
  width: 80%;
  text-align: center;
`

const ResponsiveWrapper = styled.div`
  padding-top: 26.25%;
`

const FileInput = styled.input`
  opacity: 0;
  width: 0;
  height: 100%;
  position: absolute;
`

const CameraPortada = styled(CameraAlt)`
  cursor: pointer;
  color: ${props => props.theme.colors.transparentGray};
  @media screen and (max-width: 800px) {
    color: ${props => props.theme.colors.white};
  }
`
const CameraAvatar = styled(CameraPortada)``

const PortadaContainer = styled.div`
  position: relative;
  &:hover label {
    opacity: 1;
  }
`
const PortadaLabel = styled.label`
  z-index: 10;
  position: absolute;
  bottom: 4%;
  right: 4%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 7px;
  background-color: ${props => props.theme.colors.transparentBlack};
  border-radius: 50%;
  box-shadow: 2px 2px 3px 1px ${props => props.theme.colors.transparentBlack};
  opacity: 0;
  transition: opacity 0.3s ease;
  border: 2px solid ${props => props.theme.colors.white};
  @media screen and (max-width: 800px) {
    opacity: 1;
  }
`

const AvatarContainer = styled.div`
  position: relative;
  margin-top: -5em;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover label {
    opacity: 1;
  }
  @media screen and (max-width: 800px) {
    max-height: 8em;
    margin-top: -2em;
    justify-content: flex-end;
    align-items: flex-end;
  }
`
const AvatarLabel = styled.label`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 7px;
  background-color: ${props => props.theme.colors.transparentBlack};
  border-radius: 50%;
  box-shadow: 2px 2px 3px 1px ${props => props.theme.colors.transparentBlack};
  border: solid 2px ${props => props.theme.colors.white};
  @media screen and (max-width: 800px) {
    opacity: 1;
  }
`
const PlaceholderPortada = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  opacity: 1;
  @media screen and (max-width: 800px) {
    max-height: 100%;
  }
  ${props =>
    props.isShown
      ? css`
          animation: loading 0.8s ease-in-out infinite;
        `
      : css`
          display: none;
        `}
  @keyframes loading {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
const StyledImgPortada = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  @media screen and (max-width: 800px) {
    max-height: 100%;
  }
  ${props =>
    props.isShown
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`

const ProfileDataContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 0 2%;
`

const StyledAvatarImg = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  box-shadow: -3px 23px 89px -41px rgba(0, 0, 0, 1);
  object-fit: fill;
  @media screen and (max-width: 800px) {
    width: 8em;
    height: 8em;
  }
  ${props =>
    props.isShown
      ? css`
          opacity: inherit;
        `
      : css`
          display: none;
        `}
`
const AvatarPlaceholder = styled.img`
  border-radius: 50%;
  width: 200px;
  height: 200px;
  box-shadow: -3px 23px 89px -41px rgba(0, 0, 0, 1);
  object-fit: fill;
  @media screen and (max-width: 800px) {
    width: 8em;
    height: 8em;
  }
  ${props =>
    props.isShown
      ? css`
          animation: loading 0.8s ease-in-out infinite;
        `
      : css`
          display: none;
        `}
  @keyframes loading {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const ProfileDataInformation = styled.div`
  align-self: flex-start;
  padding-left: 1em;
  @media screen and (max-width: 800px) {
    padding-left: 5vw;
  }
`
const ProfileName = styled.h3`
  margin: 0;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  font-size: 2rem;
  word-break: break-word;
`
const ProfileDateToBirth = styled.h4`
  margin: 0;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
  font-size: 1.3rem;
`
const ProfileDateToBirthHelper = styled.small`
  color: ${props => props.theme.colors.strongGray};
  font-weight: 600;
  font-size: 0.8rem;
`

const StyledHeader = styled.header`
  /*Queda en caso de reemplazar img por div*/
  & div.portada {
    background-image: url('../../img/profile/profile_portada.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100vw;
    max-height: 150px;
  }
`

const mapStateToProps = state => {
  const { name, dateToBirth, embarazoDoesExist } = state.profileReducer
  const { usuarioId, isNewUser } = state.authReducer.auth
  const { portada, avatar } = state.profileImagesReducer
  const themeName = state.profileReducer.color.name
  return {
    name,
    dateToBirth,
    avatar,
    portada,
    themeName,
    usuarioId,
    isNewUser,
    embarazoDoesExist
  }
}

const mapDispatchToProps = {
  setPortada,
  setAvatar,
  getPortada,
  getAvatar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
