import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Redirect } from 'react-router-dom'

import envConfig from '../../envConfig'

class Unauthorized extends Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      time: 5
    }
  }

  componentDidMount() {
    const { didLogout, isAuth } = this.props
    if (didLogout || !isAuth) {
      window.sessionStorage.clear()
      const intervalId = setInterval(() => {
        this.setState(prevState => {
          return {
            time: prevState.time - 1
          }
        })
      }, 1000)
      setTimeout(() => {
        clearInterval(intervalId)
        const { PORTAL_URL } = envConfig.endpoints
        window.location = PORTAL_URL
      }, 5000)
    }
  }

  render() {
    const { isAuth, didLogout } = this.props
    const { time } = this.state
    return isAuth ? (
      <Redirect to="/" />
    ) : (
      <h1>
        {`${
          didLogout ? 'Usuario deslogueado' : 'Acceso no autorizado'
        } será redirigido al portal de clínicas en ${time} segundos.`}
      </h1>
    )
  }
}

const mapStateToProps = state => {
  const { isAuth, didLogout } = state.authReducer.auth
  return {
    isAuth,
    didLogout
  }
}

export default connect(mapStateToProps)(Unauthorized)
