import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AccessAlarm, LocalHotel } from 'styled-icons/material'

import { getEspecialidadPorId } from '../../api'
import { daysDifference } from '../../utils/date'
import { setEspecialidades, setModalType, showModal } from '../../redux/actions/modalActions'
import { setError } from '../../redux/actions/feedbackMessageActions'
import { ID_RESERVA_CAMA, MODAL_TYPES, RESERVA_CAMA_DAYS_PROXIMITY } from '../../constants/generics'

const Novedades = props => {
  const reservaCamaHandler = () => {
    getEspecialidadPorId(ID_RESERVA_CAMA)
      .then(res => {
        if (res.data) {
          props.setEspecialidades([res.data])
          props.setModalType(MODAL_TYPES.RESERVA_CAMA)
          props.showModal()
        }
      })
      .catch(console.log)
  }
  const diff = daysDifference(new Date(), new Date(props.dateToBirth))
  return (
    !props.hasBedAppointment &&
    diff <= RESERVA_CAMA_DAYS_PROXIMITY && (
      <Container>
        <NovedadContainer>
          <NovedadInfoContainer>
            <NovedadIcon />
            <NovedadText>
              Ya podés reservar una cama para que ese día disfrutes de todo y no te tengas que
              preocupar por nada.
            </NovedadText>
          </NovedadInfoContainer>
          <ActionContainer>
            <ReservaIcon />
            <ActionLink onClick={reservaCamaHandler}>Reservar cama</ActionLink>
          </ActionContainer>
        </NovedadContainer>
      </Container>
    )
  )
}
const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  height: 40vh;
  @media screen and (max-width: 800px) {
    height: 45vh;
  }
`
const NovedadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 56%;
  color: ${props => props.theme.colors.white};
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].novedadContainerBackground};
  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: auto;
    padding: 3%;
  }
`
const NovedadInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
`
const NovedadText = styled.span`
  text-align: center;
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ActionLink = styled.span`
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`
const ReservaIcon = styled(LocalHotel)`
  height: 4rem;
  width: 4rem;
`
const NovedadIcon = styled(AccessAlarm)`
  height: 4rem;
  width: 4rem;
  @media screen and (max-width: 800px) {
    display: none;
  }
`
const mapDispatchToProps = {
  setEspecialidades,
  showModal,
  setModalType,
  setError
}

const mapStateToProps = state => {
  const { hasBedAppointment } = state.calendarReducer.week
  const { dateToBirth } = state.profileReducer
  return {
    hasBedAppointment,
    dateToBirth
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Novedades)
