import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { KeyboardArrowLeft } from 'styled-icons/material'

import { ACTION_ICON_SIZE } from '../../constants/generics'

const Breadcrumbs = props => {
  const BACK_INDEX = 1
  const BASEPATH_INDEX = props.routes.length - 2

  const routesIterator = routes => {
    const paths = routes.slice(0, routes.length - 2)
    paths.reverse()
    return paths.map(({ title, path }, index) => (
      <Fragment key={`${title}--${index}`}>
        <PathSpan>/</PathSpan>
        <StyledLink exact to={path}>
          {title}
        </StyledLink>
      </Fragment>
    ))
  }

  return (
    <Container className={props.className} style={{ ...props.style }}>
      <Backbutton to={props.routes[BACK_INDEX].path}>
        <BackIcon size={ACTION_ICON_SIZE} />
        <Span>Volver</Span>
      </Backbutton>
      <Path>
        <BasePath exact to={props.routes[BASEPATH_INDEX].path}>
          {props.routes[BASEPATH_INDEX].title}
        </BasePath>
        {routesIterator(props.routes)}
      </Path>
    </Container>
  )
}

Breadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string.isRequired, path: PropTypes.string.isRequired })
  ).isRequired
}

const Container = styled.div`
  width: 80%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 2%;
`
const Backbutton = styled(Link)`
  display: flex;
  align-items: center;
  width: 5vw;
  color: ${props => props.theme.colors.darkGray};
  text-decoration: none;

  &:active {
    text-decoration-color: ${props => props.theme.colors.darkGray};
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.darkGray};
  }
  &:hover svg {
    transform: scale(1.2);
  }
  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2em;
  }
`
const BackIcon = styled(KeyboardArrowLeft)`
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
const Span = styled.span`
  height: 99%;
  color: ${props => props.theme.colors.strongGray};
  user-select: none;
`
const BasePath = styled(NavLink)`
  text-decoration: none;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.active {
    cursor: default;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  }
`
const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.active {
    text-decoration: underline;
    text-decoration-color: inherit;
  }
`
const Path = styled.span`
  color: ${props => props.theme.themeColors[props.theme.selected].quaternaryColor};
  font-weight: 600;
  font-size: 20px;
  user-select: none;
  text-transform: capitalize;
  @media screen and (max-width: 800px) {
    font-size: 1.2em;
  }
`
const PathSpan = styled.span`
  margin: 0 0.3% 0 0.3%;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
export default withRouter(Breadcrumbs)
