import React from 'react'
import styled from 'styled-components'

const CardsRow = props => {
  return <Row {...props}>{props.children}</Row>
}

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50%;

  @media screen and (max-width: 800px) {
    height: auto;
    flex-wrap: wrap;
  }
`

export default CardsRow
