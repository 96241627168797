import React, { Fragment } from 'react'
import styled from 'styled-components'

import Header from './Header'
import Footer from './Footer'
import Servicios from './Servicios'
import Novedades from './Novedades'
import CalendarContainer from '../Calendar/CalendarContainer'

const Layout = props => {
  return (
    <Fragment>
      <Header />
      <Main>
        <CalendarContainer />
        <Novedades />
        <Servicios />
      </Main>
      <Footer />
    </Fragment>
  )
}

const Main = styled.main`
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }
`

export default Layout
