import React, { Component } from 'react'
// import PropTypes from 'prop-types'

//Same as redux connects pattern
const withBlogData = (listData, breadcrumbsRoutes) => WrappedComponent => {
  class ComponentWithBlogData extends Component {
    render() {
      return (
        <WrappedComponent {...this.props} list={listData} breadcrumbsRoutes={breadcrumbsRoutes} />
      )
    }
  }
  return ComponentWithBlogData
}

export default withBlogData
