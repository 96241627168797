import React, { Component } from 'react'

import Modal from '../Modal/Backdrop'
import FeedbackMessage from './FeedbackMessage'

import envConfig from '../../envConfig'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorTitle: '',
      errorMessage: '',
      timer: 5,
      redirect: false
    }
  }

  componentDidCatch() {
    const intervalId = setInterval(() => {
      this.setState(prevState => {
        return {
          timer: prevState.timer - 1,
          errorMessage: `Ha ocurrido un error será redirigido al portal de clínicas en ${prevState.timer -
            1}`
        }
      })
    }, 1000)
    setTimeout(() => {
      clearInterval(intervalId)
      const { PORTAL_URL } = envConfig.endpoints
      window.location = PORTAL_URL
    }, 5000)

    this.setState(prevState => ({
      hasError: true,
      errorTitle: 'Error inesperado',
      errorMessage: `Ha ocurrido un error será redirigido al portal de clínicas en ${
        prevState.timer
      }`
    }))
  }

  render() {
    const { hasError, errorTitle, errorMessage } = this.state
    const { children } = this.props
    return hasError ? (
      <Modal>
        <FeedbackMessage isError title={errorTitle} message={errorMessage} />
      </Modal>
    ) : (
      children
    )
  }
}

export default ErrorBoundary
