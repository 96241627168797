import AVATAR_PLACEHOLDER from '../../img/profile/avatar_placeholder.jpg'
import PORTADA_PLACEHOLDER from '../../img/profile/header_placeholder.png'

import COLORS from '../../styled/colors'

import { getMensajesAPI, getEmbarazo } from '../../api'
import { pureSetProfile } from './profileActions'
import { constants } from '../../constants/reduxActions'
const {
  LOGIN_PACIENTE,
  LOGIN_ADMIN,
  REDIRECT_LOGOUT,
  LOGOUT,
  SET_NEW_USER,
  REMOVE_NEW_USER,
  SET_TOKEN,
  SET_PORTADA,
  SET_AVATAR
} = constants

/**
 * Set token
 */
export const setToken = payload => {
  return {
    type: SET_TOKEN,
    payload
  }
}

/**
 * Logout forbbiden user
 */
export const logoutForbbiden = () => {
  return {
    type: REDIRECT_LOGOUT
  }
}

/**
 * Logout from perfil menu
 */
export const cleanLogout = () => {
  return {
    type: LOGOUT
  }
}

/**
 * Login PP_ADMIN
 */
export const loginAdmin = ({ token, usuarioId }) => dispatch => {
  dispatch(setToken(token))
  getMensajesAPI({
    usuarioId: usuarioId,
    bandeja: 'entrada',
    pagina: '1',
    cantidad: '1'
  })
    .then(res => {
      dispatch({
        type: LOGIN_ADMIN,
        payload: { token, usuarioId }
      })
    })
    .catch(console.log)
}

const setLoginData = payloadObject => ({
  type: LOGIN_PACIENTE,
  payload: payloadObject
})

/**
 * Login Paciente
 */
export const loginPaciente = ({ token, usuarioId }) => dispatch => {
  dispatch(setToken(token))
  getEmbarazo(usuarioId)
    .then(async res => {
      if (res.data) {
        let color = {
          hex: COLORS.fadedPink,
          name: 'main'
        }
        const { fechaEstimadaParto, nombre, sexo } = res.data
        if (res.data.color) {
          const foundColorEntry = Object.entries(COLORS).find(
            ([colorName, colorHex]) => colorHex === res.data.color
          )
          if (foundColorEntry) {
            const [colorName, colorHex] = foundColorEntry
            color.name = colorName === 'fadedPink' ? 'main' : colorName
            color.hex = colorHex
          }
        }
        const dataTodispatch = {
          usuarioId,
          nombre,
          sexo,
          fechaEstimadaParto,
          color
        }
        dispatch(pureSetProfile(dataTodispatch))
        return dispatch(setLoginData({ usuarioId, token }))
      }
      dispatch(setLoginData({ usuarioId, token }))
      dispatch({
        type: SET_PORTADA,
        url: PORTADA_PLACEHOLDER
      })
      dispatch({
        type: SET_AVATAR,
        url: AVATAR_PLACEHOLDER
      })
      dispatch(setIsNewUser())
    })
    .catch(console.log)
}

/**
 * Remove isNewUser from auth
 */
export const removeisNewUser = () => {
  return {
    type: REMOVE_NEW_USER
  }
}
/**
 * Set isNewUser from auth
 */
export const setIsNewUser = () => {
  return {
    type: SET_NEW_USER
  }
}
