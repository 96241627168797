import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { loginAdmin, loginPaciente, logoutForbbiden } from '../../redux/actions/authActions'
import FeedbackMessage from '../Feedback/FeedbackMessage'
import Modal from '../Modal/Backdrop'
import { tokenParse } from '../../utils/tokenParse'

class Verification extends Component {
  componentDidMount() {
    const { isAuth } = this.props.auth
    if (isAuth === 'admin' || isAuth === 'paciente') {
      return
    }
    const searchParams = new URLSearchParams(this.props.location.search)
    const token = searchParams.get('token')
    const isAdmin = searchParams.get('isAdmin')
    if (!token) {
      return this.props.logoutForbbiden()
    }
    const authObject = tokenParse(token)
    if (isAdmin === 'true') {
      return this.props.loginAdmin(authObject)
    }
    this.props.loginPaciente(authObject)
  }

  render() {
    const { isAuth } = this.props.auth
    return this.props.messageShown ? (
      <Modal isFeedbackMessage>
        <FeedbackMessage
          title="Error de comunicación"
          message="Ocurrió un error de conexión."
          isError
        />
      </Modal>
    ) : isAuth === null ? null : (
      <Redirect to="/" />
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.authReducer.auth,
    messageShown: state.feedbackMessageReducer.messageShown
  }
}

const mapDispatchToProps = {
  loginPaciente,
  loginAdmin,
  logoutForbbiden
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verification)
