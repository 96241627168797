import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import BACKGROUND_IMAGE from '../../img/blog/blog_card_background.png'

const BlogCard = props => (
  <StyledLink to={props.link} {...props}>
    <Label>
      <TitleSpan>{props.title}</TitleSpan>
      <DetailSpan>{props.detail}</DetailSpan>
    </Label>
  </StyledLink>
)

BlogCard.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.string
}
const TitleSpan = styled.span`
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 2%;
`
const DetailSpan = styled(TitleSpan)`
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.2s ease;
  @media screen and (max-width: 800px) {
    opacity: 1;
  }
`
const Label = styled.label`
  position: absolute;
  bottom: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].blogCardLabelBackground};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  transition: min-height 0.2s ease;
  @media screen and (max-width: 800px) {
    padding-bottom: 5%;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
  position: relative;
  flex: 0 0 26%;
  background-image: ${props => `url(${props.image})` || `url(${BACKGROUND_IMAGE})`};
  background-size: cover;
  background-position: top;
  margin-bottom: 4%;
  border-radius: 5px;
  transition: transform 0.4s ease;
  @media screen and (min-width: 801px) {
    &:hover ${Label} {
      min-height: 50%;
      height: auto;
      padding: 20px 10px;
      justify-content: space-evenly;
      border-radius: 0;
      background-color: ${props =>
        props.theme.themeColors[props.theme.selected].blogCardLabelBackgroundActive};
    }
    &:hover ${DetailSpan} {
      opacity: 1;
    }
    &:hover {
      transform: scale(1.04);
    }
  }
  @media screen and (max-width: 800px) {
    flex: 0 0 90%;
    min-height: 45vh;
  }
`

export default BlogCard
