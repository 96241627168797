import { combineReducers } from 'redux'
import authReducer from './authReducer'
import loaderReducer from './loaderReducer'
import profileReducer from './profileReducer'
import profileImagesReducer from './profileImagesReducer'
import modalReducer from './modalReducer'
import calendarReducer from './calendarReducer'
import feedbackMessageReducer from './feedbackMessageReducer'

const rootReducers = combineReducers({
  authReducer,
  loaderReducer,
  profileReducer,
  profileImagesReducer,
  modalReducer,
  calendarReducer,
  feedbackMessageReducer
})

export default rootReducers
