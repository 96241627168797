import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  CheckBoxOutlineBlank,
  CheckBox,
  StarBorder,
  Star,
  Spellcheck,
  SwapVert,
  Event,
  Delete,
  IndeterminateCheckBox,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreHoriz
} from 'styled-icons/material'
import ReactPaginate from 'react-paginate'

import MailItem from './MailItem'
import MailActionLoading from './MailActionLoading'
import { MAIL_CATEGORIES, ACTION_ICON_SIZE } from '../../constants/generics'
import './Pagination.css'

const MailsList = props => {
  return (
    <Container>
      <Header>
        {props.activeCategory !== MAIL_CATEGORIES.ELIMINADOS && (
          <>
            <Span style={{ width: '10%' }}>
              <IconContainer onClick={props.selectAll}>
                {(props.selectAllActive || props.selectedIds.length === props.mails.length) &&
                props.selectedIds.length ? (
                  <CheckBox size={ACTION_ICON_SIZE} />
                ) : props.selectedIds.length ? (
                  <IndeterminateCheckBox size={ACTION_ICON_SIZE} />
                ) : (
                  <CheckBoxOutlineBlank size={ACTION_ICON_SIZE} />
                )}
                <CheckboxSpan>Todos</CheckboxSpan>
              </IconContainer>
            </Span>
            <Span style={{ width: '7%', marginRight: '2%' }}>
              {!props.actionLoading.fav ? (
                <IconContainer onClick={props.favoriteAll}>
                  {props.favAllActive ? (
                    <Star size={ACTION_ICON_SIZE} />
                  ) : (
                    <StarBorder size={ACTION_ICON_SIZE} />
                  )}
                </IconContainer>
              ) : (
                <MailActionLoading />
              )}
            </Span>
          </>
        )}
        <Span style={{ width: '65%', paddingRight: '1%' }}>
          <IconContainer onClick={() => props.setOrder('subject')}>
            <Spellcheck size={ACTION_ICON_SIZE} />
            {props.isOrderedBy === 'subject' && <SwapVert size={'1em'} />}
          </IconContainer>
        </Span>
        <Span
          style={{
            width: props.activeCategory !== MAIL_CATEGORIES.ELIMINADOS ? '20%' : '35%',
            paddingRight: '1%'
          }}
        >
          <IconContainer onClick={() => props.setOrder('date')}>
            <Event size={ACTION_ICON_SIZE} />
            {props.isOrderedBy === 'date' && <SwapVert size={'1em'} />}
          </IconContainer>
        </Span>
        {props.activeCategory !== MAIL_CATEGORIES.ELIMINADOS && (
          <Span style={{ width: ACTION_ICON_SIZE }}>
            {props.selectedIds.length ? (
              !props.actionLoading.del ? (
                <IconContainer onClick={props.deleteHandler}>
                  <Delete size={ACTION_ICON_SIZE} />
                </IconContainer>
              ) : (
                <MailActionLoading />
              )
            ) : null}
          </Span>
        )}
      </Header>
      {props.mails.length ? (
        props.mails.map(hiloData => {
          return (
            <MailItem
              isPapelera={props.activeCategory === MAIL_CATEGORIES.ELIMINADOS}
              key={`${hiloData.id}--${hiloData.ultimoMensaje.cuerpo}`}
              id={hiloData.id}
              idRaiz={hiloData.id}
              isNewMail={!hiloData.ultimoMensaje.leido}
              isFav={hiloData.tieneFavorito}
              isSelected={!!props.selectedIds.find(id => hiloData.id === id)}
              subject={hiloData.ultimoMensaje.asunto}
              content={hiloData.ultimoMensaje.cuerpo}
              date={new Date(hiloData.ultimoMensaje.fechaEnvio)}
              setFavorite={props.setFavorite}
              setSelect={props.setSelect}
              isFavLoading={props.actionLoading.fav}
              openMail={props.openMail}
              cantidadEnHilo={hiloData.cantidadMensajes}
            />
          )
        })
      ) : (
        <span>Por el momento no hay mails en esta bandeja.</span>
      )}
      {props.totalEnBandeja > 0 && (
        <ReactPaginate
          pageCount={props.totalEnBandeja / 5}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          breakLabel={<MoreHoriz size="2em" />}
          nextLabel={<KeyboardArrowRight size={'2em'} />}
          previousLabel={<KeyboardArrowLeft size={'2em'} />}
          containerClassName="pagination-container"
          pageClassName="pagination-item"
          nextClassName="pagination-item"
          previousClassName="pagination-item"
          nextLinkClassName="pagination-anchor"
          previousLinkClassName="pagination-anchor"
          pageLinkClassName="pagination-anchor"
          onPageChange={selectedObject =>
            props.setCategory(props.activeCategory, selectedObject.selected + 1)
          }
        />
      )}
    </Container>
  )
}

const Span = styled.span`
  align-items: center;
  display: flex;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  background-color: ${props => props.theme.colors.lightGray};
  padding: 0 1%;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`

const CheckboxSpan = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`

MailsList.propTypes = {
  mails: PropTypes.array.isRequired,
  selectAllActive: PropTypes.bool,
  favAllActive: PropTypes.bool,
  selectedIds: PropTypes.array.isRequired,
  selectAll: PropTypes.func.isRequired,
  setSelect: PropTypes.func.isRequired,
  favoriteAll: PropTypes.func.isRequired,
  setFavorite: PropTypes.func.isRequired,
  setOrder: PropTypes.func.isRequired,
  isOrderedBy: PropTypes.string.isRequired,
  totalEnBandeja: PropTypes.number.isRequired,
  setCategory: PropTypes.func.isRequired,
  activeCategory: PropTypes.string.isRequired,
  openMail: PropTypes.func
}

export default MailsList
