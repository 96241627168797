import React, { Fragment } from 'react'
import styled from 'styled-components'

import BlogCard from '../Card/BlogCard'
import BreadcrumbsFromUrl from '../Breadcrumbs/BreadcrumbsFromUrl'
import Footer from '../Layout/Footer'
import { BLOG_DATA } from '../../constants/blog-data'

const Blog = props => {
  return (
    <Fragment>
      <Main>
        <BreadcrumbsFromUrl />
        <Container quantity={BLOG_DATA.length}>
          {BLOG_DATA.map((dataArray, index) => (
            <React.Fragment key={index}>
              {dataArray.map(item => (
                <BlogCard
                  image={item.image}
                  key={`${item.path}-${item.title}`}
                  title={item.title}
                  detail={item.detail}
                  link={item.path}
                />
              ))}
            </React.Fragment>
          ))}
        </Container>
      </Main>
      <Footer />
    </Fragment>
  )
}

const Container = styled.div`
  width: 90%;
  display: flex;
  height: ${props => props.quantity * 55.4}vh;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media screen and (max-width: 800px) {
    height: auto;
  }
`
const Main = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  min-height: 62vh;
  padding-top: 2%;
`

export default Blog
