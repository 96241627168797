import { constants } from '../../constants/reduxActions'
const { SET_AVATAR, SET_PORTADA } = constants

const initialState = {
  portada: {
    url: null
  },
  avatar: {
    url: null
  }
}

const profileImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PORTADA:
      return {
        ...state,
        portada: {
          url: action.payload
        }
      }
    case SET_AVATAR:
      return {
        ...state,
        avatar: {
          url: action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default profileImagesReducer
