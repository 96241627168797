import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { H3, H5, PrimaryButton } from '../../../styled/generics'
import { StyledForm as Form } from '../../../styled/forms'
import { showGallery } from '../../../redux/actions/modalActions'
import { addNota, removeNota, editNota } from '../../../redux/actions/notaActions'
import NotaItem from './NotaItem'
import NuevaNota from './NuevaNota'
import Modal from '../../Modal/Backdrop'

class NotasForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addEditMode: false,
      editingId: null,
      collapseOpened: null
    }
  }

  addHandler = (dataObject, filesObject) => {
    const { titulo, texto } = dataObject
    const nota = {
      diaEstimado: this.props.notaDay,
      titulo,
      texto
    }

    if (this.state.editingId) {
      nota.id = this.state.editingId
      return this.props.editNota(nota, filesObject, () => this.closeAddEditHandler())
    }
    this.props.addNota(nota, filesObject, () => this.closeAddEditHandler())
  }

  removeHandler = notaObject => {
    this.props.removeNota(notaObject)
  }

  submitHandler = event => {
    event.preventDefault()
  }

  editModeHandler = idToEdit => {
    this.setState({
      addEditMode: true,
      editingId: idToEdit
    })
  }

  addModeHandler = () => {
    this.setState({
      addEditMode: true,
      editingId: null
    })
  }

  closeAddEditHandler = () => {
    this.setState({
      addEditMode: false,
      editingId: null
    })
  }

  collapseHandler = id => this.setState({ collapseOpened: id })

  render() {
    const editingNota =
      this.state.editingId !== null
        ? this.props.totalDeNotas[this.props.notaDay].find(nota => nota.id === this.state.editingId)
        : null
    return (
      <Fragment>
        {this.state.addEditMode && (
          <Modal isNested isCloseable customCloseHandler={this.closeAddEditHandler}>
            <NuevaNota
              nota={editingNota}
              titulo={editingNota ? editingNota.titulo : ''}
              texto={editingNota ? editingNota.texto : ''}
              isEdit={!!this.state.editingId}
              addHandler={this.addHandler}
              closeHandler={this.closeAddEditHandler}
              editingId={!this.state.editingId ? false : this.state.editingId}
            />
          </Modal>
        )}
        <Form onSubmit={this.submitHandler}>
          <H3>Mis notas del día</H3>
          {this.props.notasDelDia.length ? (
            <Ul minHeight={this.props.notasDelDia.length}>
              {this.props.notasDelDia.map((nota, index) => (
                <NotaItem
                  key={`${nota.id}-${nota.titulo}-${nota.texto}`}
                  nota={nota}
                  isCollapsed={nota.id !== this.state.collapseOpened}
                  collapseHandler={this.collapseHandler}
                  editHandler={this.editModeHandler}
                  removeHandler={this.removeHandler}
                  showGallery={() => this.props.showGallery(nota.photos)}
                />
              ))}
            </Ul>
          ) : (
            <H5>No hay notas actualmente</H5>
          )}
          <ButtonContainer>
            <AgregarNotaButton type="button" onClick={this.addModeHandler}>
              Agregar nota
            </AgregarNotaButton>
          </ButtonContainer>
        </Form>
      </Fragment>
    )
  }
}

const Ul = styled.ul`
  margin: 0;
  min-height: ${props => (props.minHeight + 1) * 5}vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
`
const AgregarNotaButton = styled(PrimaryButton)`
  width: 30%;
  @media screen and (max-width: 800px) {
    width: 60%;
  }
`

const ButtonContainer = styled.div`
  z-index: 99;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  border-top: 1px solid ${props => props.theme.themeColors[props.theme.selected].mainColor};
`
const mapStateToProps = state => {
  const { notas, notaDay } = state.modalReducer
  const totalDeNotas = state.calendarReducer.data.notas
  const { usuarioId } = state.authReducer.auth
  return {
    notasDelDia: notas,
    totalDeNotas,
    notaDay,
    usuarioId
  }
}
const mapDispatchToProps = {
  showGallery,
  addNota,
  removeNota,
  editNota
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotasForm)
