import { getPregnancyBeginDate } from '../../utils/date'
import { getRegistrosClinicos, getEtapas, getTurnoEspecialidad } from '../../api'

import { ID_RESERVA_CAMA } from '../../constants/generics'
import { constants } from '../../constants/reduxActions'

const { SET_WEEK, SET_DAY, SET_CALENDAR_DATA, SET_HAS_BED_APPOINTMENT, SET_ACTIVE_DAY } = constants

const WEEKS_UNTIL_BIRTH = 39 //Begins from 0

export const weekCalc = weekNumber => (dispatch, getState) => {
  const pregnancyBeginsOn = getPregnancyBeginDate(getState().profileReducer.dateToBirth)
  let finalDay
  let initialDay = new Date(getState().profileReducer.dateToBirth)
  initialDay.setDate(initialDay.getDate() + (weekNumber - (WEEKS_UNTIL_BIRTH + 1)) * 7)
  finalDay = new Date(initialDay)
  finalDay.setDate(initialDay.getDate() + 6)
  const weekPayload = {
    number: weekNumber,
    month: finalDay.getMonth(),
    datefromTo: [initialDay, finalDay],
    pregnancyBeginsOn
  }
  dispatch(setWeek(weekPayload))
}

export const checkBedAppointment = () => (dispatch, getState) => {
  const { usuarioId } = getState().authReducer.auth
  getTurnoEspecialidad({ usuarioId, especialidadId: ID_RESERVA_CAMA })
    .then(res => {
      if (!res.data) {
        dispatch(setHasBedAppointment(false))
      }
    })
    .catch(console.log)
}

export const setHasBedAppointment = boolean => ({
  type: SET_HAS_BED_APPOINTMENT,
  payload: boolean
})

export const setActiveDay = dayDate => ({
  type: SET_ACTIVE_DAY,
  payload: dayDate
})

const setWeek = payload => {
  return {
    type: SET_WEEK,
    payload
  }
}

export const setDay = payload => {
  return {
    type: SET_DAY,
    payload
  }
}

const setCalendarData = payload => {
  return {
    type: SET_CALENDAR_DATA,
    payload
  }
}

export const getCalendarData = payload => dispatch => {
  //TODO getData from api and set dispatch setCalendarData
}

export const getRegistros = () => dispatch => {
  getRegistrosClinicos()
    .then(res => {
      // console.log('res -> ', res)
      if ((res.statusText = 'OK')) {
        return dispatch(
          setCalendarData({
            registros: res.data
          })
        )
      }
    })
    .catch(console.log)
}

export const getMiEmbarazo = () => dispatch => {
  getEtapas()
    .then(res => {
      // console.log('res -> ', res)
      if ((res.statusText = 'OK')) {
        return dispatch(
          setCalendarData({
            embarazo: res.data
          })
        )
      }
    })
    .catch(console.log)
}
