import AVATAR_PLACEHOLDER from '../../img/profile/avatar_placeholder.jpg'
import PORTADA_PLACEHOLDER from '../../img/profile/header_placeholder.png'

import { getInitialWeekNumber } from '../../utils/date'
import { b64toBlob } from '../../utils/getBase64'
import { weekCalc } from './calendarActions'
import { hideModal } from './modalActions'
import { removeisNewUser } from './authActions'
import { setProfile, setPhoto, getPhoto } from '../../api'
import { FILE_TYPES } from '../../constants/generics'
import { constants } from '../../constants/reduxActions'
const { SET_PROFILE, SET_AVATAR, SET_PORTADA } = constants

export const pureSetProfile = payload => ({
  type: SET_PROFILE,
  payload: {
    ...payload,
    embarazoDoesExist: true
  }
})

export const setProfileData = (payload, photoObjectArr) => (dispatch, getState) => {
  const usuarioId = payload.usuarioId ? payload.usuarioId : getState().authReducer.auth.usuarioId
  const profileData = {
    ...payload,
    usuarioId,
    color: payload.color.hex
  }
  setProfile(profileData)
    .then(res => {
      if (photoObjectArr) {
        const photoPromises = photoObjectArr.map(photoObject => {
          const { fileType, file } = photoObject
          const photoData = {
            file,
            fileType,
            usuarioId
          }
          return setPhoto(photoData)
        })
        return Promise.all(photoPromises)
      }
      return res
    })
    .then(res => {
      const initialWeekNumber = getInitialWeekNumber(payload.fechaEstimadaParto)
      if (photoObjectArr) {
        photoObjectArr.forEach(photoObject =>
          dispatch({
            type: photoObject.fileType === FILE_TYPES.portada ? SET_PORTADA : SET_AVATAR,
            payload: URL.createObjectURL(photoObject.file)
          })
        )
      }
      payload.embarazoDoesExist = true
      dispatch({
        type: SET_PROFILE,
        payload
      })
      dispatch(removeisNewUser())
      dispatch(hideModal())
      return dispatch(weekCalc(initialWeekNumber))
    })
    .catch(console.log)
}

export const setPortada = (payload, resetPlaceholderCallback) => (dispatch, getState) => {
  setPhoto({
    file: payload,
    fileType: FILE_TYPES.portada,
    usuarioId: getState().authReducer.auth.usuarioId
  })
    .then(res => {
      if ((res.statusText = 'OK')) {
        dispatch({
          type: SET_PORTADA,
          payload: URL.createObjectURL(payload)
        })
      }
    })
    .catch(err => {
      resetPlaceholderCallback()
    })
}

export const setAvatar = (payload, resetPlaceholderCallback) => (dispatch, getState) => {
  setPhoto({
    file: payload,
    fileType: FILE_TYPES.avatar,
    usuarioId: getState().authReducer.auth.usuarioId
  })
    .then(res => {
      if ((res.statusText = 'OK')) {
        return dispatch({
          type: SET_AVATAR,
          payload: URL.createObjectURL(payload)
        })
      }
    })
    .catch(err => {
      resetPlaceholderCallback()
    })
}

export const getAvatar = (usuarioId, resetPlaceholderCallback) => (dispatch, getState) => {
  getPhoto(usuarioId, FILE_TYPES.avatar)
    .then(res => {
      if (res.data) {
        return b64toBlob(res.data.content).then(blob => {
          dispatch({
            type: SET_AVATAR,
            payload: URL.createObjectURL(blob)
          })
        })
      }
    })
    .catch(err => {
      dispatch({
        type: SET_AVATAR,
        payload: AVATAR_PLACEHOLDER
      })
      return resetPlaceholderCallback ? resetPlaceholderCallback() : null
    })
}

export const getPortada = (usuarioId, resetPlaceholderCallback) => (dispatch, getState) => {
  getPhoto(usuarioId, FILE_TYPES.portada)
    .then(res => {
      if ((res.statusText = 'OK')) {
        return b64toBlob(res.data.content).then(blob => {
          dispatch({
            type: SET_PORTADA,
            payload: URL.createObjectURL(blob)
          })
        })
      }
    })
    .catch(err => {
      dispatch({
        type: SET_PORTADA,
        payload: PORTADA_PLACEHOLDER
      })
      return resetPlaceholderCallback ? resetPlaceholderCallback() : null
    })
}
