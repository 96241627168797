import * as yup from 'yup'

const notaSchema = yup.object().shape({
  titulo: yup
    .string()
    .required('Debe completar el título.')
    .max(128, 'El campo debe contener como máximo 128 caracteres.')
    .min(
      3,
      'El campo debe contener al menos 3 caracteres caracteres alfanúmericos, espacios y los siguientes caracteres especiales ["?" "¿" "!" "¡" "," "."]'
    )

    .matches(
      /^[a-z0-9\s?¿!¡,.áéíóúñÑ]*$/i,
      'Sólo se permiten caracteres alfanúmericos, espacios y los siguientes caracteres especiales ["?" "¿" "!" "¡" "," "."]'
    ),
  texto: yup
    .string()
    .required('Completa el campo de texto de tu Nota.')
    .min(3, 'El campo debe contener al menos 3 caracteres.')
    .max(500, 'El campo debe contener como máximo 500 caracteres.')
    .matches(
      /^[a-z0-9\s?¿!¡,.áéíóúñÑ]*$/i,
      'Sólo se permiten caracteres alfanúmericos, espacios y los siguientes caracteres especiales ["?" "¿" "!" "¡" "," "."]'
    ),
  files: yup
    .mixed()
    .test('archivo-type', 'No puedes subir images al contenedor de archivos!', function test(
      value
    ) {
      if (!value) {
        return true
      }
      return value && value.type.match(/(jpg|jpeg|png)/) ? false : true
    })
    .test('archivo-existe', 'Ya subiste este archivo!', function test(value) {
      if (!value) {
        return true
      }
      const archivosSubidos = this.options.context
      return archivosSubidos.find(
        item => item.name === value.name && item.size === value.size && item.type === value.type
      )
        ? false
        : true
    })
    .test('avatar-size', 'No puedes subir archivos de mas de 5MB.', function test(value) {
      return value && value.size > 5242880 ? false : true
    }),
  photos: yup
    .mixed()
    .test('foto-type', 'La imagen de la nota debe ser de tipo jpg, jpeg, o png.', function test(
      value
    ) {
      if (!value) {
        return true
      }
      return value && !value.type.match(/(jpg|jpeg|png)/) ? false : true
    })
    .test('avatar-size', 'No puedes subir archivos de mas de 5MB.', function test(value) {
      return value && value.size > 5242880 ? false : true
    })
})

export default notaSchema
