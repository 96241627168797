import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import validationSchema from '../../validation/consultaSchema'
import { hideModal } from '../../redux/actions/modalActions'
import {
  StyledForm,
  SubmitButton,
  Select,
  TextArea,
  ErrFeedbackContainer
} from '../../styled/forms'
import { H3, Alerta } from '../../styled/generics'
import { flashSuccesMessage } from '../../redux/actions/feedbackMessageActions'
import { enviarMensajeAPI } from '../../api'

class ConsultaForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      consulta: '',
      especialidad: '',
      errors: {}
    }
  }

  onChangeHandler = event => {
    const { name, value } = event.target
    if (name === 'consulta' && value.length > 1024) {
      return this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          consulta: `Cantidad de caractéres ${value.length}. Cantidad máxima 1024!`
        },
        consulta: value
      }))
    }
    const newErrors = { ...this.state.errors }
    delete newErrors[name]
    this.setState({
      [name]: value,
      errors: newErrors
    })
  }

  submitHandler = event => {
    event.preventDefault()
    const { especialidades, usuarioId } = this.props
    validationSchema
      .validate(
        {
          ...this.state,
          consulta: this.state.consulta.trim(),
          usuarioId
        },
        {
          context: {
            especialidades,
            usuarioId,
            isEspecialidad: this.props.isEspecialidad
          }
        }
      )
      .then(result => {
        this.props.hideModal()
        const payload = {
          idUsuarioFrom: this.props.usuarioId,
          cuerpo: this.state.consulta
        }
        let especialidadObject = null
        if (this.props.isEspecialidad) {
          especialidadObject = this.props.especialidades.find(especialidad => {
            return especialidad.id === parseInt(this.state.especialidad)
          })
        }
        payload.asunto = especialidadObject
          ? `Consulta para especialidad ${especialidadObject.nombre ||
              especialidadObject.descripcion}`
          : 'Consulta con Pregnancy Planner'
        enviarMensajeAPI(payload)
          .then(res => {
            this.props.flashSuccesMessage({
              title: 'Consulta',
              message: '¡Tu consulta fue envíada con éxito!'
            })
          })
          .catch(console.log)
      })
      .catch(errors => {
        this.setState({
          errors: {
            [errors.path]: errors.message
          }
        })
      })
  }

  render() {
    return (
      <Fragment>
        <StyledH3>{this.props.title}</StyledH3>
        <Alerta>
          <H4>Importante</H4>
          <P>La respuesta a tu consulta puede tardar hasta 24 horas. </P>
          <P>
            En caso de tener pérdidas o alguna otra urgencia, por favor acercate directamente a la
            guardia.
          </P>
        </Alerta>
        <StyledForm onSubmit={this.submitHandler}>
          {this.props.isEspecialidad && (
            <Select
              name="especialidad"
              value={this.state.especialidad}
              onChange={this.onChangeHandler}
            >
              <option value="" defaultValue disabled>
                Elegí la especialidad de tu consulta
              </option>
              {this.props.especialidades.map(especialidad => {
                return (
                  <Option
                    key={especialidad.descripcion || especialidad.nombre}
                    value={especialidad.id}
                  >
                    {especialidad.descripcion || especialidad.nombre}
                  </Option>
                )
              })}
            </Select>
          )}
          {this.state.errors.especialidad && (
            <ErrContainer>* {this.state.errors.especialidad}</ErrContainer>
          )}
          <TextArea
            placeholder="Consulta..."
            name="consulta"
            value={this.state.consulta}
            onChange={this.onChangeHandler}
          />
          {this.state.errors.consulta && (
            <ErrContainer>* {this.state.errors.consulta}</ErrContainer>
          )}
          <SolicitarButton
            disabled={
              Object.entries(this.state.errors).length !== 0 &&
              this.state.errors.constructor === Object
            }
          >
            Enviar consulta
          </SolicitarButton>
        </StyledForm>
      </Fragment>
    )
  }
}

const Option = styled.option`
  color: ${props => props.theme.colors.black};
`
const P = styled.p`
  margin: 0;
`
const H4 = styled.h4`
  margin: 0;
`
const StyledH3 = styled(H3)`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`

const SolicitarButton = styled(SubmitButton)`
  margin-top: 5%;
`
const ErrContainer = styled(ErrFeedbackContainer)`
  margin-bottom: 2%;
  margin-top: -3%;
`

const mapStateToProps = state => {
  return {
    usuarioId: state.authReducer.auth.usuarioId,
    especialidades: state.modalReducer.turnoEspecialidad
  }
}

const mapDispatchToProps = {
  hideModal,
  flashSuccesMessage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultaForm)
