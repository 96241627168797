import * as yup from 'yup'

const profileSchema = yup.object().shape({
  especialidad: yup
    .string()
    .test('especialidad-test', 'Elija una especialidad válida de la lista.', function test(value) {
      const { especialidades, isEspecialidad } = this.options.context
      return !isEspecialidad
        ? true
        : especialidades.find(especialidad => especialidad.id === parseInt(value))
    }),
  usuarioId: yup
    .string()
    .required()
    .test('usuario-test', 'El usuario debe ser el logeado', function test(value) {
      return this.options.context.usuarioId.localeCompare(value, { usage: 'search' }) === 0
    }),
  consulta: yup
    .string()
    .required('Debe completar el campo consulta')
    .matches(
      /^[a-z0-9\s?¿!¡,.áéíóúñÑ]*$/i,
      'Sólo se permiten caracteres alfanúmericos, espacios y los siguientes caracteres especiales ["?" "¿" "!" "¡" "," "."]'
    )
    .min(10, 'Su consulta debe contener al menos 10 caracteres alfanuméricos')
    .max(1024, 'Su consulta debe contener como máximo 1024 caracteres alfanuméricos')
})

export default profileSchema
