import { constants } from '../../constants/reduxActions'
const { SET_ERROR, RESET_FEEDBACK, SUCCESS_MESSAGE } = constants

export const flashSuccesMessage = payload => {
  return {
    type: SUCCESS_MESSAGE,
    payload
  }
}

/**
 * Set error message
 */
export const setError = payload => {
  return {
    type: SET_ERROR,
    payload
  }
}

/**
 * Reset error
 */
export const resetFeedbackMessage = () => {
  return {
    type: RESET_FEEDBACK
  }
}
