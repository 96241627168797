import {
    constants
} from '../../constants/reduxActions';
const {
    SHOW_LOADER,
    HIDE_LOADER
} = constants;

const initialState = {
    isLoading: false
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADER:
            return {
                isLoading: true
            };
        case HIDE_LOADER:
            return {
                isLoading: false
            };
        default:
            return {
                ...state
            };
    }
}

export default loaderReducer;