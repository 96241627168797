import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { reservaDeTurno } from '../../redux/actions/modalActions'
import validationSchema from '../../validation/turnoSchema'
import { H2, H3, H4, H5 } from '../../styled/generics'
import {
  StyledForm,
  Select,
  TextArea,
  SubmitButton,
  ErrFeedbackContainer
} from '../../styled/forms'
import RadioInput from './RadioInput'
import { CLINICS_LIST } from '../../constants/generics'

class TurnoForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      especialidad:
        this.props.isEstudioForm || this.props.isReservaCama ? this.props.especialidades[0].id : '',
      clinica: '',
      franjaHoraria: 'Noche',
      comentario: '',
      errors: {}
    }
  }

  onChangeHandler = event => {
    const { name, value } = event.target
    if (name === 'comentario' && value.length > 128) {
      return this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          comentario: `Cantidad de caractéres ${value.length}. Cantidad máxima 128!`
        },
        comentario: value
      }))
    }
    const newErrors = { ...this.state.errors }
    delete newErrors[name]
    this.setState({
      [name]: value,
      errors: newErrors
    })
  }

  onSubmitHandler = event => {
    event.preventDefault()
    validationSchema
      .validate(this.state, {
        context: {
          especialidades: this.props.especialidades
        }
      })
      .then(result => {
        const objectToPost = {
          usuarioId: this.props.usuarioId,
          clinica: parseInt(this.state.clinica),
          horario: this.state.franjaHoraria,
          comentario: this.state.comentario
        }
        if (this.props.isEstudioForm) {
          objectToPost.estudio = parseInt(this.state.especialidad)
        } else {
          objectToPost.especialidad = parseInt(this.state.especialidad)
        }
        this.props.reservaDeTurno(objectToPost)
      })
      .catch(errors => {
        this.setState({
          errors: {
            [errors.path]: errors.message
          }
        })
      })
  }

  render() {
    return (
      <StyledForm onSubmit={this.onSubmitHandler}>
        <H2>{this.props.title}</H2>
        {this.props.isEstudioForm && (
          <EstudioTitleContainer>
            <EstudioName>{this.props.especialidades[0].nombre}</EstudioName>
            {this.props.especialidades[0].descripcion && (
              <EstudioDescription>{this.props.especialidades[0].descripcion}</EstudioDescription>
            )}
          </EstudioTitleContainer>
        )}
        {!this.props.isEstudioForm && !this.props.isReservaCama && (
          <React.Fragment>
            <Select
              name="especialidad"
              value={this.state.especialidad}
              onChange={this.onChangeHandler}
            >
              <option value="" defaultValue disabled>
                Seleccione una especialidad o estudio
              </option>
              {this.props.especialidades.map(especialidad => (
                <Option
                  key={especialidad.nombre || especialidad.descripcion}
                  value={especialidad.id}
                >
                  {especialidad.nombre || especialidad.descripcion}
                </Option>
              ))}
            </Select>
            {this.state.errors.especialidad && (
              <ErrContainer>{'* ' + this.state.errors.especialidad}</ErrContainer>
            )}
          </React.Fragment>
        )}
        <Select name="clinica" value={this.state.clinica} onChange={this.onChangeHandler}>
          <option value="" defaultValue disabled>
            Seleccione una clínica
          </option>
          {Object.entries(CLINICS_LIST).map(([id, { name }]) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
        {this.state.errors.clinica && (
          <ErrContainer>{'* ' + this.state.errors.clinica}</ErrContainer>
        )}
        <H4>Franja horaria</H4>
        <ContainerHorario>
          <RadioInput
            name="franjaHoraria"
            onChange={this.onChangeHandler}
            labelName="Mañana"
            value="Mañana"
          />
          <RadioInput
            name="franjaHoraria"
            onChange={this.onChangeHandler}
            labelName="Tarde"
            value="Tarde"
          />
          <RadioInput
            name="franjaHoraria"
            onChange={this.onChangeHandler}
            isChecked
            labelName="Noche"
            value="Noche"
          />
          {this.state.errors.franjaHoraria && (
            <ErrContainer>{'* ' + this.state.errors.franjaHoraria}</ErrContainer>
          )}
        </ContainerHorario>
        <TextArea
          name="comentario"
          value={this.state.comentario}
          onChange={this.onChangeHandler}
          placeholder="NOTA"
        />
        {this.state.errors.comentario && (
          <ErrContainer>{'* ' + this.state.errors.comentario}</ErrContainer>
        )}
        {this.props.isReservaCama && (
          <ReservaCamaCaption>
            <small>
              {'* Esta reserva queda sujeta a la disponibilidad de camas en la Clínica elegida.'}
            </small>
          </ReservaCamaCaption>
        )}
        <SolicitarButton
          disabled={
            Object.entries(this.state.errors).length !== 0 &&
            this.state.errors.constructor === Object
          }
        >
          Solicitar
        </SolicitarButton>
      </StyledForm>
    )
  }
}

const EstudioTitleContainer = styled.div`
  width: 80%;
`

const EstudioDescription = styled(H5)`
  text-align: center;
`
const EstudioName = styled(H3)`
  text-align: center;
`

const Option = styled.option`
  color: ${props => props.theme.colors.black};
`
const ContainerHorario = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 5%;
`
const SolicitarButton = styled(SubmitButton)`
  margin-top: 5%;
`
const ErrContainer = styled(ErrFeedbackContainer)`
  margin-top: -3%;
  margin-bottom: 2%;
`
const ReservaCamaCaption = styled.div`
  margin-bottom: 2%;
  width: 80%;
  & small {
    font-weight: bold;
    color: ${props => props.theme.colors.strongGray};
  }
`

TurnoForm.propTypes = {
  title: PropTypes.string.isRequired,
  isReservaCama: PropTypes.bool
}

const mapDispatchToProps = {
  reservaDeTurno
}

const mapStateToProps = state => {
  return {
    usuarioId: state.authReducer.auth.usuarioId,
    especialidades: state.modalReducer.turnoEspecialidad
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TurnoForm)
