import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
  MailOutline,
  Notifications,
  // HelpOutline,
  KeyboardArrowDown,
  AddCircleOutline
} from 'styled-icons/material'

import {
  getCantidadNoLeidosAPI,
  getUserData,
  getTurno,
  getAppointmentNotification
} from '../../api'
import { dateParse, getRealDateFromEstimatedDay } from '../../utils/date'
import { setModalType, setEspecialidades, showModal } from '../../redux/actions/modalActions'
import { setError } from '../../redux/actions/feedbackMessageActions'
import { cleanLogout } from '../../redux/actions/authActions'
import Logo from '../Icons/Logo'
import IconMobileMenu from '../Icons/IconMobileMenu'
import {
  MAIL_NOTIFICATION_INTERVAL,
  APPOINMENT_DAYS_PROXIMITY,
  MODAL_TYPES,
  CLINICS_LIST,
  ACTION_ICON_SIZE
} from '../../constants/generics'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDropdownOpen: false,
      isMobileMenuOpen: false,
      isNotifOpen: false,
      turnoNotifications: [],
      notificationsCounter: 0,
      newMailsCounter: 0,
      intervalId: null,
      nombreUser: 'nombre',
      apellidoUser: 'apellido',
      email: null
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dateToBirth.getTime() !== this.props.dateToBirth.getTime() ||
      prevProps.embarazoDoesExist !== this.props.embarazoDoesExist
    ) {
      getAppointmentNotification(this.props.usuarioId)
        .then(res =>
          this.setState({ turnoNotifications: res.data, notificationsCounter: res.data.length })
        )
        .catch(console.log)
    }
  }

  componentDidMount() {
    //API call to get appointment notifications
    if (this.props.embarazoDoesExist) {
      getAppointmentNotification(this.props.usuarioId)
        .then(res =>
          this.setState({ turnoNotifications: res.data, notificationsCounter: res.data.length })
        )
        .catch(console.log)
    }
    getUserData(this.props.usuarioId)
      .then(res =>
        this.setState({
          nombre: res.data.nombre,
          apellido: res.data.apellido,
          email: res.data.email
        })
      )
      .catch(console.log)
    //API call to get notification of new mail received, then set newMailsCounter
    getCantidadNoLeidosAPI(this.props.usuarioId)
      .then(res => {
        this.setState({ newMailsCounter: res.data })
        const intervalId = setInterval(
          () =>
            getCantidadNoLeidosAPI(this.props.usuarioId).then(res =>
              this.setState({
                newMailsCounter: res.data
              })
            ),
          MAIL_NOTIFICATION_INTERVAL
        )
        this.setState({ intervalId })
      })
      .catch(console.log)
  }

  notifItemHandler = estudioObject => {
    const { usuarioId } = this.props
    if (!estudioObject.requiereTurno) {
      const err = new Error()
      err.message = `Este estudio no precisa que solicites turno, acercate directamente a la clínica que desees para realizarlo.`
      err.title = `Solicitud de turno`
      return this.props.setError(err)
    }
    getTurno({
      usuarioId: usuarioId,
      estudio: estudioObject.id
    })
      .then(res => {
        if (res.statusText === 'OK' && !res.data) {
          this.props.setModalType(MODAL_TYPES.TURNO_ESTUDIO)
          this.props.setEspecialidades([estudioObject])
          return this.props.showModal()
        }
        if (res.data) {
          // TODO use detalleForm to show turno data to the user.
          const err = new Error('Error al intentar pedir turno.')
          const { clinica, horario, fechaCreacion } = res.data
          err.message = `Pediste atenderte en la ${
            CLINICS_LIST[clinica].name
          } y elegiste la franja horaria de la ${horario}.\n(Turno solicitado el ${dateParse(
            fechaCreacion
          )})`
          err.title = `Ya dispones de un turno`
          this.props.setError(err)
          throw err
        }
      })
      .catch(console.log)
  }

  backdropClickHandler = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        isNotifOpen: false,
        isDropdownOpen: false
      }
    })
  }

  notificationsDropdownHandler = () =>
    this.setState(prevState => ({ isNotifOpen: !prevState.isNotifOpen, notificationsCounter: 0 }))

  userDropdownHandler = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }))
  }

  perfilClickHandler = () => {
    this.setState({
      isDropdownOpen: false,
      isMobileMenuOpen: false
    })
    this.props.setModalType(MODAL_TYPES.PROFILE)
    this.props.showModal()
  }

  mobileMenuHandler = event => {
    this.state.isMobileMenuOpen ? clearAllBodyScrollLocks() : disableBodyScroll()
    this.setState(prevState => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen
    }))
  }

  renderNotifText = estudioObject => (
    <>
      {`Recordá que el `}
      <strong>
        {dateParse(
          getRealDateFromEstimatedDay(estudioObject.diaEstimado, this.props.pregnancyBeginsOn)
        )}
      </strong>
      {` deberías realizarte `}
      <strong>{estudioObject.nombre}</strong>
    </>
  )
  render() {
    return (
      this.props.isAuth && (
        <>
          {(this.state.isDropdownOpen || this.state.isNotifOpen) && (
            <Backdrop onClick={this.backdropClickHandler} />
          )}
          <StyledNav>
            <StyledLogoContainer>
              <Link to="/">
                <Logo />
              </Link>
            </StyledLogoContainer>
            <StyledMenuIcon
              isOpen={this.state.isMobileMenuOpen}
              onClick={this.mobileMenuHandler}
              size="40px"
            />
            <StyledNavItems isOpen={this.state.isMobileMenuOpen}>
              <NavItemsLeftList>
                <StyledLink
                  to="/mis-correos"
                  onClick={() => this.setState({ newMailsCounter: 0, isMobileMenuOpen: false })}
                >
                  <MailLeftListIcon />
                  <NotificationNumber>
                    {!!this.state.newMailsCounter && this.state.newMailsCounter}
                  </NotificationNumber>
                </StyledLink>
                {this.props.isAuth !== 'admin' && (
                  <IconListItem>
                    <NotificationsLeftListIcon onClick={this.notificationsDropdownHandler} />
                    <NotificationNumber onClick={this.notificationsDropdownHandler}>
                      {!!this.state.notificationsCounter && this.state.notificationsCounter}
                    </NotificationNumber>
                    {window.innerWidth > 800 ? (
                      <NotificationsDropdown className={this.state.isNotifOpen ? 'active' : ''}>
                        {!this.state.turnoNotifications.length && (
                          <DropdownMenuItem isLast>
                            {`No hay estudios pendientes dentro de los próximos ${APPOINMENT_DAYS_PROXIMITY} días..`}
                          </DropdownMenuItem>
                        )}
                        {this.state.turnoNotifications.map((estudioObject, index) => (
                          <DropdownMenuItem
                            isLast={index + 1 === this.state.turnoNotifications.length}
                            key={estudioObject.id}
                            onClick={() => this.notifItemHandler(estudioObject)}
                          >
                            {this.renderNotifText(estudioObject)}
                          </DropdownMenuItem>
                        ))}
                      </NotificationsDropdown>
                    ) : null}
                  </IconListItem>
                )}
              </NavItemsLeftList>
              {window.innerWidth <= 800 && (
                <MobileNotificationsList className={this.state.isNotifOpen ? 'active' : ''}>
                  {!this.state.turnoNotifications.length && (
                    <DropdownMenuItem isLast>
                      {`No hay estudios pendientes dentro de los próximos ${APPOINMENT_DAYS_PROXIMITY} días..`}
                    </DropdownMenuItem>
                  )}
                  {this.state.turnoNotifications.map((estudioObject, index) => (
                    <MobileNotifItem
                      isLast={index + 1 === this.state.turnoNotifications.length}
                      key={estudioObject.id}
                      onClick={() => {
                        this.setState({ isNotifOpen: false, isMobileMenuOpen: false })
                        this.notifItemHandler(estudioObject)
                      }}
                    >
                      <NotifText>{this.renderNotifText(estudioObject)}</NotifText>
                      <TurnoButtonContainer>
                        <AddCircleOutline size={ACTION_ICON_SIZE} />
                        <span>Turno</span>
                      </TurnoButtonContainer>
                    </MobileNotifItem>
                  ))}
                </MobileNotificationsList>
              )}
              <NavItemsRightList>
                <ProfileDropdownContainer>
                  <ProfileDropdownSpan onClick={this.userDropdownHandler}>
                    {this.state.nombre && this.state.apellido && (
                      <>
                        {`${this.state.nombre} ${this.state.apellido}`}
                        <ProfileDropdownIcon isActive={this.state.isDropdownOpen} />
                      </>
                    )}
                  </ProfileDropdownSpan>
                  <DropdownMenu
                    isAdminUser={this.props.isAuth === 'admin'}
                    className={this.state.isDropdownOpen ? 'active' : ''}
                  >
                    {this.props.isAuth !== 'admin' && (
                      <React.Fragment>
                        <DropdownMenuItem isLast onClick={this.perfilClickHandler}>
                          Mi Perfíl
                        </DropdownMenuItem>
                        <StyledHr />
                      </React.Fragment>
                    )}
                    <DropdownMenuItem isLast onClick={this.props.cleanLogout}>
                      Cerrar Sesión
                    </DropdownMenuItem>
                  </DropdownMenu>
                </ProfileDropdownContainer>
                {/* <IconListItem>
              <HelpIcon />
            </IconListItem> */}
              </NavItemsRightList>
            </StyledNavItems>
          </StyledNav>
        </>
      )
    )
  }
}

const Backdrop = styled.div`
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
`

const StyledNav = styled.nav`
  background-color: ${props => props.theme.themeColors[props.theme.selected].secondaryColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18vh;
  padding: 15px 15px 24px 15px;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
  border-bottom: 5px solid ${props => props.theme.colors.white};
`
const StyledLogoContainer = styled.div`
  margin-left: 15%;
  padding-top: 5px;
  max-height: 101px;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};

  @media screen and (max-width: 800px) {
    margin-left: 0;
    & svg {
      width: 70%;
    }
  }
`
const StyledMenuIcon = styled(IconMobileMenu)`
  &:hover {
    cursor: pointer;
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
`
const StyledNavItems = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-evenly;
  align-self: flex-end;
  align-items: center;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    top: 18vh;
    left: 0;
    align-items: flex-start;
    height: 82vh;
    width: 100%;
    background-color: ${props => props.theme.themeColors[props.theme.selected].secondaryColor};
    justify-content: space-between;
    padding: 2%;
    transform: translateY(-100vh);
    z-index: 99;
    ${props =>
      props.isOpen &&
      css`
        transition: transform 0.3s ease;
        transform: translateY(0);
      `}
  }
`
const iconsCommonCSS = css`
  height: 2em;
  width: 2em;
  padding: 3px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
  @media screen and (max-width: 800px) {
    height: 3em;
    width: 3em;
  }
`
const MailLeftListIcon = styled(MailOutline)`
  ${iconsCommonCSS}
`
const NotificationsLeftListIcon = styled(Notifications)`
  ${iconsCommonCSS}
`
// const HelpIcon = styled(HelpOutline)`
//   ${iconsCommonCSS}
// `
const IconListItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
`
const NotificationNumber = styled.span`
  align-self: flex-end;
  @media screen and (max-width: 800px) {
    align-self: inherit;
    font-size: 1.5em;
  }
`
const MobileNotificationsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 63%;
  width: 100%;
  margin: 0;
  overflow-y: auto;
  transform: translateY(-100vh);
  transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  &.active {
    transform: translateY(0);
  }
  @media screen and (min-width: 800px) {
    display: none;
  }
`

const NavItemsLeftList = styled.ul`
  width: 20%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-around;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    width: 100%;
    flex-direction: row;
    padding: 4% 0;
    border-top: 2px solid ${props => props.theme.colors.white};
  }
`
const NavItemsRightList = styled.ul`
  width: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 4% 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid ${props => props.theme.colors.white};
    & li {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      width: 90%;
      justify-content: space-around;

      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
    & li:nth-child(2) {
      align-self: center;
      display: flex;
      justify-content: flex-start;
      width: 10%;
    }
  }
`
const ProfileDropdownContainer = styled.li`
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`
const ProfileDropdownSpan = styled.span`
  display: flex;
  align-items: center;
  user-select: none;
`
const ProfileDropdownIcon = styled(KeyboardArrowDown)`
  height: 2em;
  width: 2em;
  padding: 3px;
  transition: transform 0.4s ease;
  ${props =>
    props.isActive &&
    css`
      transform: rotateZ(-180deg);
    `}
  @media screen and (max-width: 800px) {
    transform: rotateZ(-90deg);
    ${props =>
      props.isActive &&
      css`
        transform: rotateZ(90deg);
      `}
  }
`

const DropdownMenuItem = styled.li`
  width: 100%;
  cursor: pointer;
  padding: 5px 5px;
  ${props =>
    !props.isLast &&
    css`
      border-bottom: 2px solid ${props => props.theme.colors.white};
    `}
`
const MobileNotifItem = styled(DropdownMenuItem)`
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  ${props =>
    !props.isLast &&
    css`
      border-bottom: 2px solid ${props => props.theme.colors.white};
    `}
`
const NotifText = styled.span`
  width: 80%;
`
const TurnoButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`
const dropdownCss = css`
  z-index: -10;
  position: absolute;
  top: 2em;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 100%;
  align-items: flex-start;
  list-style-type: none;
  background-color: ${props => props.theme.themeColors[props.theme.selected].secondaryColor};
  border-top: 2px solid ${props => props.theme.colors.white};
  padding: 0 8px;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media screen and (max-width: 800px) {
    position: initial;
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    align-items: center;
    border-top: 0;
    /* border-right: 2px solid ${props => props.theme.colors.white}; */
    transform: translateX(-100vw);
    transition: transform 0.2s ease;
    padding: 0;
    text-align: center;
  }

  &.active {
    z-index: 10;
    opacity: 1;
    @media screen and (min-width: 801px) {
      box-shadow: -1px 2px 20px -2px rgba(255, 255, 255, 0.75);
      border-radius: 4px;
    }
    @media screen and (max-width: 800px) {
      transform: translateX(0);
    }
  }
`

const NotificationsDropdown = styled.ul`
  ${dropdownCss}
  width: 200px;
  right: 0;
  overflow-y: auto;
  max-height: 170px;
  display: flex;
  flex-direction: column;
`

const DropdownMenu = styled.ul`
  ${dropdownCss}
  ${props =>
    props.isAdminUser &&
    css`
      min-height: auto;
    `}
`
const StyledHr = styled.hr`
  width: 100%;
  margin: 0;
  border: 1px solid ${props => props.theme.colors.white};
  @media screen and (max-width: 800px) {
    width: 2px;
    height: 30px;
    margin: 0 5px;
  }
`
const mapDispatchToProps = {
  setModalType,
  showModal,
  setEspecialidades,
  cleanLogout,
  setError
}
const mapStateToProps = state => {
  const { isAuth } = state.authReducer.auth
  const { usuarioId } = state.authReducer.auth
  const { embarazoDoesExist, dateToBirth } = state.profileReducer
  const { pregnancyBeginsOn } = state.calendarReducer.week
  return {
    isAuth,
    usuarioId,
    pregnancyBeginsOn,
    dateToBirth: new Date(dateToBirth),
    embarazoDoesExist
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)
