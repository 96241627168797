import guid from './guid'
// Convert file to base64 string
export const fileToBase64 = fileObject => {
  return new Promise(resolve => {
    let reader = new FileReader()
    // Read file content on file loaded event
    reader.onload = function(event) {
      resolve(event.target.result)
    }
    // Convert data to base64
    reader.readAsDataURL(fileObject)
  })
}

export const b64toFile = (b64Data, fileName = guid(), contentType = '', sliceSize = 512) => {
  return new Promise(
    resolve => {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const file = new File(byteArrays, fileName, { type: contentType })
      resolve(file)
    },
    reject => {
      reject(Promise.reject())
    }
  )
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  return new Promise(
    resolve => {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      resolve(blob)
    },
    reject => {
      reject(Promise.reject())
    }
  )
}
