import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { EventAvailable, Forum, HelpOutline, MailOutline, Person } from 'styled-icons/material'

import { getEspecialidades } from '../../api'
import { setModalType, setEspecialidades, showModal } from '../../redux/actions/modalActions'
import CardsRow from '../Card/CardsRow'
import Card from '../Card/Card'
import { MODAL_TYPES, ID_RESERVA_CAMA } from '../../constants/generics'
const { TURNO_ESPECIALIDAD, CONSULTA_ESPECIALISTA, CONSULTA, PROFILE } = MODAL_TYPES

class Servicios extends Component {
  constructor(props) {
    super(props)
    this.state = {
      especialidades: []
    }
  }

  componentDidMount() {
    getEspecialidades()
      .then(res => {
        const especialidades = res.data.filter(especialidad => especialidad.id !== ID_RESERVA_CAMA)
        this.setState({ especialidades })
      })
      .catch(console.log)
  }

  onClickHandler = type => {
    if (type === TURNO_ESPECIALIDAD || type === CONSULTA_ESPECIALISTA) {
      this.props.setEspecialidades(this.state.especialidades)
    }
    this.props.setModalType(type)
    this.props.showModal()
  }

  render() {
    return (
      <Section>
        <ServiciosContainer>
          <CardsRow>
            <Card
              clickHandler={() => this.onClickHandler(TURNO_ESPECIALIDAD)}
              icon={<EventAvailableIcon size="3em" />}
              text="Solicitá turnos online"
            />
            <Card link="/blog" icon={<ForumIcon size="3em" />} text="Visitá nuestro blog" />
            <Card
              clickHandler={() => this.onClickHandler(CONSULTA_ESPECIALISTA)}
              icon={<HelpOutlineIcon size="3em" />}
              text="Consultá a un especialista"
            />
            <Card
              clickHandler={() => this.onClickHandler(CONSULTA)}
              icon={<HelpOutlineIcon size="3em" />}
              text="Contactá a tu pregnancy planner"
            />
            <Card
              link={'/mis-correos'}
              icon={<MailIcon size="3em" />}
              text="Accedé a tus mensajes"
            />
            <Card
              icon={<PersonIcon size="3em" />}
              text="Mí perfíl"
              clickHandler={() => this.onClickHandler(PROFILE)}
            />
          </CardsRow>
        </ServiciosContainer>
      </Section>
    )
  }
}
const PersonIcon = styled(Person)`
  color: ${props => props.theme.themeColors[props.theme.selected].serviciosIcon};
`
const ForumIcon = styled(Forum)`
  color: ${props => props.theme.themeColors[props.theme.selected].serviciosIcon};
`
const EventAvailableIcon = styled(EventAvailable)`
  color: ${props => props.theme.themeColors[props.theme.selected].serviciosIcon};
`
const HelpOutlineIcon = styled(HelpOutline)`
  color: ${props => props.theme.themeColors[props.theme.selected].serviciosIcon};
`
const MailIcon = styled(MailOutline)`
  color: ${props => props.theme.themeColors[props.theme.selected].serviciosIcon};
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const ServiciosContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60vh;
  background-color: ${props => props.theme.themeColors[props.theme.selected].serviciosBackground};
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 800px) {
    height: 67vh;
    flex-direction: row;
    align-items: center;
  }
`
const mapDispatchToProps = {
  setEspecialidades,
  setModalType,
  showModal
}

export default connect(
  null,
  mapDispatchToProps
)(Servicios)
