import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { CheckBoxOutlineBlank, CheckBox, StarBorder, Star } from 'styled-icons/material'

import { dateParse } from '../../utils/date'
import { ACTION_ICON_SIZE } from '../../constants/generics'

import MailActionLoading from './MailActionLoading'

const MailItem = props => {
  return (
    <Item isNewMail={props.isNewMail}>
      {!props.isPapelera && (
        <>
          <Span style={{ width: '10%' }}>
            <IconContainer onClick={() => props.setSelect(props.id)}>
              {props.isSelected ? (
                <CheckBox size={ACTION_ICON_SIZE} />
              ) : (
                <CheckBoxOutlineBlank size={ACTION_ICON_SIZE} />
              )}
            </IconContainer>
          </Span>
          <Span style={{ width: '7%', marginRight: '2%', height: '64px' }}>
            {!props.isFavLoading ? (
              <IconContainer onClick={() => props.setFavorite(props.id)}>
                {props.isFav ? (
                  <Star size={ACTION_ICON_SIZE} />
                ) : (
                  <StarBorder size={ACTION_ICON_SIZE} />
                )}
              </IconContainer>
            ) : (
              <MailActionLoading />
            )}
          </Span>
        </>
      )}
      <Span style={{ width: '65%', paddingRight: '1%' }}>
        <Asunto onClick={() => props.openMail(props.idRaiz)}>
          {props.subject || 'Mail sin asunto'}
        </Asunto>
        <span style={{ marginLeft: '1.5%' }}>
          {props.cantidadEnHilo > 1 && `(${props.cantidadEnHilo})`}
        </span>
      </Span>
      <Span style={{ width: props.isPapelera ? '35%' : '22%', paddingRight: '1%' }}>
        {dateParse(props.date)}
      </Span>
    </Item>
  )
}

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`

const Span = styled.span`
  align-items: center;
  display: flex;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
const Asunto = styled.span`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  height: auto;
  background-color: ${props => props.theme.colors.white};
  padding: 0 1%;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-color: ${props => props.theme.colors.lightGray};
  &:hover {
    background-color: ${props => props.theme.themeColors[props.theme.selected].quinaryColor};
  }
  ${props =>
    props.isNewMail &&
    css`
      font-weight: 600;
    `}
`
MailItem.propTypes = {
  id: PropTypes.number.isRequired,
  subject: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  isNewMail: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  isFav: PropTypes.bool.isRequired,
  setFavorite: PropTypes.func.isRequired,
  setSelect: PropTypes.func.isRequired
}

export default MailItem
