import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Card = props => {
  return props.link ? (
    <StyledLink to={props.link} {...props}>
      {props.icon}
      <Span>{props.text}</Span>
    </StyledLink>
  ) : (
    <Container onClick={props.clickHandler} {...props}>
      {props.icon}
      <Span>{props.text}</Span>
    </Container>
  )
}

Card.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  link: PropTypes.string
}

const Span = styled.span`
  color: ${props => props.theme.colors.darkGrayBlue};
  text-align: center;
  font-weight: bold;
`
const containerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  width: 15%;
  height: 100%;
  padding: 0% 3%;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: 800px) {
    width: 30%;
    height: 25vh;
    flex: 0 0 30%;
    margin-bottom: 15px;
  }
`
const Container = styled.div`
  ${containerCss}
`
const StyledLink = styled(Link)`
  ${containerCss}
  text-decoration: none;
`
export default Card
