import React from 'react'
import styled from 'styled-components'

import colors from '../../styled/colors'

const IconCarousel = props => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox={props.viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{props.title}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <Path d={props.feto} id={props.id} isActive={props.isActive} onClick={props.clickHandler} />
      </g>
    </svg>
  )
}

const Path = styled.path`
  cursor: pointer;
  &:hover {
    fill: ${props => props.theme.themeColors[props.theme.selected].bebeIconActive};
  }
  fill: ${props =>
    props.isActive
      ? props.theme.themeColors[props.theme.selected].bebeIconActive
      : colors.lightGray};
`

export default IconCarousel
