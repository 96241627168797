import React from 'react'

import BLOG_CARD_UTILIDAD from '../img/blog/utilidad_internacion.jpg'
import BLOG_CARD_RECIEN_NACIDO from '../img/blog/info_recien_nacido.jpg'
import BLOG_CARD_EGRESO from '../img/blog/info_egreso.jpg'
import BLOG_CARD_VACUNACION from '../img/blog/vacunacion.jpg'
import BLOG_CARD_LACTANCIA from '../img/blog/info_lactancia.jpg'
import BLOG_CARD_POSICIONES from '../img/blog/posiciones.jpg'
import BLOG_CARD_RECOMENDACIONES_GENERALES from '../img/blog/recomendaciones_grales.jpg'

export const BLOG_DATA = [
         [
           {
             title: 'Información de utilidad para la internación',
             detail:
               'Consultá aquí la información que te será de utilidad durante la internación. Admisión y documentación.',
             path: '/blog/informacion-de-utilidad-para-la-internacion',
             image: BLOG_CARD_UTILIDAD,
             breadcrumbsRoutes: [
               {
                 title: 'Información de utilidad para la internación',
                 path: '/blog/informacion-de-utilidad-para-la-internacion'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'Documentación requerida al momento del ingreso',
                   content: (
                     <ul>
                       <li>Documentos y credencial de la Obra Social/Prepaga</li>
                       <li>Órden de internación autorizada por tu Obra Social/Prepaga</li>
                       <li>
                         Depósito de garantía para cubrir los gastos del acompañante en caso
                         de que se requiera, (consultá el valor vigente al momento de tu
                         internación).
                       </li>
                     </ul>
                   )
                 },
                 {
                   title: 'Habitaciones',
                   content: (
                     <ul>
                       <li>
                         La habitación podrá ser individual o compartida, según corresponda a
                         tu plan de cobertura y a lo que tu Obra Social/Prepaga autorice.
                       </li>
                       <li>
                         Todas las habitaciones cuentan con baño privado, pantallas LCD,
                         vidrios insonorizados y conexión Wi-fi. Los servicios de televisión
                         por cable, cama y servicio de comidas para el acompañante, y
                         telefonía, son opcionales y a cargo del paciente.
                       </li>
                     </ul>
                   )
                 },
                 {
                   title: 'Horarios de visita',
                   content: (
                     <ul>
                       <li>
                         <strong>Clínica Bazterrica:</strong> por la mañana de 9 a 12 y por
                         la tarde de 16 a 20
                       </li>
                       <li>
                         <strong>Clínica Santa Isabel:</strong> por la mañana de 9.30 a 12.30
                         y por la tarde de 16.30 a 19.30
                       </li>
                     </ul>
                   )
                 },
                 {
                   title: 'Qué llevar en el bolso',
                   content: (
                     <React.Fragment>
                       <h4>
                           Empezá a armar tu bolso de 2 a 4 días antes de la internación.
                       </h4>
                       <h4>Para vos:</h4>
                       <ul>
                         <li>
                           Últimos estudios realizados (laboratorio, electrocardiograma,
                           ecografías).
                         </li>
                         <li>
                           Camisón para amamantar (en lo posible que sea de mangas cortas o
                           sin mangas).
                         </li>
                         <li>Ropa interior que pueda mancharse o desecharse.</li>
                         <li>Corpiños de lactancia.</li>
                         <li>Protectores mamarios.</li>
                         <li>Ropa cómoda para el momento del alta sanatorial.</li>
                       </ul>
                       <h4>Para tu bebé:</h4>
                       <p>
                         Ya próximo al momento del nacimiento del bebé, tener preparado en
                         una bolsa transparente una batita de algodón blanca, un pantaloncito
                         de algodón blanco o un body blanco, un par de medias, un gorrito de
                         algodón blanco y una manta/colchita para recibirlo.
                       </p>
                       <p>
                         La bolsa debe estar limpia y etiquetada con tu nombre y apellido y
                         nombre del bebé
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Para la estadía en la clínica',
                   content: (
                     <React.Fragment>
                       <ul>
                         <li>
                           Batitas o camisetas y pantaloncitos de algodón (en lo posible de
                           color blanco).
                         </li>
                         <li>“Ositos” de algodón.</li>
                         <li>Saquitos o enteritos de lana.</li>
                         <li>Gorritos.</li>
                         <li>Medias.</li>
                         <li>Manta y mantilla de lana</li>
                         <li>
                           Ropa para el momento del alta sanatorial (tené en cuenta la
                           estación del año)
                         </li>
                       </ul>
                       <p>
                         Los artículos descartables para la mamá y su bebé (pañales, gasas,
                         óleo calcáreo, algodón, alcohol, apósitos) son provistos por la
                         Clínica.
                       </p>
                     </React.Fragment>
                   )
                 }
               ]
             }
           },
           {
             title: 'Información sobre el recién nacido',
             detail: 'Consejos, información y recomendaciones sobre el recién nacido.',
             path: '/blog/informacion-sobre-el-recien-nacido',
             image: BLOG_CARD_RECIEN_NACIDO,
             breadcrumbsRoutes: [
               {
                 title: 'Información sobre el recién nacido',
                 path: '/blog/informacion-sobre-el-recien-nacido'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'El primer contacto con tu bebé',
                   content: (
                     <React.Fragment>
                       <p>
                         Sin duda, no existe un momento tan especial en la vida como el
                         primer contacto con un hijo, tanto para vos como para él.
                       </p>
                       <p>
                         El bebé tiene capacidades que le permiten reconocer a la madre: por
                         tu voz (la ha escuchado durante todo el embarazo), por el ritmo de
                         tu corazón, por su olfato, etc. También pueden alinear sus ojos con
                         los ojos de los padres, ya que ven de cerca.
                       </p>
                       <p>
                         Todas estas capacidades son las que permiten, desde el primer
                         momento, tener una conexión tan profunda y especial que establezca
                         un sólido vínculo entre madre e hijo. No tengas miedo ni te
                         angusties si el bebé llora, ya que esta es su forma de comunicación
                         si tiene hambre, miedo, dolor, frío o si está incómodo y además, lo
                         ayuda a liberar tensiones. También es la forma de expresar su
                         necesidad de contacto físico. Abrazalo y acaricialo. Si tu bebé
                         llora repetidamente durante largos periodos, el médico puede
                         ayudarte a decidir si ese llanto tiene algún significado o no.
                       </p>
                       <p>
                         Inmediatamente después del nacimiento comienza a formarse el vínculo
                         madre-hijo y para que este se desarrolle de forma adecuada es
                         necesario que el bebé este en contacto con sus padres
                         permanentemente. En esto consiste la internación conjunta. Esta es
                         la forma de desarrollar un vínculo sano, sólido y creciente.
                       </p>
                       <p>
                         También facilita el desarrollo de una adecuada lactancia, así como
                         preparar a la madre para la atención de su hijo en los días
                         posteriores.
                       </p>
                       <p>
                         Es nuestra intención que durante tu internación adquieras
                         experiencia práctica en la atención de tu hijo, además de adquirir
                         conocimientos y establecer una buena relación madre-hijo.
                       </p>
                       <p>
                         Para eso en la bolsa individual de cada cunita, la nurse te dejará
                         todo lo necesario para la higiene y el cuidado del bebé.
                       </p>
                       <p>
                         Tu bebé será revisado todos los días por el pediatra y la nurse de
                         cada turno hablará con vos sobre “cómo atender a tu hijo”.
                       </p>
                       <p>
                         Es conveniente que las dudas que vayan originando la nueva relación
                         y cuidados las escribas en un papel para que sean aclaradas por la
                         nurse.
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'El llanto del bebé',
                   content: (
                     <React.Fragment>
                       <p>
                         El llanto del bebé despierta generalmente muchas angustias en la
                         mamá y en el papá. Ante esto, recomendamos serenarse y confiar en
                         que este momento pasará. Los bebés se expresan mediante el llanto,
                         nos quieren decir algo. Vos, con el correr del tiempo, podrás
                         decodificar qué necesita tu bebé en cada momento: puede que tenga
                         hambre, sueño, necesidad de succionar, incomodidad y lo que con más
                         frecuencia necesitan, UPA.
                       </p>
                       <p>
                         Comúnmente este tipo de necesidades comienza con un llanto leve que
                         va agudizándose si no es satisfecho de inmediato.
                       </p>
                       <p>
                         Ahora bien, cuando el llanto se presenta de manera abrupta puede que
                         sienta alguna molestia o dolor (cólico). Esto generalmente les
                         sucede a algunos bebés y les genera un dolor muy fuerte, que se
                         refleja en su llanto.
                       </p>
                       <p>
                         Podés probar sostenerlo firmemente boca abajo flexionando sus
                         piernas hacia abajo y hablarle suavemente. Es un momento que pasará.
                         En estos momentos intentá tranquilizarte, lo único que podés hacer
                         es acompañarlo y sostenerlo.
                       </p>
                       <p>
                         El recién nacido necesita contacto físico. En el vientre materno
                         estaba protegido. Ahora se encuentra en un mundo lleno de estímulos,
                         luces, voces, sonidos, olores. Estando en brazos de mamá o papá se
                         siente seguro y desde allí le es más placentero interactuar con el
                         mundo.
                       </p>
                       <p>
                         Contrariamente a lo que se creía, hacerle upa y cantarle a un bebé
                         reduce gradualmente la duración del llanto, sin que esto implique
                         malcriarlos, sino todo lo contrario.
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Vestimenta',
                   content: (
                     <>
                       <p>
                         El recién nacido debe usar ropa suave, no ajustada al cuerpo, y que
                         le permita la mayor movilidad, incluyendo la de sus pies. La
                         elección de la ropa corre por tu cuenta. El único patrón de
                         referencia válido sos vos misma. Si tenés frío, abrigá al bebé. Si
                         tenés calor, desabrígalo.
                       </p>
                       <p>
                         Recordá que el recién nacido siente un poco más el calor y el frío
                         que el adulto. Te recordamos que se puede perfumar la ropa del bebé
                         pero no su piel.
                       </p>
                     </>
                   )
                 },
                 {
                   title: 'Higiene y cuidado del cordón umbilical',
                   content: (
                     <React.Fragment>
                       <p>Antes de tocar a tú bebé, siempre deberás lavarte las manos.</p>
                       <p>
                         El cordón umbilical no cumple ninguna función después del nacimiento
                         y se cae generalmente entre los siete y diez días de edad.
                       </p>
                       <p>
                         Cada vez que cambies a tu bebé, mojá el cordón con una gasa embebida
                         en alcohol, especialmente en la zona del cordón que se une a la
                         piel. Desechá esta gasa, no es imprescindible cubrirlo con otra gasa
                         seca.
                       </p>
                       <p>
                         Al desprenderse el cordón, pueden aparecer unas gotas de sangre en
                         su base. Esto es normal, no te preocupes. Continuá humedeciendo la
                         herida con alcohol durante tres días más. Si persistiera la humedad
                         después de este periodo, consultá con tu médico. También consultá si
                         en cualquier momento hasta entonces percibieras mal olor, o
                         secreción amarillo verdoso, y fundamentalmente un enrojecimiento
                         alrededor del ombligo.
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Higiene y cuidados del bebé',
                   content: (
                     <ul>
                       <li>
                         Los recién nacidos suelen tener una deposición durante o después de
                         cada mamada.
                       </li>
                       <li>
                         Durante los primeros siete a diez días se les deben cambiar los
                         pañales después de cada comida.
                       </li>
                       <li>
                         Para la higiene de la cola de tu bebé es conveniente el uso del óleo
                         calcáreo o lavar la zona cubierta por el pañal con agua tibia,
                         secalo lo mejor posible y después aplicá el óleo calcáreo o una
                         crema protectora.
                       </li>
                       <li>
                         Para bañar a tu bebé utilizá jabón de glicerina neutro, sin perfume.
                       </li>
                       <li>
                         No se aconseja el uso de talco ni féculas, tampoco el uso de
                         perfumes ya que pueden producir reacciones en la piel del recién
                         nacido.
                       </li>
                       <li>
                         Intentá repetir siempre el mismo horario para el baño. La
                         regularidad no obsesiva les da seguridad a los bebés.
                       </li>
                       <li>Las uñas se deben mantener cortas, limándolas, sin cortarlas.</li>
                       <li>
                         Las orejitas se limpian con algodón por fuera y no se aconseja
                         introducir hisopos dentro del conducto auditivo.
                       </li>
                     </ul>
                   )
                 }
               ]
             }
           },
           {
             title: 'Información para el egreso',
             detail:
               'Consultá aquí la información que te será de utilidad durante el egreso del sanatorio.',
             path: '/blog/informacion-para-el-egreso',
             image: BLOG_CARD_EGRESO,
             breadcrumbsRoutes: [
               {
                 title: 'Información para el egreso',
                 path: '/blog/informacion-para-el-egreso'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'El primer contacto con tu bebé',
                   content: (
                     <React.Fragment>
                       <p>
                         El primer control del bebé debe realizarse entre las 24 y 48 horas
                         del alta sanatorial, salvo expresa indicación médica.
                       </p>
                       <p>
                         Consultá con el Servicio de Neonatología cuándo y dónde deberás
                         realizar el control tu bebé.
                       </p>
                       El primer control post alta se realiza en:
                       <ul>
                         <li>
                           <strong>Clínica Bazterrica:</strong> lunes, miércoles y viernes de
                           11 a 13.
                         </li>
                         <li>
                           <strong>Clínica Santa Isabel:</strong> de lunes a viernes a las
                           14.
                         </li>
                       </ul>
                       <p>
                         <strong>
                           Es importante que luego del alta sanatorial elijas un pediatra de
                           cabecera para tu hijo. Él será quien va a realizar todos los
                           controles del bebé y quien despejará tus dudas.
                         </strong>
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Algunas características esperables del recién nacido',
                   content: (
                     <React.Fragment>
                       <p>
                         Con el afán de evitarte preocupaciones innecesarias, queremos
                         contarte que los recién nacidos suelen presentar algunos
                         comportamientos que carecen de significado alguno y, por lo tanto,
                         no requieren consulta ni tratamiento.
                       </p>
                       <strong>Entre ellas, podemos señalar las siguientes:</strong>
                       <ul>
                         <li>Estornudos: no implican resfríos ni falta de abrigo.</li>
                         <li>
                           Hipo: no requiere tratamiento, ya que al bebé no le molesta.
                         </li>
                         <li>
                           Descamación de la piel: a veces muy generalizada y visible, es
                           normal.
                         </li>
                         <li>Manos y pies fríos: no suele ser señal de falta de abrigo.</li>
                         <li>
                           Pechos hinchados: por pasaje normal de hormonas maternas a través
                           de la placenta, tanto en varones como en niñas. Nunca lo apriete.
                         </li>
                         <li>
                           Pequeña menstruación y/o flujo: por el mismo mecanismo hormonal,
                           las niñas suelen tener durante las primeras semanas, un flujo
                           blanquecino, sin olor y algunas de ellas pueden acompañar esto con
                           unas gotas de sangre. Limitate a limpiar suavemente con un algodón
                           embebido en agua tibia, de adelante hacia atrás.
                         </li>
                         <li>
                           Cólicos: durante el primer trimestre de vida, casi todos los bebés
                           padecen de cólicos, en grado mayor o menor. Estos son tanto más
                           importantes cuanta más ansiedad despiertan. Procurá calmarte, tomá
                           a tu bebé en brazos y acaricialo hasta que se calme.
                         </li>
                       </ul>
                       <p>
                         <strong>Consultá con tu médico si:</strong>
                       </p>
                       <ul>
                         <li>
                           El bebé rechaza el pecho en varias oportunidades y/o no puede
                           permanecer prendido en el pezón.
                         </li>
                         <li>
                           El bebé se duerme rápidamente cuando se lo pone al pecho y no se
                           lo puede mantener despierto el tiempo que es necesario para que se
                           pueda alimentar de forma adecuada.
                         </li>
                         <li>
                           En las primeras dos - tres semanas de vida, el bebé duerme más de
                           cuatro a cinco horas seguidas en la noche sin despertarse para
                           comer.
                         </li>
                         <li>
                           El bebé tiene menos de 6 pañales húmedos por día o tiene pañales
                           que están ligeramente húmedos.
                         </li>
                         <li>
                           El bebé elimina pocas deposiciones, o tiene heces mucosas de color
                           verde oscuro.
                         </li>
                         <li>El bebé está pálido, azul o amarillo con ictericia.</li>
                         <li>
                           La temperatura rectal del bebé es mayor de 38,1ºC o menor de 36,3
                           ºC.
                         </li>
                         <li>
                           El bebé está irritable y no puede ser satisfecho con la mamada.
                         </li>
                         <li>El bebé parece débil y apático.</li>
                         <li>
                           Estás tomando cualquier medicación prescripta o de venta libre,
                           mientras estás amamantando.
                         </li>
                         <li>
                           Tenés cualquier inquietud o preocupación acerca de la lactancia.
                         </li>
                       </ul>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Recomendaciones generales',
                   content: (
                     <ul>
                       <li>
                         Acostá a tu bebé boca arriba o de costado (excepto indicación
                         médica).
                       </li>
                       <li>
                         Realizá el control periódico de la salud del bebé (vacunas,
                         alimentación, consejos varios, etc.)
                       </li>
                       <li>Evitá la permanencia del bebé en sitios donde se fuma.</li>
                       <li>Estimulá la lactancia materna.</li>
                       <li>
                         Cuidá que el colchón de la cuna o moisés sea de consistencia firme.
                         No coloques almohadas, plumones o colchones mullidos debajo del
                         bebé, ya que está corriendo un riesgo.
                       </li>
                       <li>
                         Que los bebés duerman con los brazos por fuera de la ropa de cama
                         (de este modo sus manos se enfrían y el sueño es más superficial).
                       </li>
                       <li>
                         Si se lo acuesta de costado el brazo debe quedar en ángulo recto con
                         su cuerpo, para evitar que pueda darse vuelta y quedar boca abajo.Si
                         se lo acuesta de costado el brazo debe quedar en ángulo recto con su
                         cuerpo, para evitar que pueda darse vuelta y quedar boca abajo.
                       </li>
                       <li>
                         Que el lactante duerma en la habitación de los padres los primeros
                         meses.
                       </li>
                       <li>No sobre abrigar al bebé ni sobrecalentar la habitación.</li>
                     </ul>
                   )
                 },
                 {
                   title: 'Estudios post alta',
                   content: (
                     <ul>
                       <li>
                         <strong>Otoemisiones acústicas (Pesquisa de hipoacusia):</strong>
                         <h4>¿Qué son las otoemisiones acústicas?</h4>
                         <p>
                           En el oído interno existen unas células llamadas “sensoriales”,
                           que tienen la extraordinaria condición de poder transformar el
                           sonido que reciben del exterior en impulsos eléctricos que a su
                           vez serán transmitidos al cerebro a través del nervio auditivo.
                           Pero además, una parte de ellas tienen la propiedad de producir
                           también sonido. A este sonido, que puede ser espontáneo o
                           provocado por un estímulo acústico, es posible detectarlo con un
                           pequeño micrófono colocado en el conducto auditivo externo. Estos
                           sonidos que nacen en el oído son las otoemisiones acústicas
                         </p>
                         <p>
                           Los equipos de otoemisiones acústicas para realizar “Screening
                           Auditivo” son dispositivos pequeños, fácilmente transportables y
                           de manejo simple. El método en sí mismo es inocuo, no requiere
                           preparación especial, se puede realizar en cualquier ambiente,
                           incluso en la cuna de la Nursery. Simplemente, se aplica con
                           cuidado en el conducto auditivo externo y el dispositivo, de
                           manera automática, estimula, detecta y registra las otoemisiones
                           en contados minutos, sin alterar al niño.
                         </p>
                       </li>
                       <li>
                         <strong>Primer Control Oftalmológico:</strong>
                         <p>
                           Luego del alta deberás solicitar turno para la primera consulta
                           oftalmológica del bebé.
                         </p>
                         <p>
                           Tanto para los estudios como para consultas médicas, podés
                           solicitar turnos en la{' '}
                           <strong>Central Integral de turnos </strong>
                           al <a href="tel:0800-666-6587">0800 666 6587</a>.
                         </p>
                       </li>
                     </ul>
                   )
                 },
                 {
                   title: 'Trámite de inscripción del recién nacido',
                   content: (
                     <React.Fragment>
                       <p>
                         Por disposición de la Dirección General del Gobierno de la Ciudad
                         Autónoma de Buenos Aires, se ha modificado el proceso para la
                         inscripción y obtención de la partida de nacimiento de los recién
                         nacidos. La Clínica ha sido designada para validar los nacimientos,
                         correspondiendo a las madres o los padres el trámite de inscripción
                         de los recién nacidos.
                       </p>
                       <ul>
                         <li>
                           La inscripción de los bebés nacidos en{' '}
                           <strong>Clínica Santa Isabel</strong> se realiza en el CGP N° 7,
                           Av. Rivadavia 7202.
                         </li>
                         <li>
                           La inscripción de los bebés nacidos en{' '}
                           <strong>Clínica Bazterrica</strong> se realiza en el Registro
                           Nacional de las Personas, Sarandí 1273.
                         </li>
                       </ul>
                       <p>
                         Padres o madres cuentan con un plazo de 40 (cuarenta) días corridos
                         a partir de la fecha del nacimiento para anotar a su bebé. Para
                         esto, podrás solicitar turno al teléfono <a href="tel:147">147</a>{' '}
                         (Gobierno de la Ciudad), por internet en:{' '}
                         <a href="www.registrocivil.gob.ar">www.registrocivil.gob.ar</a>.
                       </p>
                       <strong>Requisitos para la inscripción:</strong>
                       <ul>
                         <li>Solicitar turno</li>
                         <li>DNI de madre y padre (original y copia).</li>
                         <li>
                           Libreta o acta de matrimonio (en caso de estar casados, original y
                           copia).
                         </li>
                       </ul>
                       <p>
                         Para más información, podés ingresar en:
                         <a href="www.buenosaires.gob.ar">www.buenosaires.gob.ar</a>
                       </p>
                     </React.Fragment>
                   )
                 }
               ]
             }
           }
         ],
         [
           {
             title: 'Calendario de vacunación del recién nacido',
             detail:
               'Las vacunas son una herramienta valiosa para prevenir enfermedades, por ello cumplir con el calendario es fundamental para lograrlo.',
             path: '/blog/calendario-de-vacunacion-del-recien-nacido',
             image: BLOG_CARD_VACUNACION,
             breadcrumbsRoutes: [
               {
                 title: 'Calendario de vacunación del recién nacido',
                 path: '/blog/calendario-de-vacunacion-del-recien-nacido'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'Documentación requerida al momento del ingreso',
                   content: <div />
                 },
                 {
                   title: 'Habitaciones',
                   content: <div />
                 },
                 {
                   title: 'Horarios de visita',
                   content: <div />
                 },
                 {
                   title: 'Para incluir en tu bolso',
                   content: <div />
                 },
                 {
                   title: 'Para la estadía en la clínica',
                   content: <div />
                 }
               ]
             }
           },
           {
             title: 'Información acerca de la lactancia materna',
             detail:
               'Descubre lo que necesitas saber sobre periodos de lactancia, resuelve tus dudas sobre la conservación de la leche y cuidados para la madre.',
             path: '/blog/informacion-acerca-de-la-lactancia-materna',
             image: BLOG_CARD_LACTANCIA,
             breadcrumbsRoutes: [
               {
                 title: 'Información acerca de la lactancia materna',
                 path: '/blog/informacion-acerca-de-la-lactancia-materna'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'Información general',
                   content: (
                     <React.Fragment>
                       <p>
                         Entre el cuarto y sexto día después del parto, se produce un aumento
                         brusco en la producción de leche. Por eso es normal que la mamá
                         sienta los pechos más pesados y algo sensibles. Esta sensación
                         normalmente se va en las primeras dos o tres semanas de lactancia.
                       </p>
                       <p>
                         Como sabrás, el mejor alimento para el bebé es la leche materna, ya
                         que contiene todos los elementos fundamentales para la adecuada
                         nutrición del bebé y ejerce acción protectora frente a las
                         infecciones.
                       </p>
                       <p>
                         El amamantamiento es un aprendizaje para la mamá y para el bebé. Un
                         niño recién nacido, a menudo solo lame el pezón las primeras veces.
                         Cuando se prende, succiona un poco, descansa y luego succiona otra
                         vez.
                       </p>
                       <p>
                         La primera leche que recibe el bebé es el calostro que, aunque sea
                         poquito, posee anticuerpos que lo protegen de infecciones graves y
                         hace que elimine precozmente el mecoño. Es muy rico en proteínas y
                         satisface todas las necesidades nutricionales del recién nacido.
                         Recordá: el estímulo más importante para la bajada de leche es la
                         succión del pecho. Por ese motivo, es muy importante aprovechar el
                         estado de alerta del bebé durante las primeras horas de vida.
                       </p>
                       <p>
                         Hay múltiples posiciones para el amamantamiento, pero cada mamá
                         deberá elegir aquella en la que se encuentre más cómoda y le resulte
                         más placentera. A fin de evitar que se lastimen los pezones, lo
                         ideal es ir alternando las diferentes posiciones para lograr un
                         efectivo drenaje de la glándula mamaria, la boca debe abarcar todo
                         lo que pueda de la aréola. Luego, para retirarlo del pecho, con
                         suavidad deberá introducir su dedo en la boca del bebé.
                       </p>
                       <p>
                         Lo esencial es que cualquiera sea la posición en que esté colocado
                         el bebé, quede enfrentado al cuerpo de la mamá (panza con panza),
                         bien pegado, con el mentón bien apoyado en el pecho y con la cabeza
                         y el cuerpo en un solo eje. Es aconsejable que la mamá utilice
                         almohadones, que ubicará de manera estratégica para mejorar su
                         postura.
                       </p>
                       <p>
                         Es el niño quien regula la frecuencia de las mamadas, de acuerdo con
                         la necesidad que tenga de recibir alimento y de hidratarse.
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Pautas de cuidados para los pezones',
                   content: (
                     <React.Fragment>
                       <p>
                         Durante la gestación y luego del nacimiento del bebé, cuidar los
                         pechos es muy importante.
                       </p>
                       <p>
                         Se pueden evitar así algunos problemas como: dolor de mamas,
                         enrojecimiento en los pezones y grietas. Existen algunos tipos de
                         pezones que requieren especial atención.
                       </p>
                       <p>
                         Estos inconvenientes se dan en los primeros tiempos de la lactancia,
                         cuando los pezones no están acostumbrados a la succión y la demanda
                         de un bebé. El momento más adecuado para empezar la preparación es
                         entre el quinto y el sexto mes del embarazo. Y los momentos ideales
                         para cuidar el pezón son al levantarse y al acostarse, y después del
                         baño. Como cualquier otro tratamiento es necesario consultar al
                         médico antes de realizarlo.
                       </p>
                       Los pezones necesitan <strong>alas:</strong>
                       <ul>
                         <li>
                           <strong>A:</strong> Aire por medio natural o con escudos
                           aireadores. El pezón debe estar siempre seco, y en lo posible
                           expuesto al aire.
                         </li>
                         <li>
                           <strong>L:</strong> Lubricación, con calostro o crema de
                           caléndula.
                         </li>
                         <li>
                           <strong>A:</strong> Agua, el agua limpia preserva la grasa natural
                           de los pezones. No es necesario usar jabón para lavar la zona.
                         </li>
                         <li>
                           <strong>S:</strong> Sol, tomar sol directamente en los pezones, 15
                           minutos por la mañana y 15 minutos por la tarde. Por supuesto,
                           hacerlo en los horarios de exposición recomendados.
                         </li>
                       </ul>
                     </React.Fragment>
                   )
                 }
               ]
             }
           },
           {
             title: 'Posiciones para amamantar',
             detail:
               'Las mejores posiciones para amamantar a tu bebé de forma segura y cómoda.',
             path: '/blog/posiciones-para-amamantar',
             image: BLOG_CARD_POSICIONES,
             breadcrumbsRoutes: [
               {
                 title: 'Posiciones para amamantar',
                 path: '/blog/posiciones-para-amamantar'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'Información general',
                   content: (
                     <p>
                       Algunos bebés sueltan el pecho una vez vaciado. Otros, en cambio,
                       cuando terminan continúan succionando, pero de manera más superficial.
                       Es lo que se conoce como succión no nutritiva que el lactante realiza
                       por el simple placer que le brinda succionar la teta de la mamá. Si
                       bien este movimiento es muy importante, es necesario distinguir los
                       dos tipos de succión para saber si el pequeño está ingiriendo la leche
                       o no.
                     </p>
                   )
                 },
                 {
                   title: 'Posición inversa',
                   content: (
                     <>
                       <p>
                         En caso de cesárea, pare evitar molestias en el abdomen de la mamá
                         se recomienda colocar al bebé al costado del cuerpo de la mamá.
                       </p>
                       <p>
                         Hay que sostener con la mano la nuca del bebé y guiarlo en la
                         colocación al pecho.
                       </p>
                     </>
                   )
                 },
                 {
                   title: 'Posición cuna',
                   content: (
                     <p>
                       Podés acostar al bebé sobre una almohada. La mamá le ofrece el pecho
                       con la mano, del lado que amamanta.
                     </p>
                   )
                 },
                 {
                   title: 'Posición caballito (sentado)',
                   content: (
                     <p>
                       El bebé está sentado sobre una almohada frente al pecho. La mamá lo
                       sostiene con su antebrazo.
                     </p>
                   )
                 },
                 {
                   title: 'Posición biológica (recostado sobre la madre)',
                   content: (
                     <p>
                       Cuando el reflejo de eyección es abundante, la mamá puede recostar
                       boca abajo al bebé sobre su abdomen y sostenerlo suavemente sobre la
                       frente del bebé.La mamá y el bebé están enfrentados, panza con panza.
                       La cabeza del bebé se apoya en el brazo inferior de la mamá.
                     </p>
                   )
                 },
                 {
                   title: 'Posición acostada',
                   content: (
                     <p>
                       Posición acostadas de succión para saber si el pequeño está ingiriendo
                       la leche o no.
                     </p>
                   )
                 }
               ]
             }
           }
         ],
         [
           {
             title: 'Recomendaciones generales',
             detail:
               'Información variada, consejos para el regreso al trabajo y otras recomendaciones.',
             path: '/blog/recomendaciones-generales',
             image: BLOG_CARD_RECOMENDACIONES_GENERALES,
             breadcrumbsRoutes: [
               {
                 title: 'Recomendaciones generales',
                 path: '/blog/recomendaciones-generales'
               },
               {
                 title: 'Blog',
                 path: '/blog'
               },
               {
                 title: 'Inicio',
                 path: '/'
               }
             ],
             data: {
               list: [
                 {
                   title: 'Información general',
                   content: (
                     <ul>
                       <li>
                         Prestar atención a la posición y colocación del bebé para que tome
                         el pecho: la boca del bebé bien abierta, y panza con panza.
                       </li>
                       <li>
                         Lactancia frecuente y sin restricciones: entre 8 y 12 veces por día.
                       </li>
                       <li>
                         Variar las posiciones para que se vacíen todos los conductos y así
                         evitar su taponamiento.
                       </li>
                       <li>
                         Antes de dar el pecho, realizar masajes suaves, con aplicación de
                         calor húmedo.
                       </li>
                       <li>
                         Si la areola está hinchada y es difícil para el bebé tomar el pecho,
                         se debe extraer manualmente la leche hasta ablandar la zona areolar.
                       </li>
                       <li>De esta manera, el niño podrá mamar efectivamente.</li>
                       <li>Evitar el uso de corpiños ajustados o con tiras que aprietan.</li>
                       <li>
                         Relajarse y beber agua de acuerdo con la sed que tenga la mamá.
                       </li>
                     </ul>
                   )
                 },
                 {
                   title: 'Regreso al trabajo',
                   content: (
                     <React.Fragment>
                       <p>
                         Que lactancia y trabajo no son compatibles, es una idea equivocada.
                         Si querés continuar con la lactancia incluso en período laboral,
                         aquí te brindamos la información necesaria para continuar con el
                         amamantamiento. Tendrás que practicar la extracción de leche 15 días
                         antes.
                       </p>
                       <p>
                         Para empezar, deberás de determinar el método que vas a usar para
                         realizar la extracción. Puede ser manual, con un extractor manual o
                         eléctrico. La elección dependerá de cómo te sientas con cada una de
                         estas técnicas. Lo recomendable es que pruebes las que tengas a tu
                         alcance y evalúes cuál te resulta más cómoda. Deberás extraerte
                         leche después de cada mamada, toda la que puedas.
                       </p>
                       <p>
                         Tené presente que las primeras veces es probable que obtengas poca
                         cantidad, pero no te preocupes: a medida que estés haciéndolo,
                         estarás estimulando e irás mejorando los resultados.
                       </p>
                     </React.Fragment>
                   )
                 },
                 {
                   title: 'Manejo y conservación de la Leche Materna',
                   content: (
                     <ul>
                       <li>Lavate las manos con agua y jabón antes de cada extracción.</li>
                       <li>
                         Utilizá envases de vidrio o plástico con tapa, previamente
                         esterilizados.
                       </li>
                       <li>
                         La leche extraída se puede mantener en temperatura ambiente por 4
                         horas. La leche de diferentes extracciones, durante el mismo día,
                         puede mezclarse en el mismo envase hasta completar el volumen
                         requerido.
                       </li>
                       <li>
                         Si contás con heladera, dejala sobre la bandeja superior y no en la
                         puerta.
                       </li>
                       <li>
                         Congelá la leche extraída si no vas a usarla en las siguientes 48
                         horas.
                       </li>
                       <li>Etiquetá el envase con la fecha de la extracción.</li>
                       <li>
                         Sugerimos guardar la leche en envases de 60 y 120 cc. De esta
                         manera, dispondrás de la medida necesaria según la edad del tiempo y
                         duración de la leche materna luego de extraída:
                         <ul>
                           <li>
                             <strong>Temperatura ambiente:</strong> 4 horas.
                           </li>
                           <li>
                             <strong>Heladera:</strong> 24 horas.
                           </li>
                           <li>
                             <strong>Congelador:</strong> 15 días.
                           </li>
                           <li>
                             <strong>Freezer:</strong> 3 meses.
                           </li>
                         </ul>
                       </li>
                     </ul>
                   )
                 },
                 {
                   title: 'Para descongelar o calentar la leche materna',
                   content: (
                     <React.Fragment>
                       <ul>
                         <li>
                           Colocá el envase sin destapar, en un recipiente con agua tibia,
                           hasta lograr temperatura ambiente.
                         </li>
                         <li>Agitá para unir sus componentes.</li>
                         <li>
                           No calentar en horno microondas ni a fuego directo porque se
                           destruyen importantes componentes.
                         </li>
                         <li>No volver a congelar la leche materna.</li>
                         <li>
                           El resto, que el bebé no ingirió en la toma, debe ser desechado.
                         </li>
                       </ul>
                     </React.Fragment>
                   )
                 }
               ]
             }
           }
         ]
       ]
