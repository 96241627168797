import React from 'react'
import styled, { css } from 'styled-components'
import { CameraAlt } from 'styled-icons/material'

import { PrimaryButton } from './generics'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`

export const StyledInput = styled.input`
  align-self: center;
  padding: 10px;
  width: 80%;
  border: 1px solid ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`
const StyledSelect = styled.select`
  text-overflow: ellipsis;
  align-self: center;
  padding: 10px;
  padding-right: 24px;
  width: 80%;
  border: 1px solid ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  margin-bottom: 3%;
  appearance: none;
  ${props => css`
    background-image: ${`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24px' viewBox='0 0 24 24' fill='${props.theme.themeColors[
      props.theme.selected
    ].primaryColor.replace(
      '#',
      '%23'
    )}'%3E%3Cpath d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z' /%3E%3C/svg%3E")`};
  `}
  background-repeat: no-repeat;
  background-position: right;
  color: ${props => props.theme.colors.strongGray};
  background-color: ${props => props.theme.colors.white};
  text-overflow: ellipsis;
`
export const Select = props => <StyledSelect {...props} />

export const TextArea = styled.textarea`
  width: 80%;
  overflow-y: auto;
  color: ${props => props.theme.colors.black};
  min-height: 180px;
  padding: 2%;
  resize: none;
  border: 1px solid ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  margin-bottom: 3%;
`

export const SubmitButton = styled(PrimaryButton)`
  margin: 5% 0;
  width: 60%;
  padding: 10px;
  background-color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  color: ${props => props.theme.colors.white};
  border: none;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const FileInputLabel = styled.label`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  border: 2px solid #ffffff;
  &:hover {
    opacity: 1;
  }
  @media screen and (max-width: 800px) {
    opacity: 1;
    color: ${props => props.theme.colors.white};
  }
`

export const FileInputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  &:hover ${FileInputLabel} {
    opacity: 1;
  }
`
export const FileInputLabelIcon = styled(CameraAlt)`
  color: ${props => props.theme.colors.transparentGray};
`
export const FileInput = styled.input`
  display: none;
`
export const ErrFeedbackContainer = styled.div`
  width: 80%;
  color: ${props => props.theme.colors.styledRed};
`
