import { constants } from '../../constants/reduxActions'
import { MODAL_TYPES } from '../../constants/generics'

const {
  SHOW_MODAL,
  HIDE_MODAL,
  SET_MODAL_TYPE,
  SET_ESPECIALIDAD,
  SET_NOTAS_OF_MODAL,
  SET_DETALLE,
  SET_NOTA_DAY,
  SHOW_GALLERY,
  HIDE_GALLERY
} = constants

const initialState = {
  isModalOpen: false,
  modalType: MODAL_TYPES.PROFILE,
  turnoEspecialidad: '',
  detalleEmbarazo: null,
  notas: null,
  notaDay: null,
  errMessage: null,
  isGalleryOpen: false,
  galleryPhotos: []
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isModalOpen: true
      }
    case HIDE_MODAL:
      return {
        ...state,
        isModalOpen: false
      }
    case SET_MODAL_TYPE:
      return {
        ...state,
        modalType: action.payload
      }
    case SET_ESPECIALIDAD:
      return {
        ...state,
        turnoEspecialidad: action.payload
      }
    case SET_DETALLE:
      return {
        ...state,
        detalleEmbarazo: action.payload
      }
    case SET_NOTAS_OF_MODAL:
      return {
        ...state,
        notas: action.payload
      }
    case SET_NOTA_DAY:
      return {
        ...state,
        notaDay: action.payload
      }
    case SHOW_GALLERY:
      return {
        ...state,
        galleryPhotos: action.payload,
        isGalleryOpen: true
      }
    case HIDE_GALLERY:
      return {
        ...state,
        isGalleryOpen: false
      }
    default:
      return {
        ...state
      }
  }
}

export default modalReducer
