import * as yup from 'yup'

const profileSchema = yup.object().shape({
  especialidad: yup
    .string()
    .required('La especialidad es un campo requerido.')
    .test('especialidad-test', 'Elija una especialidad válida de la lista.', function test(value) {
      return this.options.context.especialidades.find(
        especialidad => especialidad.id === parseInt(value)
      )
    }),
  clinica: yup.string().required('Es necesario elegir una clínica.'),
  franjaHoraria: yup
    .string()
    .required()
    .matches(/(Mañana|Tarde|Noche)/),
  comentario: yup.string().max(128, 'El máximo permitido es de 128 caracteres.')
})

export default profileSchema
