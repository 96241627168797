import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Spinner = props => {
  return (
    <SpinnerSVG
      viewBox="0 0 50 50"
      fillColor={props.fillColor}
      spinnerWidth={props.spinnerWidth}
      spinnerHeight={props.spinnerHeight}
    >
      <circle cx="25" cy="25" r="20" strokeWidth="5"></circle>
    </SpinnerSVG>
  )
}

Spinner.propTypes = {
  fillColor: PropTypes.string,
  spinnerWidth: PropTypes.string,
  spinnerHeight: PropTypes.string
}

const SpinnerSVG = styled.svg`
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: ${props => props.spinnerWidth || '50px'};
  height: ${props => props.spinnerHeight || '50px'};

  & circle {
    stroke: ${props =>
      props.fillColor || props.theme.themeColors[props.theme.selected].primaryColor};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    fill: transparent;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`

export default Spinner
