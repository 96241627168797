import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { enviarMensajeAPI, obtenerHiloAPI } from '../../../api'
import { H2, H4 } from '../../../styled/generics'
import { StyledForm as Form, TextArea, SubmitButton } from '../../../styled/forms'

import { Scrollbars } from 'react-custom-scrollbars'

class HiloForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cuerpo: '',
      hilo: []
    }
  }

  componentDidMount() {
    //TODO get assigned pacientes to select from pop-up list
    //TODO get assigned medicos/PP to select from pop-up list
    const urlParams = {
      idUsuario: this.props.userId,
      idMensajeRaiz: this.props.idMail,
      esPapelera: this.props.esPapelera
    }
    if (this.props.isPapelera) {
      urlParams.esPapelera = true
    }
    obtenerHiloAPI(urlParams)
      .then(res => this.setState({ hilo: res.data }))
      .catch(console.log)
  }

  onChangeHandler = event => {
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }

  submitHandler = event => {
    event.preventDefault()
    const { cuerpo } = this.state
    const payload = {
      idUsuarioFrom: this.props.userId,
      idMensajeRaiz: this.props.idMail,
      asunto: this.state.hilo[0].asunto,
      cuerpo
    }
    if (this.props.isAdmin) {
      const mensaje = this.state.hilo.find(mensaje => mensaje.idMensajeRaiz === null)
      payload.idUsuarioTo = mensaje.idAutor
    }
    enviarMensajeAPI(payload)
      .then(res => {
        this.setState(prevState => {
          const newHilo = prevState.hilo.slice()
          newHilo.push({ cuerpo: prevState.cuerpo, idAutor: this.props.userId })
          return {
            ...prevState,
            hilo: newHilo,
            asunto: '',
            cuerpo: ''
          }
        })
      })
      .catch(console.log)
  }

  render() {
    return (
      <Container>
        <H2>{this.props.title}</H2>
        <Scrollbars
          thumbSize={100}
          autoHeight
          autoHide
          autoHeightMax="55vh"
          style={{ marginBottom: '20px' }}
        >
          <ChatContainer>
            {this.state.hilo.length ? (
              this.state.hilo.map((mensaje, index) => {
                const id1 = mensaje.idAutor.toLocaleLowerCase()
                const id2 = this.props.userId.toLocaleLowerCase()
                return id1.localeCompare(id2) === 0 ? (
                  <MensajeEnviado key={index}>
                    <strong>Vos</strong>
                    <CustomChatBubble esPropio>{mensaje.cuerpo}</CustomChatBubble>
                  </MensajeEnviado>
                ) : (
                  <MensajeRecibido key={index}>
                    <strong>
                      {this.props.isAdmin
                        ? `Paciente: ${mensaje.nombreAutor} ${mensaje.apellidoAutor}`
                        : 'Pregnancy Planner'}
                    </strong>
                    <CustomChatBubble>{mensaje.cuerpo}</CustomChatBubble>
                  </MensajeRecibido>
                )
              })
            ) : (
              <H4>No hay mensajes en el historial</H4>
            )}
          </ChatContainer>
        </Scrollbars>
        {!this.props.esPapelera && (
          <Form onSubmit={this.submitHandler}>
            <LabelRespuesta>
              <CustomTextArea
                name="cuerpo"
                value={this.state.cuerpo}
                onChange={this.onChangeHandler}
                placeholder={'Tu respuesta'}
              />
            </LabelRespuesta>
            <ButtonsContainer>
              <EnviarButton name="enviar">Enviar mensaje</EnviarButton>
            </ButtonsContainer>
          </Form>
        )}
      </Container>
    )
  }
}

const ChatContainer = styled.div`
  padding: 5px;
`
const Container = styled.div`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`

const Label = styled.label`
  width: 80%;
  margin-bottom: 2%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
const LabelRespuesta = styled(Label)`
  width: 100%;
`

const MensajeCommonCSS = css`
  width: 60%;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    width: 80%;
  }
`

const MensajeRecibido = styled.div`
  ${MensajeCommonCSS}
  margin-right: 40%;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-right: 20%;
  }
`
const MensajeEnviado = styled.div`
  ${MensajeCommonCSS}
  margin-left: 40%;
  @media screen and (max-width: ${props => props.theme.sizes.breakPoints.small}) {
    margin-left: 20%;
  }
`

const CustomTextArea = styled(TextArea)`
  width: 100%;
  max-height: 20vh;
  min-height: 9vh;
  overflow-y: auto;
`
const CustomChatBubble = styled.div`
  border-radius: 15px;
  height: auto;
  background-color: ${props =>
    props.esPropio ? props.theme.colors.white : props.theme.colors.lightGray};
  padding: 2%;
  border: 1px solid;
  margin-bottom: 3%;
  word-break: break-word;
  hyphens: auto;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 0 5% 0;
`
const commonButtonCSS = css`
  margin: 0;
  width: 45%;
`

const EnviarButton = styled(SubmitButton)`
  ${commonButtonCSS}
`

HiloForm.propTypes = {
  userId: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  idMail: PropTypes.number,
  esPapelera: PropTypes.bool
}

export default HiloForm
