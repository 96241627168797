import React, { Component } from 'react'
import styled, { css } from 'styled-components'

class ColorPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      default: this.props.default.hex
    }
  }

  onClickHandler = (colorName, colorHex) => {
    const theme = {
      name: colorName,
      hex: colorHex
    }
    this.setState({
      default: theme.hex
    })
    return this.props.onChange(this.props.name, theme)
  }

  render() {
    const iterableColors = Object.entries(this.props.colors)
    return (
      <StyledContainer>
        {iterableColors.map(colorEntry => (
          <StyledColorItem
            key={colorEntry[0]}
            onClick={
              this.state.default !== colorEntry[1].mainColor
                ? () => this.onClickHandler(colorEntry[0], colorEntry[1].mainColor)
                : null
            }
            backgroundColor={colorEntry[1].mainColor}
            isActive={this.state.default === colorEntry[1].mainColor}
          />
        ))}
      </StyledContainer>
    )
  }
}

const StyledColorItem = styled.li`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  margin-bottom: 1em;

  &:hover {
    border: 2.5px solid ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
  }
  ${props =>
    props.isActive &&
    css`
      border: 2.5px solid ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
    `}
`

const StyledContainer = styled.ul`
  display: inline-flex;
  margin: 0;
  padding: 0;
  width: 65%;
  justify-content: space-between;
  list-style-type: none;
  @media screen and (max-width: 800px) {
    width: 90%;
  }
`

export default ColorPicker
