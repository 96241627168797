import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'

import { dateParse, getInitialWeekNumber, weekNumberFromRealDateDay } from '../../utils/date'
import { getTurno } from '../../api'
import { setError } from '../../redux/actions/feedbackMessageActions'
import {
  weekCalc,
  getRegistros,
  getMiEmbarazo,
  setActiveDay,
  checkBedAppointment
} from '../../redux/actions/calendarActions'
import { getNotasOfWeek, setNotasLoader } from '../../redux/actions/notaActions'
import {
  setModalType,
  setEspecialidades,
  showModal,
  setDetalle,
  setNotasOfModal,
  setNotaDay
} from '../../redux/actions/modalActions'
import Calendar from './Calendar'
import MobileCalendar from './MobileCalendar'
import CarouselFetos from '../Calendar/CarouselFetos'
import { CLINICS_LIST, MODAL_TYPES } from '../../constants/generics'

class CalendarContainer extends Component {
  async componentDidMount() {
    const initialWeekNumber = getInitialWeekNumber(this.props.dateToBirth)
    this.props.weekCalc(initialWeekNumber)
    this.props.getRegistros()
    this.props.getMiEmbarazo()
    this.props.setNotasLoader()
    this.props.getNotasOfWeek()

    // TODO delete on demo purpose comments.
    // const todayDate = new Date('2019-07-19T02:59:40.510Z')
    this.changeActiveDayTimer(new Date())
    this.props.checkBedAppointment()
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.week.gottenNotas[this.props.week.number] &&
      this.props.week.number > 0 &&
      this.props.week.number !== prevProps.week.number
    ) {
      // Fetch notas of the week withoutShowing main loader.
      this.props.setNotasLoader()
      this.props.getNotasOfWeek()
    }
  }

  changeActiveDayTimer = dateObject => {
    const secondsToChangeActiveDay = 59 - dateObject.getSeconds()
    const minutesToChangeActiveDay = 59 - dateObject.getMinutes()
    const hoursToChangeActiveDay = 23 - dateObject.getHours()
    const millisecondsToChangeActiveDay =
      (secondsToChangeActiveDay +
        minutesToChangeActiveDay * 60 +
        hoursToChangeActiveDay * 60 * 60) *
      1000

    setTimeout(() => {
      const newDate = new Date(dateObject.toDateString())
      newDate.setDate(newDate.getDate() + 1)
      this.props.setActiveDay(newDate)
      setInterval(() => {
        newDate.setDate(newDate.getDate() + 1)
        this.props.setActiveDay(newDate)
      }, 24 * 60 * 60 * 1000)
    }, millisecondsToChangeActiveDay)
  }

  turnoClickHandler = (registroObject, usuarioId) => {
    if (!registroObject.requiereTurno) {
      const error = new Error()
      error.title = 'Solicitud de turno'
      error.message =
        'Este estudio no precisa que solicites turno, acercate directamente a la clínica que desees para realizarlo.'
      return this.props.setError(error)
    }
    getTurno({
      usuarioId: usuarioId,
      estudio: registroObject.id
    })
      .then(res => {
        if (res.statusText === 'OK' && !res.data) {
          this.props.setModalType(MODAL_TYPES.TURNO_ESTUDIO)
          this.props.setEspecialidades([registroObject])
          return this.props.showModal()
        }
        if (res.data) {
          // TODO use detalleForm to show turno data to the user.
          const err = new Error('Error al intentar pedir turno.')
          const { clinica, horario, fechaCreacion } = res.data
          err.message = `Pediste atenderte en la ${
            CLINICS_LIST[clinica].name
          } y elegiste la franja horaria de la ${horario}.\n(Turno solicitado el ${dateParse(
            fechaCreacion
          )})`
          err.title = `Ya dispones de un turno`
          this.props.setError(err)
          throw err
        }
      })
      .catch(console.log)
  }

  detalleClickHandler = eventoDelDia => {
    this.props.setModalType(MODAL_TYPES.EMBARAZO_DETALLE)
    this.props.setDetalle(eventoDelDia.descripcion)
    return this.props.showModal()
  }

  notasHandler = (notas, date) => {
    this.props.setModalType(MODAL_TYPES.NOTAS)
    this.props.setNotasOfModal(notas)
    this.props.setNotaDay(date)
    return this.props.showModal()
  }

  semanaShortcutCheck = () =>
    weekNumberFromRealDateDay(this.props.week.activeDay, this.props.dateToBirth) !==
    this.props.week.number

  render() {
    return (
      <Fragment>
        {/* CalendarModal if in future not using singleton instance */}
        <CarouselFetos />
        <MediaQuery query="(min-width: 800px)">
          {matches =>
            matches ? (
              <Calendar
                semanaToShortcut={weekNumberFromRealDateDay(
                  this.props.week.activeDay,
                  this.props.dateToBirth
                )}
                semanaShortcutActive={this.semanaShortcutCheck()}
                turnoClickHandler={this.turnoClickHandler}
                detalleClickHandler={this.detalleClickHandler}
                notasHandler={this.notasHandler}
                week={this.props.week}
              />
            ) : (
              <MobileCalendar
                semanaToShortcut={weekNumberFromRealDateDay(
                  this.props.week.activeDay,
                  this.props.dateToBirth
                )}
                semanaShortcutActive={this.semanaShortcutCheck()}
                turnoClickHandler={this.turnoClickHandler}
                detalleClickHandler={this.detalleClickHandler}
                notasHandler={this.notasHandler}
              />
            )
          }
        </MediaQuery>
      </Fragment>
    )
  }
}

const mapDispatchToProps = {
  weekCalc,
  getRegistros,
  getMiEmbarazo,
  setModalType,
  setEspecialidades,
  showModal,
  setDetalle,
  setNotasOfModal,
  setNotaDay,
  getNotasOfWeek,
  setNotasLoader,
  setError,
  setActiveDay,
  checkBedAppointment
}

const mapStateToProps = state => {
  return {
    usuarioId: state.authReducer.auth.usuarioId,
    dateToBirth: state.profileReducer.dateToBirth,
    week: state.calendarReducer.week
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarContainer)
