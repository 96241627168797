import COLORS from './colors'

const themeColors = {
  main: {
    mainColor: COLORS.fadedPink,
    primaryColor: COLORS.opaqueRed,
    secondaryColor: COLORS.roseFog,
    tertiaryColor: COLORS.styledRed,
    quaternaryColor: COLORS.opaquePink,
    quinaryColor: COLORS.vanillaIce,
    calendarHeaderBackground: COLORS.superLightPink,
    calendarHelperBackgroundDark: 'rgba(128, 45, 55, 0.15)', //opaqueRed with opacity
    calendarHelperBackgroundLight: 'rgba(128, 45, 55, 0.05)', //opaqueRed with opacity
    calendarRowItemBackground: 'rgba(229, 158, 164, 0.4)', //opaquePink with opacity
    calendarRowItemBackgroundLight: 'rgba(229, 158, 164, 0.2)', //opaquePink with opacity
    calendarDataActive: COLORS.opaquePink,
    calendarDesktopBorder: COLORS.wewak,
    calendarControl: COLORS.warmGrey,
    novedadContainerBackground: COLORS.opaquePink,
    serviciosBackground: 'rgba(255, 219, 224, 0.5)', //lightPink
    serviciosIcon: COLORS.softPink,
    bebeIconActive: COLORS.fadedPink,
    headerDayHover: COLORS.uglyPink,
    blogCardLabelBackground: COLORS.lightOpaqueRed,
    blogCardLabelBackgroundActive: COLORS.tinylightOpaqueRed
  },
  orangeYellow: {
    mainColor: COLORS.orangeYellow,
    primaryColor: COLORS.greenBrown,
    secondaryColor: COLORS.lightGold,
    tertiaryColor: COLORS.poo,
    quaternaryColor: COLORS.yellowOchre,
    quinaryColor: COLORS.palePeach,
    calendarControl: COLORS.warmGrey,
    serviciosBackground: 'rgba(251, 204, 97, 0.5)', //lightGold
    serviciosIcon: COLORS.lightYellow,
    bebeIconActive: '#FFF473',
    calendarDesktopBorder: COLORS.lightYellow,
    calendarHeaderBackground: '#f8e71e61',
    calendarRowItemBackground: 'rgba(222, 154, 0, 0.4)', //yellowOchre
    calendarRowItemBackgroundLight: 'rgba(222, 154, 0, 0.2)', //yellowOchre
    calendarDataActive: COLORS.yellowOchre,
    calendarHelperBackgroundLight: 'rgba(87, 61, 2, 0.05)', //greenBrown
    calendarHelperBackgroundDark: 'rgba(87, 61, 2, 0.15)', //greenBrown
    headerDayHover: COLORS.orangeYellow,
    novedadContainerBackground: COLORS.yellowOchre,
    blogCardLabelBackground: 'rgba(87, 61, 2, 0.5)', //greenBrown
    blogCardLabelBackgroundActive: 'rgba(87, 61, 2, 0.9)' //greenBrown
  },
  springGreen: {
    mainColor: COLORS.springGreen,
    primaryColor: COLORS.darkOliveGreen,
    secondaryColor: COLORS.washedOutGreen,
    tertiaryColor: COLORS.darkGrassGreen,
    quaternaryColor: COLORS.appleGreen,
    quinaryColor: COLORS.lightSage,
    calendarControl: COLORS.warmGrey,
    serviciosBackground: 'rgba(192, 239, 143, 0.5)', //washedOutGreen
    serviciosIcon: COLORS.lightGreen,
    bebeIconActive: COLORS.lightGreen,
    calendarDesktopBorder: COLORS.lightGreen,
    calendarHeaderBackground: '#d1f2af',
    calendarRowItemBackground: 'RGBA(109,184,32,0.4)', //appleGreen
    calendarRowItemBackgroundLight: 'RGBA(109,184,32,0.2)', //appleGreen
    calendarDataActive: COLORS.appleGreen,
    calendarHelperBackgroundLight: 'RGBA(42,82,2,0.05)', //darkOliveGreen
    calendarHelperBackgroundDark: 'RGBA(42,82,2,0.15)', //darkOliveGreen
    headerDayHover: COLORS.darkGrassGreen,
    novedadContainerBackground: COLORS.appleGreen,
    blogCardLabelBackground: 'rgba(42, 82, 2, 0.5)', //darkOliveGreen
    blogCardLabelBackgroundActive: 'rgba(42, 82, 2, 0.9)' //darkOliveGreen
  },
  darkSkyBlue: {
    mainColor: COLORS.darkSkyBlue,
    primaryColor: COLORS.navy,
    secondaryColor: COLORS.lightBlueTwo,
    tertiaryColor: COLORS.petrol,
    quaternaryColor: COLORS.niceBlue,
    quinaryColor: COLORS.duckEggBlue,
    calendarControl: COLORS.warmGrey,
    serviciosBackground: 'rgba(145, 216, 241, 0.5)', //lightBlueTwo
    serviciosIcon: COLORS.lightBlue,
    bebeIconActive: '#75c4e2',
    calendarDesktopBorder: COLORS.lightBlue,
    calendarHeaderBackground: '#d1f0fc',
    calendarRowItemBackground: '#75c4e2',
    calendarRowItemBackgroundLight: '#d1f0fc',
    calendarDataActive: COLORS.niceBlue,
    calendarHelperBackgroundLight: '#d1f0fc',
    calendarHelperBackgroundDark: '#75c4e2',
    headerDayHover: COLORS.petrol,
    novedadContainerBackground: COLORS.niceBlue,
    blogCardLabelBackground: 'rgba(1, 44, 59, 0.5)', //navy
    blogCardLabelBackgroundActive: 'rgba(1, 44, 59, 0.9)' //navy
  },
  lile: {
    mainColor: COLORS.lile,
    primaryColor: COLORS.midnightPurple,
    secondaryColor: COLORS.lavender,
    tertiaryColor: COLORS.indigo,
    quaternaryColor: COLORS.blueyPurple,
    quinaryColor: COLORS.paleLavender,
    calendarControl: COLORS.warmGrey,
    serviciosBackground: 'rgba(194, 170, 235, 0.5)', //lavender
    serviciosIcon: COLORS.lile,
    bebeIconActive: '#9767ef',
    calendarDesktopBorder: COLORS.lile,
    calendarHeaderBackground: '#d1bafc',
    calendarRowItemBackground: '#9767ef',
    calendarRowItemBackgroundLight: '#d1bafc',
    calendarDataActive: COLORS.blueyPurple,
    calendarHelperBackgroundLight: '#d1bafc',
    calendarHelperBackgroundDark: '#9767ef',
    headerDayHover: COLORS.indigo,
    novedadContainerBackground: COLORS.blueyPurple,
    blogCardLabelBackground: 'rgba(25, 1, 69, 0.5)', //midnightPurple
    blogCardLabelBackgroundActive: 'rgba(25, 1, 69, 0.9)' //midnightPurple
  }
}

export default themeColors
