import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import ModalContainer from './ModalContainer'
import { hideModal } from '../../redux/actions/modalActions'
import { resetFeedbackMessage } from '../../redux/actions/feedbackMessageActions'

class Backdrop extends Component {
  componentDidMount() {
    disableBodyScroll()
  }

  componentWillUnmount() {
    if (!this.props.isNested) {
      clearAllBodyScrollLocks()
    }
  }

  onCloseHandler = event => {
    event.stopPropagation()
    if (this.props.isCloseable) {
      return this.props.isFeedbackMessage
        ? this.props.resetFeedbackMessage()
        : this.props.hideModal()
    }
  }

  render() {
    return (
      <StyledBackdrop
        isNewUser={this.props.isNewUser}
        isFeedbackMessage={this.props.isFeedbackMessage}
      >
        <ModalContainer
          isFeedbackMessage={this.props.isFeedbackMessage}
          isCloseable={this.props.isCloseable}
          closeHandler={this.props.customCloseHandler || this.onCloseHandler}
        >
          {this.props.children}
        </ModalContainer>
      </StyledBackdrop>
    )
  }
}

/**
 * Styles
 */
const StyledBackdrop = styled.div`
  z-index: ${props => (props.isFeedbackMessage ? 1000 : 999)};
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding-top: 10px;
  background-color: ${props => (props.isNewUser ? 'gray' : 'rgba(19, 17, 17, 0.42)')};
  top: 0;
  left: 0;
  @media screen and (max-width: 800px) {
    ${props =>
      !props.isFeedbackMessage &&
      css`
        padding-top: 0;
      `}
  }
`

const mapStateToProps = state => {
  return {
    isModalOpen: state.modalReducer.isModalOpen
  }
}

const mapDispatchToProps = {
  hideModal,
  resetFeedbackMessage
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Backdrop)
