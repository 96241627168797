import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { H3 } from '../../styled/generics'

const FeedbackMessage = props => (
  <Container>
    <StyledH3 isError={props.isError}>{props.title}</StyledH3>
    <Message isError={props.isError}>{props.message}</Message>
  </Container>
)

const StyledH3 = styled(H3)`
  color: ${props => (props.isError ? props.theme.colors.styledRed : props.theme.colors.fernGreen)};
`

const Message = styled.p`
  text-align: center;
  white-space: pre-line;
  padding: 2%;
  border: 2px solid
    ${props => (props.isError ? props.theme.colors.styledRed : props.theme.colors.fernGreen)};
  border-radius: 10px;
  background-color: ${props =>
    props.isError ? props.theme.colors.lightRed : props.theme.colors.chateauGreen};
  color: ${props => !props.isError && props.theme.colors.white};
`
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.red};
`
FeedbackMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isError: PropTypes.bool
}

export default FeedbackMessage
