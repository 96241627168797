import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { KeyboardArrowDown, ViewCarousel, Edit } from 'styled-icons/material'
import { TrashAlt } from 'styled-icons/fa-solid/TrashAlt'

import { ACTION_ICON_SIZE } from '../../../constants/generics'

import { TextArea } from '../../../styled/forms'
import { H5, H4 } from '../../../styled/generics'

class NotaItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props.nota.titulo,
      content: this.props.nota.texto
    }
  }

  removeHandler = () => this.props.removeHandler(this.props.nota)
  onChangeHandler = event => this.setState({ [event.target.name]: event.target.value })
  collapseHandler = () =>
    this.props.isCollapsed
      ? this.props.collapseHandler(this.props.nota.id)
      : this.props.collapseHandler(null)

  render() {
    return (
      <li>
        <ItemHeader>
          {this.props.isCollapsed && <StyledTrash size="1em" onClick={this.removeHandler} />}
          <H4>{this.state.title}</H4>
          <StyledArrow
            isCollapsed={this.props.isCollapsed}
            size={ACTION_ICON_SIZE}
            onClick={this.collapseHandler}
          />
        </ItemHeader>
        <Collapsable ref={this.props.ref} isCollapsed={this.props.isCollapsed}>
          <H5>Contenido de la nota</H5>
          <StyledTextArea
            disabled
            isCollapsed={this.props.isCollapsed}
            name="content"
            value={this.state.content}
            onChange={this.onChangeHandler}
          />
          <ActionIconsContainer>
            <StyledEdit
              onClick={() => this.props.editHandler(this.props.nota.id)}
              size={ACTION_ICON_SIZE}
            />
            {!!this.props.nota.photos && !!this.props.nota.photos.length && (
              <StyledCarousel onClick={this.props.showGallery} size={ACTION_ICON_SIZE} />
            )}
          </ActionIconsContainer>
        </Collapsable>
      </li>
    )
  }
}

const StyledArrow = styled(KeyboardArrowDown)`
  transition: transform 0.6s ease;
  cursor: pointer;
  ${props =>
    !props.isCollapsed &&
    css`
      transform: rotateZ(-180deg);
    `}
`

const ActionIconsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: -2%;
  & svg {
    margin-left: 0.5em;
    cursor: pointer;
  }
`

const Collapsable = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  ${props =>
    props.isCollapsed &&
    css`
      height: 0;
      opacity: 0;
      width: 0;
    `}
  ${props =>
    !props.isCollapsed &&
    css`
      opacity: 1;
      width: 95%;
      height: 25vh;
    `}
`

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledTextArea = styled(TextArea)`
  width: 0;
  ${props =>
    !props.isCollapsed &&
    css`
      width: 100%;
    `}
  min-height: 55%;
`

const actionIconsCSS = css`
  &:hover {
    transform: scale(1.2);
  }
`
const StyledTrash = styled(TrashAlt)`
  cursor: pointer;
  z-index: 99;
  ${actionIconsCSS}
`
const StyledEdit = styled(Edit)`
  ${actionIconsCSS}
`
const StyledCarousel = styled(ViewCarousel)`
  ${actionIconsCSS}
`
export default NotaItem
