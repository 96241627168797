import React from 'react'
import styled, { css } from 'styled-components'
import { Close } from 'styled-icons/material'

import { ACTION_ICON_SIZE } from '../../constants/generics'

const ModalContainer = props => {
  return (
    <Container isFeedbackMessage={props.isFeedbackMessage}>
      {props.isCloseable && (
        <ModalDismissContainer>
          <DismissIcon onClick={props.closeHandler} size={ACTION_ICON_SIZE} />
        </ModalDismissContainer>
      )}
      {props.children}
    </Container>
  )
}

const Container = styled.div`
  color: ${props => props.theme.colors.opaqueRed};
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  width: 40vw;
  min-height: 20vh;
  max-width: 500px;

  @media (max-width: 800px) {
    width: 75vw;
    left: 13vw;
    ${props =>
      !props.isFeedbackMessage &&
      css`
        width: 100vw;
        min-height: 100vh;
        left: 0;
        top: 0;
      `}
  }
`

const ModalDismissContainer = styled.div`
  align-self: flex-end;
  border-radius: 50%;
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${props => props.theme.colors.transparentGray};
  }
`
const DismissIcon = styled(Close)`
  padding: 2px;
  cursor: pointer;
  color: ${props => props.theme.colors.red};
  transition: transform 0.1s ease;
  &:hover {
    transform: scale(1.1);
  }
`

export default ModalContainer
