import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { withTheme } from 'styled-components'

import { WEEKS_INTERLEAVE } from '../../constants/generics'
import { weekCalc } from '../../redux/actions/calendarActions'
import fetos from '../../styled/fetos'
import IconCarousel from '../Icons/IconCarousel'

const INITIAL_OFFSET = 47
const OFFSET = 6

class CarouselFetos extends Component {
  constructor(props) {
    super(props)
    this.state = {
      iterableFetos: Object.entries(fetos),
      activeItem: this.props.weekNumber,
      actualTransform:
        INITIAL_OFFSET - OFFSET * Math.floor(this.props.weekNumber / WEEKS_INTERLEAVE)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.weekNumber !== prevProps.weekNumber) {
      let activeItem = this.props.weekNumber
      if (activeItem % WEEKS_INTERLEAVE !== 0) {
        const pivotOffset = this.props.weekNumber - WEEKS_INTERLEAVE
        for (let i = 1; i <= WEEKS_INTERLEAVE - 1; i++) {
          activeItem = (pivotOffset + i) % WEEKS_INTERLEAVE === 0 ? pivotOffset + i : activeItem
        }
      }
      this.setState({
        actualTransform:
          INITIAL_OFFSET - OFFSET * Math.floor(this.props.weekNumber / WEEKS_INTERLEAVE),
        activeItem
      })
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      (nextProps.weekNumber < this.state.activeItem ||
        nextProps.weekNumber > this.state.activeItem + (WEEKS_INTERLEAVE - 1)) &&
      nextProps.weekNumber <= this.state.iterableFetos.length * WEEKS_INTERLEAVE - 1 // Temp because of lack of icons
    )
  }

  onClickHandler = event => {
    event.stopPropagation()
    const nextWeek = parseInt(event.target.id)
    this.props.weekCalc(nextWeek)
  }

  render() {
    return (
      <CarouselContainer>
        <H3>Mes a mes</H3>
        <IconsList actualTransform={this.state.actualTransform}>
          {this.state.iterableFetos.map(([key, value], index) => (
            <IconsListItem
              key={key}
              isActive={
                index * WEEKS_INTERLEAVE + (WEEKS_INTERLEAVE - 1) - this.props.weekNumber >= 0 &&
                index * WEEKS_INTERLEAVE + (WEEKS_INTERLEAVE - 1) - this.props.weekNumber <
                  WEEKS_INTERLEAVE
              }
            >
              <IconCarousel
                id={index * WEEKS_INTERLEAVE}
                isActive={
                  index * WEEKS_INTERLEAVE + (WEEKS_INTERLEAVE - 1) - this.props.weekNumber >= 0 &&
                  index * WEEKS_INTERLEAVE + (WEEKS_INTERLEAVE - 1) - this.props.weekNumber <
                    WEEKS_INTERLEAVE
                }
                clickHandler={this.onClickHandler}
                feto={value.d}
                width={value.width}
                height={value.height}
                viewBox={value.viewBox}
              />
            </IconsListItem>
          ))}
        </IconsList>
      </CarouselContainer>
    )
  }
}

const CarouselContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  width: 90%;
  overflow-x: hidden;

  @media screen and (max-width: 800px) {
    width: 315vw;
  }
`

const H3 = styled.h3`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`
const IconsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  transform: translateX(${props => props.actualTransform}%);
  transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
  min-height: 100px;
`

const IconsListItem = styled.li`
  min-width: 6%;
  display: flex;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  border-bottom: 0px;
  ${props =>
    props.isActive &&
    css`
      border-bottom: 15px solid
        ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
    `}
  transition: border-width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`

const mapStateToProps = state => {
  return {
    weekNumber: state.calendarReducer.week.number
  }
}

const mapDispatchToProps = {
  weekCalc
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CarouselFetos)
)
