import { constants } from '../../constants/reduxActions'
const {
  LOGIN_ADMIN,
  LOGIN_PACIENTE,
  REMOVE_NEW_USER,
  SET_NEW_USER,
  SET_TOKEN,
  REDIRECT_LOGOUT,
  LOGOUT
} = constants

const initialState = {
  auth: {
    isAuth: null,
    token: null,
    usuarioId: null,
    isNewUser: false,
    didLogout: false
  }
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_PACIENTE:
      return {
        auth: {
          ...state.auth,
          isAuth: 'paciente',
          usuarioId: action.payload.usuarioId,
          token: action.payload.token
        }
      }
    case LOGIN_ADMIN:
      return {
        auth: {
          ...state.auth,
          isAuth: 'admin',
          usuarioId: action.payload.usuarioId,
          token: action.payload.token
        }
      }
    case REMOVE_NEW_USER:
      return {
        auth: {
          ...state.auth,
          isNewUser: false
        }
      }
    case SET_NEW_USER:
      return {
        auth: {
          ...state.auth,
          isNewUser: true
        }
      }
    case SET_TOKEN:
      return {
        auth: {
          ...state.auth,
          token: action.payload
        }
      }
    case LOGOUT:
      return {
        auth: {
          ...state.auth,
          didLogout: true,
          isAuth: false
        }
      }
    case REDIRECT_LOGOUT:
      return {
        auth: {
          ...state.auth,
          isAuth: false
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default authReducer
