import { flashSuccesMessage } from './feedbackMessageActions'
import { reservarTurnoEstudioAPI, reservarTurnoEspecialidadAPI } from '../../api'
import { constants } from '../../constants/reduxActions'
import { setHasBedAppointment } from './calendarActions'
import { MODAL_TYPES } from '../../constants/generics'
const {
  SHOW_MODAL,
  HIDE_MODAL,
  SET_ESPECIALIDAD,
  SET_MODAL_TYPE,
  SET_DETALLE,
  SET_NOTAS_OF_MODAL,
  SET_NOTA_DAY,
  SHOW_GALLERY,
  HIDE_GALLERY
} = constants

/**
 * Hide modal component
 */
export const hideModal = () => {
  return {
    type: HIDE_MODAL
  }
}

/**
 * Show modal component
 */
export const showModal = () => {
  return {
    type: SHOW_MODAL
  }
}

/**
 * Set modal type
 */
export const setModalType = payload => {
  return {
    type: SET_MODAL_TYPE,
    payload
  }
}

/**
 * Set especialidad
 */
export const setEspecialidades = payload => {
  return {
    type: SET_ESPECIALIDAD,
    payload
  }
}

/**
 * Set detalle
 */
export const setDetalle = payload => {
  return {
    type: SET_DETALLE,
    payload
  }
}

/**
 * Set notas
 */
export const setNotasOfModal = payload => {
  return {
    type: SET_NOTAS_OF_MODAL,
    payload
  }
}

/**
 * Set nota day
 */
export const setNotaDay = payload => {
  return {
    type: SET_NOTA_DAY,
    payload
  }
}

/**
 * Set nota day
 */
export const showGallery = payload => {
  return {
    type: SHOW_GALLERY,
    payload
  }
}

/**
 * Hide gallery
 */
export const hideGallery = () => {
  return {
    type: HIDE_GALLERY
  }
}

/**
 * Reservar turno por estudio o especialidad
 */
export const reservaDeTurno = payload => (dispatch, getState) => {
  const isEstudio = getState().modalReducer.modalType === MODAL_TYPES.TURNO_ESTUDIO
  if (isEstudio) {
    return reservarTurnoEstudioAPI(payload)
      .then(() => {
        dispatch(hideModal())
        dispatch(
          flashSuccesMessage({
            title: 'Reserva',
            message: 'Solicitud de reserva realizada con éxito!'
          })
        )
      })
      .catch(console.log)
  }
  reservarTurnoEspecialidadAPI(payload)
    .then(() => {
      if (getState().modalReducer.modalType === MODAL_TYPES.RESERVA_CAMA) {
        dispatch(setHasBedAppointment(true))
      }
      dispatch(hideModal())
      dispatch(
        flashSuccesMessage({
          title: 'Reserva',
          message: 'Solicitud de reserva realizada con éxito!'
        })
      )
    })
    .catch(console.log)
}
