import * as yup from 'yup'

const mailSchema = yup.object().shape({
  cuerpo: yup
    .string()
    .max(1024, 'El máximo permitido es de 1024 caracteres.')
    .min(3, 'El campo debe contener al menos 3 caracteres alfabéticos.')
})

export default mailSchema
