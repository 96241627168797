import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import styled, { ThemeProvider } from 'styled-components'

import theme from './styled/theme'
import Routing from './components/Routes/routes'
import Loader from './components/Loader/Loader'
import Navbar from './components/Layout/Navbar'
import ScrollTop from './utils/scroll-to-top'
import Modal from './components/Modal/Backdrop'
import ProfileForm from './components/Forms/ProfileForm'
import TurnoForm from './components/Forms/TurnoForm'
import ConsultaForm from './components/Forms/ConsultaForm'
import NotasList from './components/Forms/Notas/NotasList'
import DetalleEmbarazo from './components/Forms/DetalleEmbarazo'
import FeedbackMessage from './components/Feedback/FeedbackMessage'
import NotasGallery from './components/Layout/NotasGallery'
import ErrorBoundary from './components/Feedback/ErrorBoundary'

import { MODAL_TYPES } from './constants/generics'

const {
  NOTAS,
  PROFILE,
  CONSULTA,
  CONSULTA_ESPECIALISTA,
  EMBARAZO_DETALLE,
  TURNO_ESPECIALIDAD,
  TURNO_ESTUDIO,
  RESERVA_CAMA
} = MODAL_TYPES

const App = props => {
  return (
    <ThemeProvider theme={{ ...theme, selected: props.selectedTheme }}>
      <Router>
        <ErrorBoundary>
          <Loader />
          <Container>
            {props.isAuth && <Navbar />}
            {props.messageShown && (
              <Modal isCloseable isFeedbackMessage>
                <FeedbackMessage
                  isError={!!props.error}
                  title={props.error ? props.error.title : props.success.title}
                  message={props.error ? props.error.message : props.success.message}
                />
              </Modal>
            )}
            {props.isGalleryOpen && <NotasGallery />}
            {(props.isModalOpen || (props.isNewUser && props.isAuth)) && (
              <Modal isNewUser={props.isNewUser} isCloseable={!props.isNewUser}>
                {props.modalType === NOTAS && <NotasList />}
                {props.modalType === PROFILE && <ProfileForm />}
                {props.modalType === TURNO_ESTUDIO && (
                  <TurnoForm isEstudioForm title="Pedir turno" />
                )}
                {props.modalType === RESERVA_CAMA && (
                  <TurnoForm isReservaCama title="Reserva de cama" />
                )}
                {props.modalType === TURNO_ESPECIALIDAD && <TurnoForm title="Pedir turno" />}
                {props.modalType === EMBARAZO_DETALLE && <DetalleEmbarazo />}
                {props.modalType === CONSULTA && (
                  <ConsultaForm title="Consulta con tu pregnancy planner" />
                )}
                {props.modalType === CONSULTA_ESPECIALISTA && (
                  <ConsultaForm isEspecialidad title="Consultá a un especialista" />
                )}
              </Modal>
            )}
            <ScrollTop>
              <Routing />
            </ScrollTop>
          </Container>
        </ErrorBoundary>
      </Router>
    </ThemeProvider>
  )
}

const Container = styled.div`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`

const mapStateToProps = state => {
  const { isNewUser, isAuth } = state.authReducer.auth
  const { isModalOpen, modalType, turnoEspecialidad, isGalleryOpen } = state.modalReducer
  const { messageShown, error, success } = state.feedbackMessageReducer
  const selectedTheme = state.profileReducer.color.name
  return {
    isNewUser,
    isAuth,
    isModalOpen,
    modalType,
    turnoEspecialidad,
    messageShown,
    error,
    success,
    isGalleryOpen,
    selectedTheme
  }
}

export default connect(mapStateToProps)(App)
