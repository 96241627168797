export const NOTA_FILE_TYPE = {
  FILE: 'FILE',
  PHOTO: 'PHOTO'
}

export const MODAL_TYPES = {
  NOTAS: 'NOTAS',
  PROFILE: 'PROFILE',
  TURNO_ESTUDIO: 'TURNO_ESTUDIO',
  TURNO_ESPECIALIDAD: 'TURNO_ESPECIALIDAD',
  EMBARAZO_DETALLE: 'EMBARAZO_DETALLE',
  CONSULTA: 'CONSULTA',
  CONSULTA_ESPECIALISTA: 'CONSULTA_ESPECIALISTA',
  RESERVA_CAMA: 'RESERVA_CAMA'
}

export const MAIL_CATEGORIES = {
  ENVIADOS: 'Salida',
  BORRADORES: 'Borrador',
  ELIMINADOS: 'Papelera',
  RECIBIDOS: 'Entrada',
  DESTACADOS: 'DESTACADOS'
}

export const PORTADA_RATIO = 3.81
export const AVATAR_RATIO = 1

export const MAIL_NOTIFICATION_INTERVAL = 300000
export const APPOINMENT_DAYS_PROXIMITY = 30
export const RESERVA_CAMA_DAYS_PROXIMITY = 30
export const NUMBER_OF_WEEKS = 87 //Begins from 0
export const WEEKS_INTERLEAVE = 4 //For carousel of baby aspect.
export const ID_RESERVA_CAMA = 1

export const PORTAL_CLINICA_URL = 'https://google.com.ar'

export const CLINICS_LIST = {
  1: { name: 'Clínica Santa Isabel', url: 'http://www.santaisabel.com.ar/santaisabel/' },
  3: { name: 'Clínica Del Sol', url: 'http://www.cdelsol.com.ar/dnn_clsol/' },
  2: { name: 'Clínica Bazterrica', url: 'http://www.bazterrica.com.ar/bazterrica/' },
  4: {
    name: 'Centro Médico Bazterrica',
    url: 'http://www.cmbazterrica.com.ar/cmbazterrica/'
  }
}

export const PERSONAL_DATA_TEXT =
  'El titular de los datos personales no se encuentra obligado a proporcionar datos sensibles. Asimismo, el titular se responsabiliza por la exactitud de los datos proporcionados. El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismo en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley N° 25326.\n La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, Órgano de control de la Ley N° 25326, tiene la atricución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. www.sssalud.gov.ar, 0800-222-72583. RNEMP: 1336.'

export const INITIAL_WEEK_NUMBER = 0
export const CANTIDAD_DIAS_NOTAS = 616

export const FILE_TYPES = {
  avatar: 1,
  portada: 2
}

export const CONTROL_ICONS_SIZES = {
  desktop: '3.5em'
}

export const HELPER_ICONS_SIZES = {
  desktop: '2em'
}
export const ACTION_ICON_SIZE = '1.5em'

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]
