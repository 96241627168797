import {
    constants
} from '../../constants/reduxActions';
const {
    SHOW_LOADER,
    HIDE_LOADER
} = constants;

/**
 * Show loader
 */
const showLoader = () => {
    return {
        type: SHOW_LOADER
    }
}

/**
 * Hide loader
 */
const hideLoader = () => {
    return {
        type: HIDE_LOADER
    }
}

export {
    showLoader,
    hideLoader
};