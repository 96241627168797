export const constants = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  LOGIN_ADMIN: 'LOGIN_ADMIN',
  LOGIN_PACIENTE: 'LOGIN_PACIENTE',
  LOGOUT: 'LOGOUT',
  REDIRECT_LOGOUT: 'REDIRECT_LOGOUT',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  SET_PROFILE: 'SET_PROFILE',
  SET_PHOTOS: 'SET_PHOTOS',
  SET_PORTADA: 'SET_PORTADA',
  SET_AVATAR: 'SET_AVATAR',
  SET_DETALLE: 'SET_DETALLE',
  SET_NOTAS: 'SET_NOTAS',
  SET_NOTAS_OF_WEEK: 'SET_NOTAS_OF_WEEK',
  SET_NOTAS_OF_MODAL: 'SET_NOTAS_OF_MODAL',
  SET_GOTTEN_NOTAS: 'SET_GOTTEN_NOTAS',
  SET_NOTAS_LOADER: 'SET_NOTAS_LOADER',
  REMOVE_NOTAS_LOADER: 'REMOVE_NOTAS_LOADER',
  REMOVE_NEW_USER: 'REMOVE_NEW_USER',
  SET_NEW_USER: 'SET_NEW_USER',
  SET_WEEK: 'SET_WEEK',
  SET_DAY: 'SET_DAY',
  SET_CALENDAR_DATA: 'SET_CALENDAR_DATA',
  GET_CALENDAR_DATA: 'GET_CALENDAR_DATA',
  SET_MODAL_TYPE: 'SET_MODAL_TYPE',
  SET_ESPECIALIDAD: 'SET_ESPECIALIDAD',
  SET_ERROR: 'SET_ERROR',
  RESET_FEEDBACK: 'RESET_FEEDBACK',
  SET_NOTA_DAY: 'SET_NOTA_DAY',
  SHOW_GALLERY: 'SHOW_GALLERY',
  HIDE_GALLERY: 'HIDE_GALLERY',
  SET_TOKEN: 'SET_TOKEN',
  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
  SET_HAS_BED_APPOINTMENT: 'SET_HAS_BED_APPOINTMENT',
  SET_ACTIVE_DAY: 'SET_ACTIVE_DAY'
}
