import React from 'react'
import styled from 'styled-components'

const RadioInput = props => {
  return (
    <StyledContainer>
      <StyledLabel>
        {props.labelName}
        <StyledInput
          onChange={props.onChange}
          defaultChecked={props.isChecked ? 'checked' : ''}
          type="radio"
          name={props.name}
          value={props.value}
        />
        <Checkmark />
      </StyledLabel>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  /* The container */
  display: block;
  position: relative;
  padding-left: 23px;
  height: 18px;
  margin-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const StyledLabel = styled.label`
  cursor: pointer;
  /* On mouse-over, add a pink background color to checkmark */
  &:hover input ~ span {
    background-color: ${props => props.theme.colors.transparentGray};
  }
`

const Checkmark = styled.span`
  /* Create a custom radio button */
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: ${props => props.theme.colors.lightGray};
  border-radius: 50%;
  border: 2px solid;

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Style the indicator (dot/circle) */
  &:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  }
`

const StyledInput = styled.input`
  /* Hide the browser's default radio button */
  position: absolute;
  opacity: 0;
  cursor: pointer;

  /* When the radio button is checked, add a white background */
  &:checked ~ span {
    background-color: white;
    border: 2px solid;
  }

  /* Show the indicator (dot/circle) when checked */
  &:checked ~ span:after {
    display: block;
  }
`

export default RadioInput
