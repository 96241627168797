import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { DateRange } from 'styled-icons/material'
import DatePicker from 'react-date-picker'
import * as yup from 'yup'

import {
  StyledForm,
  StyledInput,
  SubmitButton,
  FileInputContainer,
  FileInput,
  FileInputLabel,
  FileInputLabelIcon,
  ErrFeedbackContainer,
  Select,
} from '../../styled/forms'
import {
  H2,
  H3,
  H5,
  ImgPortada,
  ImgAvatar,
  PrimaryButton,
  SecondaryButton
} from '../../styled/generics'
import Modal from '../Modal/Backdrop'
import ImageCropper from '../Cropper/ImageCropper'
import ColorPicker from './ColorPicker'

import validationSchema from '../../validation/profileSchema'
import { setProfileData, setPortada, setAvatar } from '../../redux/actions/profileActions'
import { removeisNewUser } from '../../redux/actions/authActions'
import { hideModal } from '../../redux/actions/modalActions'

import { FILE_TYPES, PORTADA_RATIO, AVATAR_RATIO, ACTION_ICON_SIZE } from '../../constants/generics'
import clinics from '../../constants/clinics'
import THEME_COLORS from '../../styled/theme-colors'
import AVATAR_PLACEHOLDER from '../../img/profile/avatar_placeholder.jpg'
import PORTADA_PLACEHOLDER from '../../img/profile/header_placeholder.png'

const WEEKS_FOR_VALIDATION = 40
const DAYS_FOR_VALIDATION = 7

class ProfileForm extends Component {
  constructor(props) {
    super(props)

    const { name, dateToBirth, doctor = '', color, portada, clinic = '', avatar } = this.props

    this.maxDateToSelect = new Date()
    this.maxDateToSelect.setDate(
      this.maxDateToSelect.getDate() + WEEKS_FOR_VALIDATION * DAYS_FOR_VALIDATION
    )

    this.state = {
      // Form State
      name,
      doctor,
      portada,
      avatar,
      clinic,
      didImageChange: {
        portada: false,
        avatar: false
      },
      dateToBirth: !dateToBirth ? new Date() : new Date(dateToBirth),
      colorTheme: color || { name: 'main', hex: THEME_COLORS.main.mainColor },
      errors: {},
      // Crop handling
      isPhotoEditorOpen: false,
      editingPhoto: null,
      cropperRef: React.createRef()
    }
  }

  imageChangeHandler = event => {
    const { name, files } = event.target
    if (!files.length) {
      return
    }
    const file = files[0]
    return yup
      .reach(validationSchema, name)
      .validate(file)
      .then(result => {
        this.setState(prevState => ({
          ...prevState,
          [name]: { file, url: URL.createObjectURL(file) },
          didImageChange: { ...prevState.didImageChange, [name]: true }
        }))
      })
      .catch(err => alert(err.message))
  }

  setPhotoToCrop = event => {
    event.persist()
    if (!event.target.files.length) {
      return
    }
    const { name, files } = event.target
    const payload = files[0]
    yup
      .reach(validationSchema, name)
      .validate(payload)
      .then(result => {
        this.setState({ isPhotoEditorOpen: true, editingPhoto: { [name]: payload } })
        event.target.value = null
      })
      .catch(err => {
        event.target.value = null
        alert(err.message)
      })
  }

  cancelCropHandler = () => {
    this.setState({ isPhotoEditorOpen: false, editingPhoto: null })
  }

  acceptCropHandler = () => {
    const { cropperRef, editingPhoto } = this.state
    const [photoName] = Object.entries(editingPhoto)[0]
    const newState = {
      editingPhoto: null,
      isPhotoEditorOpen: false
    }

    cropperRef.current.getCroppedCanvas().toBlob(blob => {
      const fileToUpload = new File([blob], editingPhoto[photoName].name)
      if (photoName === 'portada') {
        this.setState(prevState => ({
          ...prevState,
          ...newState,
          portada: {
            file: fileToUpload,
            url: URL.createObjectURL(fileToUpload)
          },
          didImageChange: { ...prevState.didImageChange, portada: true }
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          ...newState,
          avatar: {
            file: fileToUpload,
            url: URL.createObjectURL(fileToUpload)
          },
          didImageChange: { ...prevState.didImageChange, avatar: true }
        }))
      }
    }, editingPhoto[photoName].type)
  }

  onChangeHandler = event => {
    const { name, value } = event.target

    if (['name', 'doctor'].includes(name) && value.length > 255) {
      return this.setState(prevState => ({
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: `La cantidad máxima de caracteres permitida es 255. La cantidad actual es: ${value.length}`
        }
      }))
    }

    this.setState(prevState => {
      const newErrors = { ...prevState.errors }
      delete newErrors[name]
      return {
        [name]: value,
        errors: newErrors
      }
    })
  }

  dateHandler = date => {
    this.setState({
      dateToBirth: date || new Date()
    })
  }

  onThemeChange = (name, colorName) => {
    this.setState({
      [name]: colorName
    })
  }

  submitHandler = event => {
    event.preventDefault()

    const { name, dateToBirth } = this.state
    const toValidate = {
      name,
      dateToBirth
    }

    validationSchema
    .validate(toValidate)
      .then(() => {
        const data = {
          nombre: this.state.name,
          obstetra: this.state.doctor,
          clinica: this.state.clinic,
          sex: 'indefinido',
          fechaEstimadaParto: this.state.dateToBirth,
          color: this.state.colorTheme
        }

        const photoData = []
        const changedPhotosIterable = Object.entries(this.state.didImageChange)

        if (changedPhotosIterable.length) {
          changedPhotosIterable.forEach(([photoName, wasChanged]) => {
            if (wasChanged) {
              photoData.push({
                file: this.state[photoName].file,
                fileType: FILE_TYPES[photoName]
              })
            }
          })
        }
        this.props.setProfileData(data, photoData)
      })
      .catch(errors => {
        this.setState({
          errors: {
            [errors.path]: errors.message
          }
        })
      })
  }

  render() {

    return (
      <>
        {this.state.isPhotoEditorOpen && (
          <Modal customCloseHandler={this.cancelCropHandler} isCloseable>
            <TitleContainer>
              <H2>Redimensioná tu foto</H2>
              <ModalDescription>
                Seleccioná el area de tu foto para poder mantener la relación de aspecto de la
                misma.
              </ModalDescription>
            </TitleContainer>
            <CropperMainContainer>
              <CropperContainer isAvatar={!!this.state.editingPhoto.avatar}>
                <ImageCropper
                  ref={this.state.cropperRef}
                  aspectRatio={
                    (this.state.editingPhoto.portada && PORTADA_RATIO) ||
                    (this.state.editingPhoto.avatar && AVATAR_RATIO)
                  }
                  imgSrc={URL.createObjectURL(
                    this.state.editingPhoto.portada || this.state.editingPhoto.avatar
                  )}
                />
              </CropperContainer>
            </CropperMainContainer>
            <ButtonContainer>
              <AcceptButton onClick={this.acceptCropHandler}>Aceptar</AcceptButton>
              <CancelButton onClick={this.cancelCropHandler}>Cancelar</CancelButton>
            </ButtonContainer>
          </Modal>
        )}
        <StyledFormContainer onSubmit={this.submitHandler} noValidate autoComplete="off">
          <H3>Tu(s) bebé(s)</H3>
          <StyledInput
            value={this.state.name}
            onChange={this.onChangeHandler}
            name="name"
            type="text"
            placeholder="Se va(n) a llamar"
          />
          {this.state.errors.name ? (
            <ErrFeedbackContainer>{'* ' + this.state.errors.name}</ErrFeedbackContainer>
          ) : null}
          <H5>Fecha probable de parto</H5>
          <DatePicker
            format="d/M/y"
            locale="es-419"
            clearIcon={null}
            calendarIcon={<DatePickerIcon size={ACTION_ICON_SIZE} />}
            value={this.state.dateToBirth}
            onChange={this.dateHandler}
            // minDate={new Date()}
            maxDate={this.maxDateToSelect}
          />
          {this.state.errors.dateToBirth ? (
            <ErrFeedbackContainer>{'* ' + this.state.errors.dateToBirth}</ErrFeedbackContainer>
          ) : null}
          <H5>Foto de perfíl</H5>
          <FileInputContainer>
            <FileInputLabel>
              <FileInputLabelIcon size={'2em'} />
              <FileInput name="avatar" type="file" onChange={this.setPhotoToCrop} />
            </FileInputLabel>
            <ImgAvatar
              name="avatar"
              src={this.state.avatar.url || AVATAR_PLACEHOLDER}
              alt="Perfil"
            />
          </FileInputContainer>
          {this.state.errors.avatar && (
            <ErrFeedbackContainer>{'* ' + this.state.errors.avatar}</ErrFeedbackContainer>
          )}
          <H5>Foto de portada</H5>
          <FileInputContainerPortada>
            <FileInputLabel>
              <FileInputLabelIcon size={'2em'} />
              <FileInput name="portada" type="file" onChange={this.setPhotoToCrop} />
            </FileInputLabel>
            <ImgPortada
              name="portada"
              src={this.state.portada.url || PORTADA_PLACEHOLDER}
              alt="Portada"
            />
          </FileInputContainerPortada>
          {this.state.errors.portada && (
            <ErrFeedbackContainer>{'* ' + this.state.errors.portada}</ErrFeedbackContainer>
          )}
          <H5>Personalizá tu perfil</H5>
          <ColorPicker
            name="colorTheme"
            onChange={this.onThemeChange}
            default={this.state.colorTheme}
            colors={THEME_COLORS}
          />
          <H5>Obstetra</H5>
          <StyledInput
              value={this.state.doctor}
              onChange={this.onChangeHandler}
              name="doctor"
              type="text"
              placeholder="Nombre del obstetra"
          />
          {this.state.errors.doctor ? (
              <ErrFeedbackContainer>{'* ' + this.state.errors.doctor}</ErrFeedbackContainer>
          ) : null}
          <H5>Clínica</H5>
          <Select
              name="clinic"
              value={this.state.clinic}
              onChange={this.onChangeHandler}
          >
            <option value="" defaultValue disabled>
              Seleccione una clínica
            </option>
            {clinics.map(clinic => (
                <Option key={clinic.id} value={clinic.id}>
                  {clinic.name}
                </Option>
            ))}
          </Select>

          <SubmitButton
            type="submit"
            disabled={
              (
                !!Object.values(this.state.errors).length &&
                !this.state.errors.files &&
                !this.state.errors.photos
              ) ||
              !this.state.clinic
            }
          >
            Guardar
          </SubmitButton>
        </StyledFormContainer>
      </>
    )
  }
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  width: 100%;
`
const AcceptButton = styled(PrimaryButton)`
  width: 40%;
`

const CancelButton = styled(SecondaryButton)`
  width: 40%;
`

const CropperMainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CropperContainer = styled.div`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  border: 3px solid;
  width: 90%;
  ${props =>
    props.isAvatar &&
    css`
      .cropper-view-box,
      .cropper-face {
        border-radius: 50%;
      }
    `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`

const ModalDescription = styled(H5)`
  width: 80%;
  text-align: center;
`

const DatePickerIcon = styled(DateRange)`
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
`
const FileInputContainerPortada = styled(FileInputContainer)`
  width: 100%;
`

const StyledFormContainer = styled(StyledForm)`
  & .react-date-picker {
    width: 80%;
    margin-bottom: 3%;
  }

  & .react-date-picker__wrapper {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  }

  /* & .react-date-picker__wrapper button:focus {
    outline-offset: -2px;
    outline: blue auto 1px;
  } */

  & .react-date-picker__inputGroup input {
    padding: 0;
    border: none;
  }
`

const Option = styled.option`
  color: ${props => props.theme.colors.black};
`

const mapStateToProps = state => {
  const { name, dateToBirth, doctor, clinic, color } = state.profileReducer
  const { portada, avatar } = state.profileImagesReducer

  return {
    name,
    dateToBirth,
    doctor,
    clinic,
    portada,
    avatar,
    color
  }
}

const mapDispatchToProps = {
  setProfileData,
  hideModal,
  removeisNewUser,
  setPortada,
  setAvatar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileForm)
