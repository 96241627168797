import React, { Component } from 'react'
import styled from 'styled-components'
import { Attachment, AttachFile, Close, CloudDownload } from 'styled-icons/material'
import validationSchema from '../../../validation/notaSchema'
import * as yup from 'yup'

import { download } from '../../../utils/downloadable'
import { NOTA_FILE_TYPE, ACTION_ICON_SIZE } from '../../../constants/generics'
import { H3, H5 } from '../../../styled/generics'
import { StyledForm as Form, StyledInput, TextArea, SubmitButton } from '../../../styled/forms'
import { SecondaryButton } from '../../../styled/generics'
import ImagesRow from './ImagesRow'

class NuevaNota extends Component {
  constructor(props) {
    super(props)
    const { nota, isEdit } = this.props
    this.state = {
      titulo: isEdit ? nota.titulo : '',
      texto: isEdit ? nota.texto : '',
      errors: {},
      files: isEdit ? nota.files : [],
      photos: isEdit ? nota.photos : [],
      fileIdsToRemove: []
    }
  }

  removeFileHandler = fileIndex => {
    this.setState(prevState => {
      const newState = {
        ...prevState,
        files: prevState.files.filter((file, index) => index !== fileIndex)
      }
      if (prevState.files[fileIndex].id) {
        newState.fileIdsToRemove = [...prevState.fileIdsToRemove, prevState.files[fileIndex].id]
      }
      return newState
    })
  }

  removePhotoHandler = fileIndex => {
    this.setState(prevState => {
      const newState = {
        ...prevState,
        photos: prevState.photos.filter((file, index) => index !== fileIndex)
      }
      if (prevState.photos[fileIndex].id) {
        newState.fileIdsToRemove = [...prevState.fileIdsToRemove, prevState.photos[fileIndex].id]
      }
      return newState
    })
  }

  fileChangeHandler = (event, fileType) => {
    //fileType => 'FILE' -- 'PHOTO'
    event.persist()
    const { name, files } = event.target
    if (!files.length) {
      return
    }
    const options = {
      context: this.props.nota
        ? fileType === NOTA_FILE_TYPE.FILE
          ? this.state.files
          : this.state.photos
        : []
    }
    const file = files[0]
    return yup
      .reach(validationSchema, name)
      .validate(file, options)
      .then(result => {
        const objectToPush =
          fileType === NOTA_FILE_TYPE.FILE ? { file } : { file, urlBlob: URL.createObjectURL(file) }
        this.setState(prevState => ({
          ...prevState,
          [name]: [...prevState[name], objectToPush]
        }))
      })
      .catch(err => alert(err.message))
      .finally(() => (event.target.value = null))
  }

  onChangeHandler = event => {
    const { name, value } = event.target
    if (name === 'titulo' && value.length > 128) {
      return this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          titulo: `Cantidad de caractéres ${value.length}. Cantidad máxima 128!`
        },
        titulo: value
      }))
    }
    if (name === 'texto' && value.length > 500) {
      return this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          texto: `Cantidad de caractéres ${value.length}. Cantidad máxima 500!`
        },
        texto: value
      }))
    }
    const newErrors = { ...this.state.errors }
    delete newErrors[name]
    this.setState({
      [name]: value,
      errors: newErrors
    })
  }

  submitHandler = event => {
    event.preventDefault()
    const { titulo, texto } = this.state
    validationSchema
      .validate({ titulo, texto })
      .then(result => {
        const { fileIdsToRemove } = this.state
        const filteredFiles = this.state.files.filter(object => !object.id)
        const filteredPhotos = this.state.photos.filter(object => !object.id)
        const filesToUpload = filteredFiles.map(object => ({ file: object.file }))
        const photosToUpload = filteredPhotos.map(object => ({ file: object.file }))
        this.props.addHandler({ titulo, texto }, { filesToUpload, photosToUpload, fileIdsToRemove })
      })
      .catch(errors => {
        this.setState({
          errors: {
            [errors.path]: errors.message
          }
        })
      })
  }

  render() {
    return (
      <React.Fragment>
        <Form onSubmit={this.submitHandler}>
          <H3>{this.props.isEdit ? 'Editar nota' : 'Nueva nota'}</H3>
          <Input
            name="titulo"
            placeholder="Título de la nota"
            value={this.state.titulo}
            onChange={this.onChangeHandler}
          />
          {this.state.errors.titulo ? (
            <ErrFeedbackContainer>{'* ' + this.state.errors.titulo}</ErrFeedbackContainer>
          ) : null}
          <TextArea
            name="texto"
            placeholder="Notas"
            value={this.state.texto}
            onChange={this.onChangeHandler}
          />
          {this.state.errors.texto ? (
            <ErrFeedbackContainer>{'* ' + this.state.errors.texto}</ErrFeedbackContainer>
          ) : null}
          {this.state.files.length ? (
            <ListaUploads>
              {this.state.files.map((archivoObject, index) => (
                <UploadItem key={`${archivoObject.id}-${archivoObject.file.name}-${index}`}>
                  <Attachment size={ACTION_ICON_SIZE} />
                  <DescriptionContainer>
                    <FileDescription>{archivoObject.file.name}</FileDescription>
                  </DescriptionContainer>
                  <DowloadIcon onClick={() => download(archivoObject.file)} />
                  <RemoveIcon onClick={() => this.removeFileHandler(index)} />
                </UploadItem>
              ))}
            </ListaUploads>
          ) : null}
          <FileUploadButton type="button">
            <FileInput
              name="files"
              type="file"
              onChange={event => this.fileChangeHandler(event, NOTA_FILE_TYPE.FILE)}
            />
            <FileUploadButtonIcon size={ACTION_ICON_SIZE} />
            Adjuntar documentación
          </FileUploadButton>
          <Filetypes>*.pdf, .doc, .docx, .xls, etc.</Filetypes>
          <H5>Subir Imágenes</H5>
          <ImagesRow
            removeHandler={photoId => this.removePhotoHandler(photoId)}
            fileHandler={this.fileChangeHandler}
            photos={this.state.photos}
          />
          <SubmitButton
            type="submit"
            disabled={
              Object.entries(this.state.errors).length &&
              !this.state.errors.files &&
              !this.state.errors.photos
            }
          >
            Guardar
          </SubmitButton>
        </Form>
      </React.Fragment>
    )
  }
}

const ErrFeedbackContainer = styled.div`
  width: 80%;
  margin-top: -3%;
  color: ${props => props.theme.colors.styledRed};
`

const FileUploadButton = styled(SecondaryButton)`
  position: relative;
  padding: 5px 10px;
  width: auto;
  margin-bottom: 5px;
`

const Filetypes = styled.small`
  font-weight: bold;
  margin-bottom: 20px;
`

const FileUploadButtonIcon = styled(AttachFile)`
  margin-right: 5px;
  cursor: pointer;
`

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`
const Input = styled(StyledInput)`
  margin-bottom: 1em;
`
const ListaUploads = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.colors.gray};
  padding-left: 0;
  @media screen and (max-width: 800px) {
    width: 85%;
  }
`

const RemoveIcon = styled(Close)`
  cursor: pointer;
  color: ${props => props.theme.colors.red};
  width: 1.2em;
  height: 1.2em;
  &:hover {
    transform: scale(1.5);
  }

  @media screen and (max-width: 800px) {
    width: 2em;
    height: 2em;
    &:hover {
      transform: none;
    }
  }
`

const DowloadIcon = styled(CloudDownload)`
  cursor: pointer;
  color: ${props => props.theme.colors.transparentGreen};
  width: 1.2em;
  height: 1.2em;
  &:hover {
    transform: scale(1.5);
  }

  @media screen and (max-width: 800px) {
    width: 2em;
    height: 2em;
    &:hover {
      transform: none;
    }
  }
`
const UploadItem = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`
const DescriptionContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 75%;
`

const FileDescription = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export default NuevaNota
