const productionConfig = {
  endpoints: {
    API_URL: 'http://pregnancyplanner.com.ar:7070/api/',
    PORTAL_URL: 'https://www.miportalclinicas.com.ar/'
  }
}

const testingConfig = {
  endpoints: {
    API_URL: 'http://vdesa-totemsgh:8083/api/',
    PORTAL_URL: 'http://172.16.4.161:4000/'
  }
}

const localConfig = {
  endpoints: {
    API_URL: 'http://localhost:61660/api/',
    PORTAL_URL: 'http://localhost:3000/'
  }
}

const { REACT_APP_STAGE } = process.env
const enviroment = REACT_APP_STAGE.trim()

let config
enviroment === 'prod'
  ? (config = productionConfig)
  : enviroment === 'test'
  ? (config = testingConfig)
  : (config = localConfig)

export default {
  ...config
}
