import styled from 'styled-components'

export const H1 = styled.h1`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
`
export const H2 = styled.h2`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
`
export const H3 = styled.h3`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
`
export const H4 = styled.h4`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
`
export const H5 = styled.h5`
  margin-top: 1em;
  margin-bottom: 1em;
`
export const H6 = styled.h6`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
`

export const ImgPortada = styled.img`
  width: 100%;
  max-height: 90px;
`
export const ImgAvatar = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  border: solid 1px;
`
export const Alerta = styled.div`
  background-color: ${props => props.theme.themeColors[props.theme.selected].quaternaryColor};
  color: ${props => props.theme.colors.white};
  width: 100%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  margin-bottom: 3%;
  align-items: center;
  text-align: center;
`
export const Button = styled.button`
  margin: 15px 0;
  width: 60%;
  padding: 10px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
export const PrimaryButton = styled(Button)`
  background-color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  color: white;
  border: none;
`
export const SecondaryButton = styled(Button)`
  background-color: ${props => props.theme.themeColors[props.theme.selected].secondaryColor};
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  border: 1px solid ${props => props.theme.themeColors[props.theme.selected].ternaryColor};
  font-weight: bold;
`
