import {
  subirArchivoNotaAPI,
  setNotaAPI,
  getNotasAPI,
  deleteNotaAPI,
  bajarArchivosDeNotaAPI,
  removeArchivoNotaAPI
} from '../../api'
import { setNotasOfModal } from './modalActions'

import { daysDifference, getFirstDayOfWeekNumber } from '../../utils/date'
import { b64toBlob, b64toFile } from '../../utils/getBase64'

import { constants } from '../../constants/reduxActions'
const {
  SET_NOTAS,
  SET_NOTAS_OF_WEEK,
  SET_GOTTEN_NOTAS,
  SET_NOTAS_LOADER,
  REMOVE_NOTAS_LOADER
} = constants

export const setNotasArray = payload => {
  return {
    type: SET_NOTAS,
    payload
  }
}

export const setNotasOfWeek = payload => {
  return {
    type: SET_NOTAS_OF_WEEK,
    payload
  }
}

export const setGottenNotas = payload => {
  return {
    type: SET_GOTTEN_NOTAS,
    payload
  }
}

export const setNotasLoader = () => {
  return {
    type: SET_NOTAS_LOADER
  }
}
export const removeNotasLoader = () => {
  return {
    type: REMOVE_NOTAS_LOADER
  }
}

export const getNotasOfWeek = () => async (dispatch, getState) => {
  const { number, pregnancyBeginsOn } = getState().calendarReducer.week
  const { notas } = getState().calendarReducer.data
  const { usuarioId } = getState().authReducer.auth
  const notaIdsToFetch = []

  const firstDayOfWeek = getFirstDayOfWeekNumber(number, pregnancyBeginsOn)
  const dayNumbers = [1, 2, 3, 4, 5, 6, 7].map(dayoffset => {
    const result = new Date(firstDayOfWeek)
    result.setDate(result.getDate() + dayoffset)
    return result
  })

  const estimatedDaysToSet = dayNumbers.map(realDateDay =>
    daysDifference(realDateDay, pregnancyBeginsOn)
  )

  let notasPromises = estimatedDaysToSet.map(value => getNotasAPI(usuarioId, value))
  notasPromises = await Promise.all(notasPromises)

  const totalNotas = notasPromises.map(resItem =>
    resItem.data.map(notaObject => {
      notaIdsToFetch.push(notaObject.id)
      return notaObject
    })
  )

  const notasToDispatch = await Promise.all(
    totalNotas.map(async notasDelDia => {
      return await Promise.all(
        notasDelDia.map(async nota => {
          let archivosDeNota = await bajarArchivosDeNotaAPI(usuarioId, nota.id)
          let files = []
          let photos = []
          for (let i = 0; i < archivosDeNota.data.length; i++) {
            if (/(jpg|jpeg|png)/.test(archivosDeNota.data[i].filename)) {
              const blob = await b64toBlob(archivosDeNota.data[i].content)
              const urlBlob = URL.createObjectURL(blob)
              photos.push({
                urlBlob,
                id: archivosDeNota.data[i].id
              })
            } else {
              files.push({
                file: await b64toFile(
                  archivosDeNota.data[i].content,
                  archivosDeNota.data[i].filename
                ),
                id: archivosDeNota.data[i].id
              })
            }
          }
          return {
            ...nota,
            files,
            photos
          }
        })
      )
    })
  )
  const newNotas = { ...notas }
  estimatedDaysToSet.forEach((estimatedDay, index) => {
    newNotas[estimatedDay] = notasToDispatch[index]
  })
  dispatch(setGottenNotas({ weekNumber: number }))
  dispatch(setNotasOfWeek(newNotas))
  dispatch(removeNotasLoader())
}

export const removeFilesFromNota = (fileIdsToRemove, notaObject) => async (dispatch, getState) => {
  await Promise.all(
    fileIdsToRemove.map(fileId => removeArchivoNotaAPI(notaObject.usuarioId, notaObject.id, fileId))
  )
  const { notaDay } = getState().modalReducer
  const totalNotas = { ...getState().calendarReducer.data.notas }
  const notaIndex = totalNotas[notaDay].findIndex(nota => nota.id === notaObject.id)
  let newPhotos = totalNotas[notaDay][notaIndex].photos
  let newFiles = totalNotas[notaDay][notaIndex].files

  fileIdsToRemove.forEach(id => {
    newFiles = newFiles.filter(file => file.id !== id)
    newPhotos = newPhotos.filter(photo => photo.id !== id)
  })
  totalNotas[notaDay][notaIndex].files = newFiles
  totalNotas[notaDay][notaIndex].photos = newPhotos

  return dispatch(setNotasArray(totalNotas))
}

export const uploadFilesToNota = (notaId, filesObject) => async (dispatch, getState) => {
  const { usuarioId } = getState().authReducer.auth
  const { notaDay } = getState().modalReducer
  const totalNotas = { ...getState().calendarReducer.data.notas }

  try {
    const filePromises = filesObject.filesToUpload.map(({ file }) =>
      subirArchivoNotaAPI(usuarioId, notaId, file)
    )
    const photoPromises = filesObject.photosToUpload.map(({ file }) =>
      subirArchivoNotaAPI(usuarioId, notaId, file)
    )

    const uploadedFiles = await Promise.all(filePromises)
    const uploadedPhotos = await Promise.all(photoPromises)

    const notaIndex = totalNotas[notaDay].findIndex(nota => nota.id === notaId)
    if (notaIndex === -1) {
      //This check should not be necessary, apparently..
      throw new Error('Could not find nota to merge file.')
    }

    filesObject.filesToUpload.forEach((fileObject, fileIndex) => {
      totalNotas[notaDay][notaIndex].files.push({
        id: uploadedFiles[fileIndex].data[0],
        file: fileObject.file
      })
    })

    filesObject.photosToUpload.forEach((photoObject, photoIndex) => {
      totalNotas[notaDay][notaIndex].photos.push({
        id: uploadedPhotos[photoIndex].data[0],
        urlBlob: URL.createObjectURL(photoObject.file)
      })
    })

    dispatch(setNotasArray(totalNotas))
  } catch (err) {
    console.log(err)
  }
}

export const editNota = (notaObject, filesObject, closeEditorCallback) => async (
  dispatch,
  getState
) => {
  notaObject.usuarioId = getState().authReducer.auth.usuarioId
  try {
    await setNotaAPI(notaObject)

    const totalNotas = { ...getState().calendarReducer.data.notas }
    const notaIndex = totalNotas[notaObject.diaEstimado].findIndex(
      nota => nota.id === notaObject.id
    )
    if (notaIndex === -1) {
      throw new Error(`Could not find nota of id=${notaObject.id}`)
    }
    totalNotas[notaObject.diaEstimado][notaIndex].titulo = notaObject.titulo
    totalNotas[notaObject.diaEstimado][notaIndex].texto = notaObject.texto
    dispatch(setNotasArray(totalNotas))

    if (filesObject.fileIdsToRemove.length) {
      await dispatch(removeFilesFromNota(filesObject.fileIdsToRemove, notaObject))
    }

    if (filesObject.filesToUpload.length || filesObject.photosToUpload.length) {
      await dispatch(uploadFilesToNota(notaObject.id, filesObject))
    }

    if (closeEditorCallback) {
      closeEditorCallback()
    }
  } catch (err) {
    console.log(err)
  }
}

export const addNota = (notaObject, filesObject, closeEditorCallback) => async (
  dispatch,
  getState
) => {
  notaObject.usuarioId = getState().authReducer.auth.usuarioId
  try {
    const res = await setNotaAPI(notaObject)
    const totalNotas = { ...getState().calendarReducer.data.notas }
    const newNotaId = res.data
    const newNotaToPush = {
      ...notaObject,
      id: newNotaId,
      files: [],
      photos: []
    }

    totalNotas[notaObject.diaEstimado].push(newNotaToPush)
    dispatch(setNotasArray(totalNotas))

    if (filesObject.filesToUpload.length || filesObject.photosToUpload.length) {
      await dispatch(uploadFilesToNota(newNotaId, filesObject))
    }
    if (closeEditorCallback) {
      closeEditorCallback()
    }
  } catch (error) {
    console.log(error)
  }
}

export const removeNota = notaObject => (dispatch, getState) => {
  deleteNotaAPI(notaObject.usuarioId, notaObject.id)
    .then(res => {
      const totalNotas = { ...getState().calendarReducer.data.notas }
      const newNotas = totalNotas[notaObject.diaEstimado].filter(item => item.id !== notaObject.id)
      totalNotas[notaObject.diaEstimado] = newNotas
      dispatch(setNotasOfModal(totalNotas[notaObject.diaEstimado]))
      return dispatch(setNotasArray(totalNotas))
    })
    .catch(console.log)
}

export const downloadFilesToNota = notaId => (dispatch, getState) => {
  const { usuarioId } = getState().authReducer.auth
  bajarArchivosDeNotaAPI(usuarioId, notaId)
    .then(res => console.log(res.data))
    .catch(err => console.log(err.message))
}
