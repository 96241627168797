import React, { Fragment } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { KeyboardArrowLeft } from 'styled-icons/material'

import { breadcrumbsURLParser } from '../../utils/breadcrumbsparse'
import { ACTION_ICON_SIZE } from '../../constants/generics'

const BreadcrumbsFromUrl = props => {
  const parsedURLArray = breadcrumbsURLParser(window.location.pathname)
  return (
    <Container {...props}>
      <Backbutton
        to={props.location.pathname.replace('/' + parsedURLArray[parsedURLArray.length - 1], '')}
      >
        <BackIcon size={ACTION_ICON_SIZE} />
        <Span>Volver</Span>
      </Backbutton>
      <Path>
        {parsedURLArray.map((pageName, index) => {
          const route = props.location.pathname.substring(
            0,
            window.location.pathname.indexOf(`/${pageName}`) + `/${pageName}`.length
          )
          return index === 0 ? (
            <BasePath key={pageName} exact to={route}>
              {pageName.replace(/-/g, ' ')}
            </BasePath>
          ) : (
            <Fragment key={pageName}>
              <PathSpan>/</PathSpan>
              <StyledLink exact to={route}>
                {pageName.replace(/-/g, ' ')}
              </StyledLink>
            </Fragment>
          )
        })}
      </Path>
    </Container>
  )
}

const Container = styled.div`
  width: 80%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 2%;
`
const Backbutton = styled(Link)`
  display: flex;
  align-items: center;
  width: 5vw;
  color: ${props => props.theme.colors.darkGray};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.darkGray};
  }
  &:hover svg {
    transform: scale(1.2);
  }
  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2em;
  }
`
const BackIcon = styled(KeyboardArrowLeft)`
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
const Span = styled.span`
  height: 99%;
  color: ${props => props.theme.colors.strongGray};
  user-select: none;
`
const BasePath = styled(NavLink)`
  text-decoration: none;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.active {
    cursor: default;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  }
`
const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &.active {
    text-decoration: underline;
    text-decoration-color: inherit;
  }
`
const Path = styled.span`
  color: ${props => props.theme.themeColors[props.theme.selected].quaternaryColor};
  font-weight: 600;
  font-size: 20px;
  user-select: none;
  text-transform: capitalize;
  @media screen and (max-width: 800px) {
    font-size: 1.2em;
  }
`
const PathSpan = styled.span`
  margin: 0 0.3% 0 0.3%;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
export default withRouter(BreadcrumbsFromUrl)
