import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  BusinessCenter,
  PregnantWoman,
  ChromeReaderMode,
  AddCircleOutline,
  Add,
  Edit
} from 'styled-icons/material'

import Spinner from '../Loader/Spinner'

import colors from '../../styled/colors'
import { daysDifference } from '../../utils/date'
import { weekCalc, setDay } from '../../redux/actions/calendarActions'
import {
  MONTHS,
  CONTROL_ICONS_SIZES,
  HELPER_ICONS_SIZES,
  NUMBER_OF_WEEKS,
  ACTION_ICON_SIZE
} from '../../constants/generics'

const Calendar = props => {
  const dayNumbers = [1, 2, 3, 4, 5, 6, 7].map(value => {
    const d = new Date(props.week.datefromTo[0])
    d.setDate(d.getDate() + value)
    return d
  })

  const registros = dayNumbers.map(value => {
    return props.registros.filter(
      entry => entry.diaEstimado === daysDifference(value, props.week.pregnancyBeginsOn)
    )
  })

  const miEmbarazo = dayNumbers.map(value => {
    return props.embarazo.filter(
      entry => entry.diaEstimado === daysDifference(value, props.week.pregnancyBeginsOn)
    )
  })

  const misNotas = dayNumbers.map(value => {
    return props.notas && Object.keys(props.notas).length
      ? props.notas[daysDifference(value, props.week.pregnancyBeginsOn)]
      : []
  })
  return (
    <Section>
      <ControlBackContainer
        onClick={() => {
          const { number } = props.week
          return !(number - 1 < 0) ? props.weekCalc(number - 1) : null
        }}
        isActive={!(props.week.number - 1 < 0)}
      >
        {!(props.week.number - 1 < 0) && (
          <Fragment>
            <KeyboardArrowLeft size={CONTROL_ICONS_SIZES.desktop} color={colors.gray} />
            <span>Semana {props.week.number}</span>
          </Fragment>
        )}
      </ControlBackContainer>
      <HeaderDataContainer>
        <TitleSemanaContainer>
          <SemanaTitle>Semana {props.week.number + 1}</SemanaTitle>
          <SemanaSubtitle>
            {`Del ${dayNumbers[0].getDate()} de ${
              MONTHS[dayNumbers[0].getMonth()]
            } de ${dayNumbers[0].getFullYear()} al ${dayNumbers[6].getDate()} de ${
              MONTHS[dayNumbers[6].getMonth()]
            } de ${dayNumbers[6].getFullYear()}`}
          </SemanaSubtitle>
          <SemanaActualButton
            onClick={() => props.weekCalc(props.semanaToShortcut)}
            isActive={props.semanaShortcutActive}
          >
            Ir a la semana actual
          </SemanaActualButton>
        </TitleSemanaContainer>
        <DayNumbersContainer>
          {dayNumbers.map((dateValue, index) => (
            <DayNumber
              isLast={index === 6}
              isActive={
                new Date(props.dateToBirth).toDateString() === dateValue.toDateString() ||
                props.activeDay.toDateString() === dateValue.toDateString()
              }
              key={index}
            >
              {new Date(props.dateToBirth).toDateString() !== dateValue.toDateString()
                ? dateValue.toDateString() === props.activeDay.toDateString()
                  ? 'Hoy'
                  : dateValue.getDate()
                : 'Probable parto'}
            </DayNumber>
          ))}
        </DayNumbersContainer>
      </HeaderDataContainer>
      <ControlForwardContainer
        onClick={() => {
          const { number } = props.week
          return !(number + 1 > NUMBER_OF_WEEKS) ? props.weekCalc(number + 1) : null
        }}
        isActive={!(props.week.number + 1 > NUMBER_OF_WEEKS)}
      >
        {!(props.week.number + 1 > NUMBER_OF_WEEKS) && (
          <Fragment>
            <span>Semana {props.week.number + 2}</span>
            <KeyboardArrowRight size={CONTROL_ICONS_SIZES.desktop} color={colors.gray} />
          </Fragment>
        )}
      </ControlForwardContainer>
      <HelperContainer>
        <BusinessCenter size={HELPER_ICONS_SIZES.desktop} />
        <HelperSpan>Registros clínicos</HelperSpan>
      </HelperContainer>
      {registros.map((registrosDelDia, index) => {
        return (
          <RowItem key={index} isFirst={index === 0} isLast={index === 6}>
            {registrosDelDia.map(registro => (
              <DataContainer isActive={!!registro} key={registro.id}>
                <LabelTurno onClick={() => props.turnoClickHandler(registro, props.usuarioId)}>
                  <TurnoIconContainer>
                    <AddCircleOutline size={ACTION_ICON_SIZE} />
                    Pedir Turno
                  </TurnoIconContainer>
                </LabelTurno>
                <DataText>{registro.nombre}</DataText>
              </DataContainer>
            ))}
          </RowItem>
        )
      })}
      <FinalRowCell />
      <LightHelperContainer>
        <PregnantWoman size={HELPER_ICONS_SIZES.desktop} />
        <HelperSpan>Mí embarazo</HelperSpan>
      </LightHelperContainer>
      {miEmbarazo.map((eventosDelDia, index) => {
        return (
          <LightRowItem key={index} isFirst={index === 0} isLast={index === 6}>
            {eventosDelDia.map(evento => (
              <DataContainer isActive={!!evento} key={evento.id}>
                <LabelTurno onClick={() => props.detalleClickHandler(evento)}>
                  <TurnoIconContainer>
                    <AddCircleOutline size={ACTION_ICON_SIZE} />
                    Ver más
                  </TurnoIconContainer>
                </LabelTurno>
                <DataText>{evento.descripcion}</DataText>
              </DataContainer>
            ))}
          </LightRowItem>
        )
      })}
      <LightFinalRowCell />
      <HelperContainer>
        <ChromeReaderMode size={HELPER_ICONS_SIZES.desktop} />
        <HelperSpan>Mis notas</HelperSpan>
      </HelperContainer>
      {misNotas.map((notasDelDia, index) => {
        return (
          <RowItem key={index} isFirst={index === 0} isLast={index === 6}>
            {props.notasLoading ? (
              <NotaContainer>
                <Spinner />
              </NotaContainer>
            ) : notasDelDia && notasDelDia.length ? (
              <Fragment>
                <NotaContainer
                  isActive={!!notasDelDia.length}
                  photo={notasDelDia[0].photos.length ? notasDelDia[0].photos[0] : false}
                >
                  <EditLabel
                    isActive={notasDelDia[0]}
                    hasPhoto={
                      notasDelDia[0] && notasDelDia[0].photos.length && notasDelDia[0].photos[0]
                    }
                  >
                    <NotaTitle
                      hasPhoto={
                        notasDelDia[0] && notasDelDia[0].photos.length && notasDelDia[0].photos[0]
                      }
                    >
                      {notasDelDia[0].titulo}
                    </NotaTitle>
                    <NotaIconContainer hasPhoto={notasDelDia[0].photos.length}>
                      <Edit
                        size={ACTION_ICON_SIZE}
                        onClick={() =>
                          props.notasHandler(
                            notasDelDia,
                            daysDifference(dayNumbers[index], props.week.pregnancyBeginsOn)
                          )
                        }
                      />
                    </NotaIconContainer>
                  </EditLabel>
                </NotaContainer>
                {notasDelDia[1] && (
                  <NotaSecondRow>
                    <SecondNota
                      photo={
                        notasDelDia[1] && notasDelDia[1].photos.length && notasDelDia[1].photos[0]
                      }
                    >
                      <SecondRowLabel
                        hasPhoto={
                          notasDelDia[1] && notasDelDia[1].photos.length && notasDelDia[1].photos[0]
                        }
                        isActive={notasDelDia.length > 1}
                      >
                        <NotaIconContainer
                          hasPhoto={
                            notasDelDia[1] &&
                            notasDelDia[1].photos.length &&
                            notasDelDia[1].photos[0]
                          }
                          onClick={() =>
                            props.notasHandler(
                              notasDelDia,
                              daysDifference(dayNumbers[index], props.week.pregnancyBeginsOn)
                            )
                          }
                        >
                          <Edit size={ACTION_ICON_SIZE} />
                        </NotaIconContainer>
                      </SecondRowLabel>
                    </SecondNota>
                    <SecondNota
                      isLast
                      photo={
                        notasDelDia[2] && notasDelDia[2].photos.length && notasDelDia[2].photos[0]
                      }
                    >
                      <SecondRowLabel
                        hasPhoto={
                          notasDelDia[2] && notasDelDia[2].photos.length && notasDelDia[2].photos[0]
                        }
                        isActive={notasDelDia.length > 1}
                      >
                        <NotaIconContainer
                          hasPhoto={
                            notasDelDia[2] &&
                            notasDelDia[2].photos.length &&
                            notasDelDia[2].photos[0]
                          }
                          onClick={() =>
                            props.notasHandler(
                              notasDelDia,
                              daysDifference(dayNumbers[index], props.week.pregnancyBeginsOn)
                            )
                          }
                        >
                          {notasDelDia.length}
                          <Add size={'1em'} />
                        </NotaIconContainer>
                      </SecondRowLabel>
                    </SecondNota>
                  </NotaSecondRow>
                )}
              </Fragment>
            ) : (
              <NotaContainer>
                <LabelTurno
                  onClick={() =>
                    props.notasHandler(
                      notasDelDia,
                      daysDifference(dayNumbers[index], props.week.pregnancyBeginsOn)
                    )
                  }
                >
                  <TurnoIconContainer>
                    <AddCircleOutline size={'2em'} />
                    Agregar Nota
                  </TurnoIconContainer>
                </LabelTurno>
              </NotaContainer>
            )}
          </RowItem>
        )
      })}
      <FinalRowCell />
    </Section>
  )
}

const Section = styled.section`
  display: grid;
  height: 95vh;
  width: 100%;
  grid-template-columns: 15% 10% 10% 10% 10% 10% 10% 10% 15%;
  grid-template-rows: 25% 25% 25% 25%;
  padding: 0;
  margin: 2% 0;
`

const ControlBackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.themeColors[props.theme.selected].calendarControl};
  cursor: pointer;
  user-select: none;
  ${props =>
    !props.isActive &&
    css`
      cursor: auto;
    `}
`

const ControlForwardContainer = styled.div`
  grid-column-start: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.themeColors[props.theme.selected].calendarControl};
  cursor: pointer;
  user-select: none;
  ${props =>
    !props.isActive &&
    css`
      cursor: auto;
    `}
`

const HeaderDataContainer = styled.div`
  grid-column-start: 2;
  grid-column-end: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid
    ${props => props.theme.themeColors[props.theme.selected].calendarDesktopBorder};
  border-left: 2px solid
    ${props => props.theme.themeColors[props.theme.selected].calendarDesktopBorder};
  border-right: 2px solid
    ${props => props.theme.themeColors[props.theme.selected].calendarDesktopBorder};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHeaderBackground};
`
const TitleSemanaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.3%;
`
const SemanaActualButton = styled.button`
  border-radius: 5px;
  border-style: solid;
  background-color: transparent;
  margin: 5px 0;
  padding: 5px 15px;
  border-color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  transition: all 0.4s ease;
  z-index: -1;
  opacity: 0;
  font-weight: bold;
  ${props =>
    props.isActive &&
    css`
      z-index: 1;
      opacity: 1;
    `}
  &:focus {
    background-color: ${props => props.theme.themeColors[props.theme.selected].secondaryColor};
    outline: none;
    box-shadow: 0px 0px 5px 1px ${props => props.theme.themeColors[props.theme.selected].mainColor};
  }
  &:hover {
    background-color: ${props => props.theme.themeColors[props.theme.selected].secondaryColor};
  }
`

const SemanaTitle = styled.h2`
  margin: 0;
  color: ${props => props.theme.themeColors[props.theme.selected].tertiaryColor};
`
const SemanaSubtitle = styled.h4`
  margin: 0;
`
const DayNumbersContainer = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
`
const DayNumber = styled.div`
  padding: 1%;
  ${props =>
    !props.isLast &&
    css`
      border-right: 2px solid white;
    `}
  width: 100%;
  background-color: white;
  color: ${props => props.theme.colors.strongGray};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.isActive &&
    css`
      background-color: ${props => props.theme.themeColors[props.theme.selected].headerDayHover};
      color: ${props => props.theme.colors.white};
    `}
  &:hover {
    background-color: ${props => props.theme.themeColors[props.theme.selected].headerDayHover};
    color: ${props => props.theme.colors.white};
  }
`

const HelperContainer = styled.div`
  grid-column-start: 1;
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHelperBackgroundDark};
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
const LightHelperContainer = styled(HelperContainer)`
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHelperBackgroundLight};
`

const HelperSpan = styled.span`
  font-size: 1.2em;
  width: 50%;
`
const FinalRowCell = styled.div`
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHelperBackgroundDark};
`
const LightFinalRowCell = styled.div`
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarHelperBackgroundLight};
`

const RowItem = styled.div`
  overflow-y: auto;
  border: 1px solid white;
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarRowItemBackground};
  border-top: none;
  display: flex;
  flex-direction: column;
  ${props =>
    (props.isFirst &&
      css`
        border-left: 2px solid
          ${props => props.theme.themeColors[props.theme.selected].calendarDesktopBorder};
      `) ||
    (props.isLast &&
      css`
        border-right: 2px solid
          ${props => props.theme.themeColors[props.theme.selected].calendarDesktopBorder};
      `)}
`
const LightRowItem = styled(RowItem)`
  background-color: ${props =>
    props.theme.themeColors[props.theme.selected].calendarRowItemBackgroundLight};
`

const DataContainer = styled.div`
  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.white};
      background-color: ${props =>
        props.theme.themeColors[props.theme.selected].calendarDataActive};
    `}
  position: relative;
  text-align: center;
  padding: 10% 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 0 33%;
  border-bottom: 1px solid ${props => props.theme.colors.white};
`
const NotaContainer = styled.div`
  position: relative;
  background-position: center;
  background-size: cover;
  ${props =>
    props.isActive &&
    !props.photo &&
    css`
      color: ${props => props.theme.themeColors[props.theme.selected].main};
    `}
  ${props =>
    props.isActive &&
    props.photo &&
    css`
      background-image: url(${props.photo.urlBlob});
    `}
  &:only-child {
    height: 100% !important;
    width: 100%;
  }
  &:nth-child(1) {
    height: 65%;
    width: 100%;
  }
`

const NotaSecondRow = styled.div`
  display: flex;
  width: 100%;
  height: 35%;
  flex-direction: row;
  border-top: 2px solid ${props => props.theme.colors.white};
`
const SecondNota = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${props =>
    props.isLast &&
    css`
      border-left: 2px solid ${props.theme.colors.white};
    `}
  ${props =>
    props.photo &&
    css`
      background-image: url(${props.photo.urlBlob});
      background-position: center;
      background-size: cover;
    `}
`

const DataText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const LabelTurno = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  color: ${props => props.theme.themeColors[props.theme.selected].primaryColor};
  background-color: ${props => props.theme.colors.transparentGray};
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

const TurnoIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const NotaIconContainer = styled(TurnoIconContainer)`
  flex-direction: row;
  opacity: 1;
  transition: opacity 0.4s ease;
  ${props =>
    props.hasPhoto &&
    css`
      color: white;
    `}
`

const EditLabel = styled(LabelTurno)`
  align-items: flex-end;
  justify-content: flex-end;
  background-color: ${props =>
    props.hasPhoto ? props.theme.colors.superTransparentBlack : props.theme.colors.transparent};
  opacity: 1;
  color: ${props =>
    !props.isActive
      ? props.theme.colors.white
      : props.theme.themeColors[props.theme.selected].primaryColor};

  & ${NotaIconContainer} {
    opacity: 0;
  }

  &:hover {
    cursor: auto;
    opacity: inherit;
  }
  &:hover ${NotaIconContainer} {
    cursor: pointer;
    opacity: 1;
  }
`
const SecondRowLabel = styled(EditLabel)`
  align-items: center;
  justify-content: center;
  opacity: 1;
  & ${NotaIconContainer} {
    opacity: 1;
  }
`
const NotaTitle = styled.h4`
  color: ${props =>
    props.hasPhoto
      ? props.theme.colors.white
      : props.theme.themeColors[props.theme.selected].primaryColor};
  margin: 0;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
`

const mapStateToProps = state => {
  const { notas, registros, embarazo } = state.calendarReducer.data
  const { usuarioId } = state.authReducer.auth
  const { dateToBirth } = state.profileReducer
  const { notasLoading } = state.calendarReducer
  const { activeDay } = state.calendarReducer.week
  return {
    notas,
    registros,
    embarazo,
    usuarioId,
    dateToBirth,
    activeDay,
    notasLoading
  }
}

const mapDispatchToProps = {
  weekCalc,
  setDay
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar)
