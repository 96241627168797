import { constants } from '../../constants/reduxActions'
const { SET_ERROR, RESET_FEEDBACK, SUCCESS_MESSAGE } = constants

/**
 * success and error are supposed to come with a title and message props.
 */
const initialState = {
  messageShown: false,
  error: null,
  success: null
}

const feedbackMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_MESSAGE: {
      return {
        messageShown: true,
        success: action.payload,
        error: null
      }
    }
    case SET_ERROR:
      return {
        messageShown: true,
        error: action.payload,
        success: null
      }
    case RESET_FEEDBACK:
      return {
        messageShown: false,
        error: null,
        success: null
      }
    default:
      return {
        ...state
      }
  }
}

export default feedbackMessageReducer
