import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Close,
  PlayCircleOutline,
  PauseCircleOutline
} from 'styled-icons/material'

import { hideGallery } from '../../redux/actions/modalActions'

class NotasGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: 0,
      isPlaying: false,
      intervalId: null,
      photos: []
    }
  }

  componentDidMount() {
    const photos = []
    this.props.photos.map(item => photos.push(item))
    this.props.photos && photos.length > 1 && this.playHandler()
    this.setState({ photos })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  onClosehandler = () => {
    this.props.hideGallery()
  }

  playHandler = () => {
    this.setState(prevState => {
      let intervalId = prevState.intervalId
      if (prevState.isPlaying) {
        clearInterval(intervalId)
      } else {
        intervalId = setInterval(
          () =>
            this.setState(prevState => {
              const { offset } = prevState
              const newOffset = offset - 1 > this.props.photos.length * -1 ? offset - 1 : 0
              return {
                offset: newOffset
              }
            }),
          3000
        )
      }
      return {
        isPlaying: !prevState.isPlaying,
        intervalId
      }
    })
  }

  stopPlaying = () => {
    this.setState(prevState => {
      clearInterval(prevState.intervalId)
      return {
        isPlaying: false,
        intervalId: null
      }
    })
  }

  moveLeftHandler = () => {
    this.stopPlaying()
    this.setState(prevState => {
      const { offset } = prevState
      const newOffset = offset + 1 <= 0 ? offset + 1 : (this.props.photos.length - 1) * -1
      return {
        offset: newOffset
      }
    })
  }

  moveRightHandler = () => {
    this.stopPlaying()
    this.setState(prevState => {
      const { offset } = prevState
      const newOffset = offset - 1 > this.props.photos.length * -1 ? offset - 1 : 0
      return {
        offset: newOffset
      }
    })
  }

  onListClick = selectedOffset => {
    this.stopPlaying()
    this.setState({
      offset: selectedOffset
    })
  }

  render() {
    return (
      <Container>
        <CloseComponent size={'3em'} onClick={this.onClosehandler} />
        {!this.state.isPlaying ? (
          <PlayComponent onClick={this.playHandler} size={'3em'} />
        ) : (
          <PauseComponent onClick={this.playHandler} size={'3em'} />
        )}
        <ArrowLeft onClick={this.moveLeftHandler} size={'5em'} />
        <ImagesContainer>
          {this.props.photos.map((photoObject, index) => (
            <ResponsiveContainer key={`${index}`} actualOffset={this.state.offset}>
              <Image src={photoObject.urlBlob} />
            </ResponsiveContainer>
          ))}
        </ImagesContainer>
        <ArrowRight onClick={this.moveRightHandler} size={'5em'} />
        <ActiveList>
          {this.props.photos.map((photo, index) => (
            <ActiveItem
              key={`${index}`}
              isActive={this.state.offset === -index}
              onClick={() => this.onListClick(-index)}
            />
          ))}
        </ActiveList>
      </Container>
    )
  }
}

const playPauseCss = css`
  position: fixed;
  color: ${props => props.theme.colors.transparentGray};
  position: fixed;
  left: 2%;
  bottom: 2%;
  &:hover {
    transform: scale(1.1);
    color: ${props => props.theme.colors.white};
    cursor: pointer;
  }
`
const PlayComponent = styled(PlayCircleOutline)`
  ${playPauseCss}
`
const PauseComponent = styled(PauseCircleOutline)`
  ${playPauseCss}
`

const CloseComponent = styled(Close)`
  color: ${props => props.theme.colors.transparentGray};
  position: fixed;
  right: 2%;
  top: 2%;
  &:hover {
    transform: scale(1.1);
    color: ${props => props.theme.colors.white};
    cursor: pointer;
  }
`
const ActiveList = styled.ul`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10%;
  width: 80%;
  list-style-type: none;
`
const ActiveItem = styled.li`
  background-color: ${props => props.theme.colors.transparentGray};
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  margin-right: 1em;
  ${props =>
    props.isActive &&
    css`
      transform: scale(1.3);
      background-color: ${props => props.theme.colors.white};
    `}

  &:hover {
    ${props =>
      !props.isActive &&
      css`
        transform: scale(1.2);
        background-color: ${props => props.theme.colors.white};
        cursor: pointer;
      `}
  }
`

const ArrowLeft = styled(KeyboardArrowLeft)`
  color: ${props => props.theme.colors.transparentGray};
  &:hover {
    transform: scale(1.2);
    color: ${props => props.theme.colors.white};
    cursor: pointer;
  }
`
const ArrowRight = styled(KeyboardArrowRight)`
  color: ${props => props.theme.colors.transparentGray};
  &:hover {
    transform: scale(1.2);
    color: ${props => props.theme.colors.white};
    cursor: pointer;
  }
`
const Container = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${props => props.theme.colors.transparentBlack};
`
const ImagesContainer = styled.div`
  width: 100%;
  max-height: 80vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  user-select: none;
`
const ResponsiveContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  transform: translateX(${props => props.actualOffset * 100}%);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`

NotasGallery.propTypes = {
  photos: PropTypes.array
}

const mapStateToProps = state => {
  const { galleryPhotos } = state.modalReducer
  return {
    photos: galleryPhotos
  }
}
const mapDispatchToProps = {
  hideGallery
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotasGallery)
